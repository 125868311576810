import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useFlowEntryPointNavigator } from 'providers/modal-flow-provider/modal-flow-controls'
import { useShallow } from 'zustand/react/shallow'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { EntryPointType } from 'components/common/flows/profile-setup/utils/entry-point-types'
import { useUserProfileStore } from 'components/common/user-profile-store'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { Button } from 'components/ui/button'

import ProfileForm from './profile-form'

const ProfilePage: React.FC = () => {
  const { user } = useAuth0()
  const { requiresOnboardingNewUser, requiresOnboardingExistingUser } =
    useUserProfileStore(
      useShallow((s) => ({
        requiresOnboardingNewUser: s.requiresOnboardingNewUser,
        requiresOnboardingExistingUser: s.requiresOnboardingExistingUser,
      }))
    )
  const { trackEvent } = useAnalytics()

  const enterProfileSetupFlow =
    useFlowEntryPointNavigator<ProfileSetupFlowType>('profile-setup')

  const shouldRenderProfileForm =
    !requiresOnboardingNewUser && !requiresOnboardingExistingUser

  React.useEffect(() => {
    trackEvent('profile_settings_page_viewed')
  }, [trackEvent])
  if (!user) return <SettingsError />

  const handleContinue = () => {
    enterProfileSetupFlow('welcome-step', {
      entryPointType: EntryPointType.SETTINGS_PAGE,
    })
    trackEvent('profile_setup_started', {
      entry_point_type: EntryPointType.SETTINGS_PAGE,
    })
  }

  const renderProfileOnboarding = () => (
    <div className="space-y-4">
      <div className="flex items-center justify-between rounded-md bg-secondary px-4 py-2">
        <p className="text-xs text-muted">
          Create your profile to personalize your experience.
        </p>
        <Button onClick={handleContinue} size="sm">
          Continue
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        <div className="max-w-[600px] space-y-4">
          <div className="flex flex-col space-y-1">
            <p className="text-base font-semibold">Profile</p>
            <p className="text-xs text-muted">
              This information is used to personalize your experience, with
              additional content tailored to your profession, practice areas,
              and language.
            </p>
          </div>
          {shouldRenderProfileForm ? (
            <ProfileForm />
          ) : (
            renderProfileOnboarding()
          )}
        </div>
      </SettingsLayout>
    </>
  )
}

export default ProfilePage
