import React, { useEffect, useState } from 'react'

import {
  MigrateWorkspaceToPermBundles,
  PermBundleMigrationResult,
} from 'models/perms'
import { FetchWorkspaces, Workspace } from 'models/workspace'
import { RequestError } from 'services/backend/backend'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import WorkspaceDropdown from 'components/dashboard/workspace-dropdown'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'

const MigrateWorkspaceToPermBundlesComponent = () => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>()
  const [lastResult, setLastResult] = useState<PermBundleMigrationResult>()
  const [dryRun, setDryRun] = useState<boolean>(true)

  const fetchWorkspaces = async () => {
    const workspaces = await FetchWorkspaces()
    setWorkspaces(workspaces)
  }

  useEffect(() => {
    void fetchWorkspaces()
  }, [])

  useEffect(() => {
    setDryRun(true)
    setLastResult(undefined)
  }, [selectedWorkspace?.id])

  const handleMigrate = async (dryRun: boolean = true) => {
    if (!selectedWorkspace) return
    try {
      const result = await MigrateWorkspaceToPermBundles(
        [selectedWorkspace.id],
        dryRun
      )
      setLastResult(result)
      setDryRun(false)
      displaySuccessMessage(
        dryRun
          ? 'Dry run successful - please confirm the logs'
          : 'Migration successful'
      )
    } catch (e) {
      if (e instanceof RequestError) {
        displayErrorMessage(e.message)
      } else {
        displayErrorMessage('An unknown error occurred')
      }
    }
  }

  return (
    <Accordion type="single" collapsible className="rounded-md border">
      <AccordionItem value="migrate-workspace-to-perm-bundles">
        <AccordionTrigger className="p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">
              Migrate Workspaces to Perm Bundles
            </h2>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col items-start gap-2 px-5">
            <p className="text-sm font-medium text-primary">
              Choose a workspace to migrate.
            </p>
            <WorkspaceDropdown
              workspaces={workspaces.filter((w) => !w.deletedAt)}
              selected={selectedWorkspace}
              setSelected={setSelectedWorkspace}
            />
            {lastResult && (
              <ScrollArea maxHeight="max-h-96 ">
                {Object.entries(lastResult).map(([workspaceId, logs]) => (
                  <div key={workspaceId}>
                    {logs.map((log: string) => (
                      <p key={log}>{log}</p>
                    ))}
                  </div>
                ))}
              </ScrollArea>
            )}
            <Button onClick={() => handleMigrate(dryRun)}>
              {dryRun ? 'Dry Run Migration' : 'Migrate for real'}
            </Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default MigrateWorkspaceToPermBundlesComponent
