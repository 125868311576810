import * as React from 'react'

import { FileType } from 'types/file'

import { Button } from 'components/ui/button'
import DocIcon from 'components/ui/icons/doc-icon'
import PdfIcon from 'components/ui/icons/pdf-icon'
import SheetIcon from 'components/ui/icons/sheet-icon'
import UnknownUploadIcon from 'components/ui/icons/unknown-upload-icon'
import { Spinner } from 'components/ui/spinner'

interface DocumentCardProps {
  fileType?: FileType
  title: string
  description: string
  onClick?: () => void
  isLoading?: boolean
}

const FILE_ICONS = {
  [FileType.PDF]: PdfIcon,
  [FileType.WORD]: DocIcon,
  [FileType.EXCEL]: SheetIcon,
  [FileType.WORD_LEGACY]: DocIcon,
  [FileType.EXCEL_LEGACY]: SheetIcon,
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  fileType,
  title,
  description,
  onClick,
  isLoading,
}) => {
  let FileIcon = UnknownUploadIcon
  if (fileType && Object.keys(FILE_ICONS).includes(fileType)) {
    FileIcon = FILE_ICONS[fileType as keyof typeof FILE_ICONS]
  }
  return (
    <Button
      className="flex h-auto flex-col gap-2 rounded-lg bg-accent py-[10px] pl-3 pr-7 transition hover:bg-button-secondary-hover disabled:hover:bg-button-secondary"
      onClick={onClick}
      variant="unstyled"
      disabled={isLoading}
    >
      <div className="flex items-center gap-[10px]">
        <div className="flex size-11 shrink-0 items-center justify-center">
          {isLoading ? (
            <Spinner size="sm" className="fill-primary" />
          ) : (
            <FileIcon className="size-11" />
          )}
        </div>
        <div className="flex flex-col items-start">
          <p className="text-sm font-medium">{title}</p>
          <p className="text-xs text-muted">{description}</p>
        </div>
      </div>
    </Button>
  )
}

export default DocumentCard
