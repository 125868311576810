import React from 'react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'

import AssistantSources from 'components/assistant/components/assistant-sources'
import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import Markdown from 'components/common/markdown/markdown'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const content = blockParams.answer.response
  const headerText = blockParams.answer.headerText
  const { getHrvyInfoMetadata, handleSourceClick } = useSourceUtils({
    sources: (blockParams.answer.sources || []) as Source[],
  })

  const Sidebar = blockParams.answer.sources.length ? (
    <AssistantSources
      allDocuments={[]}
      sources={blockParams.answer.sources as Maybe<Source[]>}
      isStreaming={blockParams.answer.progress !== 100}
      onSetActiveFileId={handleSourceClick}
      // 68px makes it align with the content of the block
      className="absolute left-0 top-[68px] ml-4 sm:ml-4"
    />
  ) : undefined

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
        {content.length > 0 && (
          <div className="mt-5">
            <Markdown
              content={content}
              getHrvyInfoMetadata={getHrvyInfoMetadata}
              className="rounded-md bg-accent p-4"
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const headerText = blockParams.answer.headerText

  return <WorkflowInput footer={headerText} />
}
