import React, { useEffect, useRef } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useMount } from 'react-use'

import { LicenseManager } from '@ag-grid-enterprise/core'
import { datadogRum } from '@datadog/browser-rum'
import { useShallow } from 'zustand/react/shallow'

import { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'

import { useAuthUser } from 'components/common/auth-context'
import VaultAppHeader from 'components/vault/components/vault-app-header/vault-app-header'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import { MAX_QUESTIONS_LIMIT } from 'components/vault/utils/vault'
import { FetchVaultSetup } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'
import VaultErrorPage from 'components/vault/vault-error-page'

import { useVaultProjects } from './hooks/use-vault-projects'
import { useVaultShareUpdatesSubscription } from './utils/use-vault-share-updates-subscription'

const VaultLayout = () => {
  const userInfo = useAuthUser()
  const { projectId } = useParams()

  const error = useVaultStore((state) => state.error)
  const setAgGridEnterpriseLicenseRegistered = useVaultStore(
    useShallow((s) => s.setAgGridEnterpriseLicenseRegistered)
  )
  const setReviewFilesPerQueryLimit = useVaultUsageStore(
    useShallow((s) => s.setReviewFilesPerQueryLimit)
  )
  const setReviewQuestionsPerQueryLimit = useVaultUsageStore(
    useShallow((s) => s.setReviewQuestionsPerQueryLimit)
  )

  const setIsLayoutLoading = useVaultStore(
    useShallow((s) => s.setIsLayoutLoading)
  )
  const setAreUploadButtonsDisabled = useVaultStore(
    useShallow((s) => s.setAreUploadButtonsDisabled)
  )

  const setDocumentClassificationTags = useDocumentClassificationStore(
    useShallow((s) => s.setDocumentClassificationTags)
  )

  const hasRecordedVaultHomeFullLoad = useRef(false)

  useMount(async () => {
    // Start tracking full load time for Vault Home
    if (!projectId) {
      datadogRum.startDurationVital('vaultHomeFullLoad')
    }

    // 1. Register the ag-grid license
    if (userInfo.IsVaultReviewUser) {
      setAgGridEnterpriseLicenseRegistered(true)
      LicenseManager.setLicenseKey(
        process.env.REACT_APP_AG_GRID_LICENSE_KEY || ''
      )
    }

    // 2. Set the review files and questions per query limits
    if (userInfo.vaultFeature) {
      setReviewFilesPerQueryLimit(
        userInfo.workspace.getVaultReviewFilesCountLimit(userInfo.vaultFeature)
      )
      setReviewQuestionsPerQueryLimit(
        userInfo.workspace.getVaultReviewQuestionsCountLimit(
          userInfo.vaultFeature
        )
      )
    } else {
      setReviewFilesPerQueryLimit(null)
      setReviewQuestionsPerQueryLimit(MAX_QUESTIONS_LIMIT)
    }

    // 3. Fetch the vault setup
    try {
      const vaultSetupResponse = await FetchVaultSetup()
      const documentClassificationTags =
        vaultSetupResponse.documentClassificationTags as DocumentClassificationTag[]
      setDocumentClassificationTags(documentClassificationTags)
    } catch (e) {
      console.error('Error fetching vault setup', e)
    }
  })

  const { areProjectsLoaded, projects } = useVaultProjects(projectId, {
    includeExamples: true,
    loadAllMetadata: true,
  })

  useEffect(() => {
    if (areProjectsLoaded) {
      setAreUploadButtonsDisabled(false)
      setIsLayoutLoading(false)

      // Stop tracking full load time for Vault Home
      if (!hasRecordedVaultHomeFullLoad.current && !projectId) {
        datadogRum.stopDurationVital('vaultHomeFullLoad', {
          context: {
            projectCount: projects.length,
          },
        })
        hasRecordedVaultHomeFullLoad.current = true
      }
    }
  }, [
    areProjectsLoaded,
    setAreUploadButtonsDisabled,
    setIsLayoutLoading,
    projectId,
    projects.length,
  ])

  if (error) {
    return <VaultErrorPage />
  }

  return (
    <>
      <VaultShareUpdatesSubscription />
      <VaultAppHeader />
      <Outlet />
    </>
  )
}

const VaultShareUpdatesSubscription = () => {
  useVaultShareUpdatesSubscription()
  return null
}

export default VaultLayout
