import React, { useEffect, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useFlowEntryPointNavigator } from 'providers/modal-flow-provider/modal-flow-controls'
import { useShallow } from 'zustand/react/shallow'

import { updateUserSettings, WelcomeScreenAction } from 'models/user-settings'
import { WelcomeScreen } from 'models/workspace'
import Services from 'services'
import type TelemtryEvent from 'services/honey-comb/telemetry-event'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

import { ProfileSetupFlowType } from './flows/profile-setup/profile-setup'
import { EntryPointType } from './flows/profile-setup/utils/entry-point-types'
import { useUserProfileStore } from './user-profile-store'

/**
 * WelcomeInterstitial is a component that renders a welcome interstitial dialog.
 * It also handles the logic for entering the profile setup flow.
 */
const WelcomeInterstitial = () => {
  const { user, logout } = useAuth0()
  const telEventRef = useRef<TelemtryEvent | null>(null)
  const { trackEvent } = useAnalytics()

  const userInfo = useAuthUser()
  const {
    requiresOnboardingNewUser,
    requiresOnboardingExistingUser,
    shouldSkipOnboarding,
  } = useUserProfileStore(
    useShallow((s) => ({
      requiresOnboardingNewUser: s.requiresOnboardingNewUser,
      requiresOnboardingExistingUser: s.requiresOnboardingExistingUser,
      shouldSkipOnboarding: s.shouldSkipOnboarding,
    }))
  )
  const [interstitial, setInterstitial] = useState<WelcomeScreen | undefined>(
    userInfo.GetInterstitial()
  )

  const enterProfileSetupFlow =
    useFlowEntryPointNavigator<ProfileSetupFlowType>('profile-setup')

  const onCancel = (): void => {
    telEventRef.current?.Finish({ action: WelcomeScreenAction.DECLINED })
    logout({ returnTo: window.location.origin })
  }

  const onOk = async (): Promise<void> => {
    if (!user || !interstitial?.id) {
      return
    }

    const wc: any = userInfo.WelcomeScreens ?? {}
    wc[interstitial.id] = WelcomeScreenAction.ACCEPTED
    telEventRef.current?.Finish({ action: WelcomeScreenAction.ACCEPTED })
    telEventRef.current = null
    setInterstitial(undefined)

    await updateUserSettings({
      welcomeScreens: wc,
    })
  }

  useEffect(() => {
    if (!interstitial) return

    telEventRef.current = Services.HoneyComb.Start({
      metric: 'ui.show_welcome_screen',
      welcome_screen_id: interstitial.id,
      welcome_screen_type: interstitial.type,
      task_type: interstitial.taskType,
    })
  }, [interstitial])

  useEffect(() => {
    if (!userInfo.isCreateUserProfilesUser || shouldSkipOnboarding) return

    if (!interstitial && requiresOnboardingNewUser) {
      enterProfileSetupFlow('welcome-step', {
        entryPointType: EntryPointType.NEW_USER,
      })
      trackEvent('profile_setup_started', {
        entry_point_type: EntryPointType.NEW_USER,
      })
    } else if (!interstitial && requiresOnboardingExistingUser) {
      enterProfileSetupFlow('welcome-step', {
        entryPointType: EntryPointType.EXISTING_USER,
      })
      trackEvent('profile_setup_started', {
        entry_point_type: EntryPointType.EXISTING_USER,
      })
    }
  }, [
    interstitial,
    enterProfileSetupFlow,
    requiresOnboardingNewUser,
    requiresOnboardingExistingUser,
    shouldSkipOnboarding,
    userInfo.isCreateUserProfilesUser,
    trackEvent,
  ])

  if (!interstitial) return null

  return (
    <Dialog open>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>{interstitial.title || 'Important notice'}</DialogTitle>
        </DialogHeader>
        <div
          className="flex flex-col gap-4"
          dangerouslySetInnerHTML={{ __html: interstitial.content }}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={onOk}>Accept</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={onCancel} variant="outline">
              Decline
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeInterstitial
