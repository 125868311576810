import React from 'react'

import { Permission, UserInfo } from 'models/user-info'
import { IntegrationType } from 'openapi/models/IntegrationType'

import GoogleDriveLogo from 'components/common/google-drive-logo'
import SharepointOneDriveLogo from 'components/common/sharepoint-onedrive-logo'
import GoogleDriveIcon from 'components/ui/icons/google-drive-icon'
import SharePointIcon from 'components/ui/icons/sharepoint-icon'

export interface ResourceUrlProps {
  required: boolean
  tooltip: string
  inputPlaceholder?: string
}

export interface IntegrationDefinition {
  name: string
  permission: Permission
  icon: React.ComponentType<{ className?: string }>
  LogoComponent: React.ReactNode
  title: React.ComponentType<{ className?: string }>
  description: string
  available: (userInfo: UserInfo) => boolean
  enablementContent: string[]
  resourceUrlProps?: ResourceUrlProps
}

export const IntegrationDefinitions: Record<
  IntegrationType,
  IntegrationDefinition
> = {
  [IntegrationType.SHAREPOINT]: {
    name: 'SharePoint',
    permission: Permission.SHAREPOINT_INTEGRATION,
    icon: SharePointIcon,
    LogoComponent: <SharepointOneDriveLogo wrap />,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">SharePoint</span>{' '}
        <span className="text-secondary"> (with OneDrive)</span>
      </p>
    ),
    description:
      'Connect your work account to upload files directly from SharePoint or OneDrive.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(IntegrationType.SHAREPOINT) &&
        userInfo.permissions.includes(Permission.SHAREPOINT_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their SharePoint and OneDrive accounts from Harvey.',
      'Upload files from SharePoint and OneDrive directly into Harvey.',
    ],
    resourceUrlProps: {
      required: true,
      tooltip:
        'The resource URL is the URL of SharePoint or OneDrive site you want to connect to. It should start with https://',
      inputPlaceholder: 'https://acme.sharepoint.com/',
    },
  },
  [IntegrationType.GOOGLE_DRIVE]: {
    name: 'Google Drive',
    permission: Permission.GOOGLE_DRIVE_INTEGRATION,
    icon: GoogleDriveIcon,
    LogoComponent: <GoogleDriveLogo />,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">Google Drive</span>
      </p>
    ),
    description: 'Users can upload files directly from Google Drive.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(
          IntegrationType.GOOGLE_DRIVE
        ) && userInfo.permissions.includes(Permission.GOOGLE_DRIVE_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their Google Drive account from Harvey.',
      'Upload files from Google Drive directly into Harvey.',
      'Use these files for collaboration or sharing.',
    ],
  },
}
