import React from 'react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import Summary from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/summary'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAntitrustFilingsAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams }) => {
  const content = blockParams.answer.metadata?.countryCodeToAnalysis
  const headerText = blockParams.answer.headerText

  if (!content) {
    // TODO: countryCodeToAnalysis is nullable, but not marked as such in the model
    return null
  }

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
        <Summary data={content} />
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAntitrustFilingsAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams }) => {
  const headerText = blockParams.answer.headerText

  return <WorkflowInput footer={headerText} />
}
