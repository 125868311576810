import React, { useMemo } from 'react'
import { useUnmount } from 'react-use'

import { HTTPError } from 'ky'
import _ from 'lodash'
import { HardDrive, FolderOpen } from 'lucide-react'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { mbToBytes, bytesToReadable, mbToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
  FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP,
  PROJECT_COUNT_DISABLED_TOOLTIP,
  REMOVE_PARAMS,
  maxTotalFileSizeInMb,
  projectsPath,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { CreateVaultFolder } from 'components/vault/utils/vault-fetcher'
import {
  getVaultProjects,
  createVaultFoldersHierarchy,
  computeFileHierarchy,
  optimisticallyCreateVaultHierarchyFiles,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const CreateProjectFooter = ({
  cancelHandler,
}: {
  cancelHandler: () => void
}) => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const isSubmitting = useVaultCreateProjectStore((s) => s.isSubmitting)
  const isDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.isDropzoneLoading
  )
  const newFolderName = useVaultCreateProjectStore((s) => s.newFolderName)
  const filesToUpload = useVaultCreateProjectStore((s) => s.filesToUpload)
  const totalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.totalFileSizeInBytes
  )
  const setIsSubmitting = useVaultCreateProjectStore((s) => s.setIsSubmitting)
  const setFileUploadMetadata = useVaultCreateProjectStore(
    (s) => s.setFileUploadMetadata
  )
  const clientMatterName = useVaultCreateProjectStore((s) => s.clientMatterName)
  const allFolderIdToVaultFolder = useVaultStore(
    (s) => s.allFolderIdToVaultFolder
  )
  const rootVaultFolderIds = useVaultStore((s) => s.rootVaultFolderIds)
  const setCurrentProject = useVaultStore((s) => s.setCurrentProject)
  const upsertVaultFolders = useVaultStore((s) => s.upsertVaultFolders)
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const updateProjectMetadata = useVaultStore((s) => s.updateProjectMetadata)
  const setShowProcessingProgress = useVaultStore(
    (s) => s.setShowProcessingProgress
  )
  const setClientMatterName = useVaultCreateProjectStore(
    (s) => s.setClientMatterName
  )
  const clientMatters = useClientMattersStore((s) => s.clientMatters)
  const canCmUserQuery = useClientMattersStore((s) => s.canCmUserQuery)

  const projectsCount =
    getVaultProjects({
      allFolderIdToVaultFolder,
      rootVaultFolderIds,
      userId: userInfo.dbId,
    }).length + 1
  const projectsCountLimit = userInfo.workspace.getVaultProjectsCountLimit(
    userInfo.vaultFeature
  )
  const isProjectCountExceeded = projectsCount > projectsCountLimit

  useUnmount(() => {
    setClientMatterName('')
    cancelHandler()
  })

  const cancelAndNavigateHandler = () => {
    trackEvent('Vault Project Creation Cancelled')
    navigate(BaseAppPath.Vault, {}, REMOVE_PARAMS)
  }

  const createProjectHandler = async () => {
    const folderName = newFolderName.trim()
    if (folderName.includes('/') || folderName.includes('\\')) {
      displayErrorMessage(
        `Project name is not valid: it cannot contain “/” or “\\”`
      )
      return
    }
    const clientMatterId =
      clientMatters.find((cm) => cm.name === clientMatterName)?.id ?? null
    setIsSubmitting(true)
    try {
      const hierarchyRootFolder = await CreateVaultFolder(
        folderName,
        null,
        clientMatterId
      )
      const fileHierarchy = computeFileHierarchy(filesToUpload)
      const { folderIdToVaultFolderId, createdFolders } =
        await createVaultFoldersHierarchy(fileHierarchy, hierarchyRootFolder.id)
      upsertVaultFolders(
        [hierarchyRootFolder, ...createdFolders],
        userInfo.dbId,
        false,
        hierarchyRootFolder.id
      )
      const { filesGroupedByVaultFolderId, vaultFolderIdToPrefix } =
        optimisticallyCreateVaultHierarchyFiles({
          rootFolderId: hierarchyRootFolder.id,
          projectId: hierarchyRootFolder.id,
          fileHierarchy,
          folderIdToVaultFolderId,
          upsertVaultFiles,
          updateProjectMetadata,
        })
      setFileUploadMetadata(filesGroupedByVaultFolderId, vaultFolderIdToPrefix)
      setCurrentProject(hierarchyRootFolder, true)
      setShowProcessingProgress(hierarchyRootFolder.id, true)

      const newPath = `${BaseAppPath.Vault}${projectsPath}${hierarchyRootFolder.id}`
      navigate(newPath, {}, REMOVE_PARAMS)
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 409) {
        displayErrorMessage(`Project name already exists.`)
      } else if (e instanceof HTTPError) {
        displayErrorMessage(`Something went wrong. Could not create project.`)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const isTotalFileSizeExceeded =
    totalFileSizeInBytes > mbToBytes(maxTotalFileSizeInMb(userInfo))
  const isFilesCountExceeded =
    filesToUpload.length >
    userInfo.workspace.getVaultFilesCountLimit(userInfo.vaultFeature)
  const userRequiresClientMatter =
    userInfo.IsVaultProjectClientMatterUser && !canCmUserQuery()
  const shouldDisableDueToNoClientMatter =
    userRequiresClientMatter && _.isEmpty(clientMatterName)
  const isDisabled = useMemo(() => {
    return (
      isDropzoneLoading ||
      isSubmitting ||
      newFolderName.trim().length === 0 ||
      isFilesCountExceeded ||
      isTotalFileSizeExceeded ||
      isProjectCountExceeded ||
      shouldDisableDueToNoClientMatter
    )
  }, [
    isDropzoneLoading,
    isSubmitting,
    newFolderName,
    isProjectCountExceeded,
    isFilesCountExceeded,
    isTotalFileSizeExceeded,
    shouldDisableDueToNoClientMatter,
  ])

  const tooltipDisabledMessage = useMemo(() => {
    if (isFilesCountExceeded || isTotalFileSizeExceeded) {
      return FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP
    } else if (isProjectCountExceeded) {
      return PROJECT_COUNT_DISABLED_TOOLTIP
    } else if (shouldDisableDueToNoClientMatter) {
      return 'Must select a client matter'
    }

    return undefined
  }, [
    isFilesCountExceeded,
    isTotalFileSizeExceeded,
    isProjectCountExceeded,
    shouldDisableDueToNoClientMatter,
  ])

  return (
    <div className="flex h-auto min-h-[72px] w-full items-center justify-between space-x-2 border-t px-6 py-2">
      <div className="flex basis-1/2 flex-wrap items-center gap-2 sm:basis-2/3">
        {filesToUpload.length > 0 && (
          <>
            <div className="flex items-center space-x-1">
              <Icon icon={HardDrive} className="text-muted" />
              <p className="line-clamp-2 text-xs text-muted">{`${bytesToReadable(
                totalFileSizeInBytes
              )} of ${mbToReadable(maxTotalFileSizeInMb(userInfo))}`}</p>
              {isTotalFileSizeExceeded && (
                <Tooltip>
                  <TooltipTrigger>
                    <AlertIcon size="small" className="mr-1" />
                  </TooltipTrigger>
                  <TooltipContent>
                    {FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP}
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
            <div className="flex items-center space-x-1">
              <Icon icon={FolderOpen} className="text-muted" />
              <p className="line-clamp-2 text-xs text-muted">{`${
                filesToUpload.length
              } of ${userInfo.workspace
                .getVaultFilesCountLimit(userInfo.vaultFeature)
                .toLocaleString()} files`}</p>
              {isFilesCountExceeded && (
                <Tooltip>
                  <TooltipTrigger>
                    <AlertIcon size="small" className="mr-1" />
                  </TooltipTrigger>
                  <TooltipContent>
                    {FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP}
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex basis-1/2 flex-row-reverse gap-2 sm:basis-1/3">
        <Button
          isLoading={isSubmitting}
          disabled={isDisabled}
          onClick={createProjectHandler}
          tooltip={tooltipDisabledMessage}
        >
          <p className="truncate">Create project</p>
        </Button>
        <Button variant="outline" onClick={cancelAndNavigateHandler}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default CreateProjectFooter
