/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the default visibility of a permission bundle
 * @export
 */
export const DefaultVisibility = {
    MANAGE: 'manage',
    VIEW: 'view',
    HIDE: 'hide',
    INTERNAL_ONLY: 'internal_only'
} as const;
export type DefaultVisibility = typeof DefaultVisibility[keyof typeof DefaultVisibility];


export function instanceOfDefaultVisibility(value: any): boolean {
    for (const key in DefaultVisibility) {
        if (Object.prototype.hasOwnProperty.call(DefaultVisibility, key)) {
            if (DefaultVisibility[key as keyof typeof DefaultVisibility] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DefaultVisibilityFromJSON(json: any): DefaultVisibility {
    return DefaultVisibilityFromJSONTyped(json, false);
}

export function DefaultVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultVisibility {
    return json as DefaultVisibility;
}

export function DefaultVisibilityToJSON(value?: DefaultVisibility | null): any {
    return value as any;
}

export function DefaultVisibilityToJSONTyped(value: any, ignoreDiscriminator: boolean): DefaultVisibility {
    return value as DefaultVisibility;
}

