/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Title = {
    SENIOR_ASSOCIATE: 'SENIOR_ASSOCIATE',
    MID_LEVEL_ASSOCIATE: 'MID-LEVEL_ASSOCIATE',
    JUNIOR_ASSOCIATE: 'JUNIOR_ASSOCIATE',
    COUNSEL: 'COUNSEL',
    IN_HOUSE: 'IN-HOUSE',
    KNOWLEDGE_MANAGER_LIBRARIAN: 'KNOWLEDGE_MANAGER/LIBRARIAN',
    LAW_CLERK_TRAINEE: 'LAW_CLERK/TRAINEE',
    PARTNER: 'PARTNER',
    PARALEGAL: 'PARALEGAL',
    ADMINISTRATIVE_INNOVATION_IT_OR_OTHER_BUSINESS_ROLE: 'ADMINISTRATIVE_INNOVATION_IT_OR_OTHER_BUSINESS_ROLE'
} as const;
export type Title = typeof Title[keyof typeof Title];


export function instanceOfTitle(value: any): boolean {
    for (const key in Title) {
        if (Object.prototype.hasOwnProperty.call(Title, key)) {
            if (Title[key as keyof typeof Title] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TitleFromJSON(json: any): Title {
    return TitleFromJSONTyped(json, false);
}

export function TitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Title {
    return json as Title;
}

export function TitleToJSON(value?: Title | null): any {
    return value as any;
}

export function TitleToJSONTyped(value: any, ignoreDiscriminator: boolean): Title {
    return value as Title;
}

