import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'

import { SafeRecord } from 'utils/safe-types'

/**
 * Represents the state for file caching:
 * - `files` holds cached file objects keyed by their file ID.
 * - `filePromises` holds in-flight network requests (as promises) keyed by file ID,
 *   to prevent multiple simultaneous fetches for the same file.
 */
interface FileCacheState {
  files: SafeRecord<string, UploadedFile>
  filePromises: SafeRecord<string, Promise<UploadedFile>>
}

/**
 * Defines the actions available on the file cache store:
 * - `addFile` adds a file to the cache.
 * - `getFile` retrieves a file from the cache or, if not cached, fetches it from the backend.
 */
interface FileCacheAction {
  /**
   * Add a file to the cache.
   * @param file The UploadedFile object to add.
   */
  addFile: (file: UploadedFile) => void

  /**
   * Retrieve a file by ID, returning it either from the cache or fetching it from the backend.
   * If a network request is already in flight for this file, this function will await that same request.
   * @param fileId The ID of the file to retrieve.
   * @returns A promise that resolves to the requested UploadedFile or undefined if not found.
   */
  getFile: (fileId: string) => Promise<UploadedFile | undefined>
}

/** The initial state for the file cache store. */
const initialState: FileCacheState = {
  files: {},
  filePromises: {},
}

/**
 * Fetches an UploadedFile from the backend service based on the provided file ID.
 * This function throws on error if the file cannot be retrieved.
 * @param fileId The ID of the file to fetch.
 * @returns A promise that resolves to an UploadedFile.
 */
const retrieveFile = async (fileId: string) =>
  await Services.Backend.Get<UploadedFile>(`file/${fileId}`, {
    throwOnError: true,
  })

/**
 * This Zustand store manages a cache of files and ensures that multiple concurrent requests
 * for the same file do not trigger redundant network calls.
 *
 * By tracking ongoing requests via `filePromises`, any subsequent request for the same file
 * will wait for the existing request to complete rather than initiating a new one.
 */
export const useFileCache = create(
  devtools(
    immer<FileCacheState & FileCacheAction>((set, get) => ({
      ...initialState,

      addFile: (file: UploadedFile) => {
        // Add the provided file to the cache
        set((state) => {
          state.files[file.id] = file
        })
      },

      getFile: async (fileId: string) => {
        // If we already have the file cached, return it immediately
        const existingFile = get().files[fileId]
        if (existingFile) {
          return existingFile
        }

        // If a request is already in progress for this file, return that in-flight promise
        const existingPromise = get().filePromises[fileId]
        if (existingPromise) {
          return existingPromise
        } else {
          // Otherwise, initiate a new request and store its promise in filePromises
          const promise = retrieveFile(fileId)
          set((state) => {
            state.filePromises[fileId] = promise
          })

          try {
            // Wait for the file to be retrieved
            const file = await promise
            // Once retrieved, store it in the cache and remove the tracking promise
            set((state) => {
              state.files[file.id] = file
              delete state.filePromises[file.id]
            })
            return file
          } catch (e) {
            // If the request fails, remove the promise so a future attempt can be made
            set((state) => {
              delete state.filePromises[fileId]
            })
            throw e
          }
        }
      },
    }))
  )
)
