import React, { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useWindowSize } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileTypeToExtension } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { cn } from 'utils/utils'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import AddMoreFiles from 'components/common/add-more-files'
import { useAuthUser } from 'components/common/auth-context'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'
import VaultCreateDisclaimer from 'components/vault/components/vault-create-disclaimer'
import VaultUploadInset from 'components/vault/components/vault-upload-inset'
import {
  FileToUpload,
  maxFileSizeInMb,
  maxTotalFileSizeInMb,
} from 'components/vault/utils/vault'
import {
  MAX_EXCEL_FILE_SIZE_IN_MB,
  ACCEPTED_FILE_TYPES,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import {
  handleDroppedFilesOrDeletedFiles,
  sumFileSizesInBytes,
} from 'components/vault/utils/vault-helpers'

import VaultCreateFileUpload from './vault-create-file-upload'
import VaultUploadedFileList from './vault-uploaded-file-list'

const ANALYTICS_NAME = 'VAULT_PROJECT'

// 99px: height of header
// 72px: height of footer
// 40px: padding-top height
// 16px: padding bottom
// 72px: height of project name
// 40px: margin between project name and disclaimer
// 33.5px: Add more files + padding
// 8px: padding around Files heading
// 66px: height of disclaimer
// 16px: margin between disclaimer and file list
const LIST_UNAVAILABLE_HEIGHT = 99 + 72 + 40 + 16 + 72 + 40 + 29.5 + 8 + 66 + 16

const CreateProject = () => {
  const userInfo = useAuthUser()
  const { height } = useWindowSize()
  const [
    isDropzoneLoading,
    isSubmitting,
    newFolderName,
    filesToUpload,
    totalFileSizeInBytes,
    clientMatterName,
  ] = useVaultCreateProjectStore(
    useShallow((s) => [
      s.isDropzoneLoading,
      s.isSubmitting,
      s.newFolderName,
      s.filesToUpload,
      s.totalFileSizeInBytes,
      s.clientMatterName,
    ])
  )
  const [shouldCmLockQueries] = useClientMattersStore(
    useShallow((state) => [state.shouldCmLockQueries])
  )

  const setNewFolderName = useVaultCreateProjectStore((s) => s.setNewFolderName)
  const setDroppedFiles = useVaultCreateProjectStore((s) => s.setDroppedFiles)
  const setTotalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.setTotalFileSizeInBytes
  )
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setIsDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.setIsDropzoneLoading
  )
  const setClientMatterName = useVaultCreateProjectStore(
    (s) => s.setClientMatterName
  )
  const onFolderNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const folderName = e.target.value
    setNewFolderName(folderName)
  }

  const recordFileDrop = useDropzoneTrack(ANALYTICS_NAME)

  const onFileDrop = useCallback(
    async (files: File[]) => {
      setIsDropzoneLoading(false)
      setDroppedFiles(files)
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: files,
        hasFolderName: true,
        currentTotalSizeInBytes: totalFileSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: true,
        existingFilesToUpload: filesToUpload,
      })
      recordFileDrop(files)
    },
    [
      setIsDropzoneLoading,
      setDroppedFiles,
      totalFileSizeInBytes,
      setTotalFileSizeInBytes,
      setFilesToUpload,
      filesToUpload,
      recordFileDrop,
    ]
  )

  const onFileDelete = useCallback(
    async (file: FileToUpload) => {
      const filesToUploadAfterDeletion = filesToUpload.filter(
        (f) => f.name !== file.name
      )
      const currentTotalSizeInBytes = await sumFileSizesInBytes(
        filesToUploadAfterDeletion.map((file) => file.file)
      )
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: [],
        hasFolderName: true,
        currentTotalSizeInBytes: currentTotalSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: false,
        existingFilesToUpload: filesToUpload.filter(
          (f) => f.name !== file.name
        ),
      })
    },
    [filesToUpload, setFilesToUpload, setTotalFileSizeInBytes]
  )

  const handleVaultFileDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setIsDropzoneLoading(true)
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: filesToUpload.length,
        maxFiles: userInfo.workspace.getVaultFilesCountLimit(
          userInfo.vaultFeature
        ),
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(maxFileSizeInMb(userInfo)),
        maxExcelFileSize: mbToBytes(MAX_EXCEL_FILE_SIZE_IN_MB),
        maxZipFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
          currentTotalFileSize: await sumFileSizesInBytes(
            filesToUpload.map((file) => file.file)
          ),
        },
        shouldSkipPasswordProtectionCheck: true,
        handleAcceptedFiles: onFileDrop,
        handleRejectedFiles: () => {
          setIsDropzoneLoading(false)
        },
      })
    },
    [filesToUpload, onFileDrop, setIsDropzoneLoading, userInfo]
  )

  const onUploadFromIntegration = async (files: File[]) => {
    setIsDropzoneLoading(true)
    await handleVaultFileDrop(files, [])
    setIsDropzoneLoading(false)
  }

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: userInfo.workspace.getVaultFilesCountLimit(userInfo.vaultFeature),
    noClick: true,
    onDrop: handleVaultFileDrop,
  })

  const [setSharepointPickerOpenState, setIntegrationFilePickerOpenState] =
    useIntegrationsStore(
      useShallow((s) => [
        s.setSharepointPickerOpenState,
        s.setIntegrationFilePickerOpenState,
      ])
    )

  const hasUploadedFiles = filesToUpload.length > 0

  const handleIntegrationClick = async (integrationType: IntegrationType) => {
    const acceptedFileTypes = _.flatMap(
      ACCEPTED_FILE_TYPES,
      (type) => FileTypeToExtension[type]
    )

    if (integrationType === IntegrationType.SHAREPOINT) {
      // TODO: remove this after we merge sharepoint into overall integration state
      setSharepointPickerOpenState({
        acceptedFileTypes,
        onUploadFromSharepoint: onUploadFromIntegration,
      })
    } else {
      setIntegrationFilePickerOpenState({
        integrationType,
        onUploadFromIntegration,
        acceptedFileTypes,
      })
    }
  }

  return (
    <div {...getRootProps()} className="relative size-full">
      <input {...getInputProps()} />
      {isDragActive && (
        <div className="flex items-center justify-center">
          <VaultUploadInset isDragActive={isDragActive} />
        </div>
      )}

      <div className="container max-w-[622px] pb-4 pt-10">
        <div className="w-full space-y-10 px-2">
          <div className="space-y-2">
            <Label htmlFor="project-name">Project name</Label>
            <Input
              required
              placeholder="Choose a name for your project"
              value={newFolderName}
              disabled={isSubmitting}
              onChange={onFolderNameChange}
              maxLength={64}
            />
          </div>
          {userInfo.IsVaultProjectClientMatterUser && (
            <ClientMatterSelect
              className={cn({
                'text-muted': _.isEmpty(clientMatterName),
              })}
              selectedValue={clientMatterName ?? undefined}
              setSelectedValue={setClientMatterName}
              allowAddNewItem={false}
              showClearOption={!shouldCmLockQueries}
            />
          )}
          <div>
            <div className="flex items-center justify-between gap-1 pb-2">
              <div className="flex items-center gap-1 py-1">
                <Label htmlFor="files">Files</Label>
                <p className="text-muted">(Optional)</p>
              </div>
              {hasUploadedFiles &&
                (isDropzoneLoading ? (
                  <div className="flex h-6 items-center">
                    <Spinner size="xxs" />
                    <p className="text-xs">Adding...</p>
                  </div>
                ) : (
                  <div>
                    <AddMoreFiles
                      onUploadFromComputer={open}
                      onUploadFromIntegration={handleIntegrationClick}
                    />
                  </div>
                ))}
            </div>
            <VaultCreateDisclaimer />
            <div className="mt-4">
              {!hasUploadedFiles ? (
                <VaultCreateFileUpload
                  acceptedFileTypes={ACCEPTED_FILE_TYPES}
                  onUploadFromIntegration={onUploadFromIntegration}
                  open={open}
                />
              ) : (
                <VaultUploadedFileList
                  height={height - LIST_UNAVAILABLE_HEIGHT}
                  filesToUpload={filesToUpload}
                  isSubmitting={isSubmitting}
                  onFileDelete={onFileDelete}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateProject
