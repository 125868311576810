import React, { useEffect } from 'react'

import { HTTPError } from 'ky'
import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

import { displayErrorMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Spinner } from 'components/ui/spinner'
import { VaultItemType } from 'components/vault/utils/vault'
import { PatchFolder, PatchFile } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultRenameDialog: React.FC = () => {
  const [
    renameRecord,
    isRenameDialogOpen,
    activeDocument,
    currentProject,
    setIsRenameDialogOpen,
    upsertVaultFolders,
    upsertVaultFiles,
    setActiveDocument,
  ] = useVaultStore(
    useShallow((state) => [
      state.renameRecord,
      state.isRenameDialogOpen,
      state.activeDocument,
      state.currentProject,
      state.setIsRenameDialogOpen,
      state.upsertVaultFolders,
      state.upsertVaultFiles,
      state.setActiveDocument,
    ])
  )
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()

  const [isRenaming, setIsRenaming] = React.useState<boolean>(false)
  const [newName, setNewName] = React.useState<string>('')

  useEffect(() => {
    if (!renameRecord) return
    setNewName(renameRecord.name)
  }, [renameRecord])

  const dialogTitle = `Rename ${renameRecord?.type}`
  const inputDescription = `Enter the new name for the ${renameRecord?.type}`

  const onFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value)
  }

  const onRenameSubmit = React.useCallback(async () => {
    const cleanedNewName = newName.trim()
    if (
      !renameRecord ||
      !cleanedNewName ||
      cleanedNewName === renameRecord.name
    ) {
      return
    }

    if (cleanedNewName.includes('/') || cleanedNewName.includes('\\')) {
      displayErrorMessage(
        `${_.upperFirst(
          renameRecord.type
        )} name is not valid: it cannot contain “/” or “\\”`
      )
      return
    }

    setIsRenaming(true)

    const renameRecordType = renameRecord.type
    const key = `${renameRecordType}_id`
    Services.HoneyComb.Record({
      metric: `ui.vault_rename_${renameRecordType}_dialog_submitted`,
      [key]: renameRecord.id,
    })
    trackEvent(
      `Vault Rename ${_.upperFirst(renameRecordType)} Dialog Submitted`,
      {
        record_id: renameRecord.id,
      }
    )

    try {
      const requestData = {
        new_name: cleanedNewName,
      }
      if (
        renameRecord.type === VaultItemType.project ||
        renameRecord.type === VaultItemType.folder
      ) {
        const updatedFolderResponse = await PatchFolder(
          renameRecord.id,
          requestData
        )
        upsertVaultFolders(
          [updatedFolderResponse],
          userInfo.dbId,
          false,
          currentProject?.id
        )
      } else {
        const updateFileResponse = await PatchFile(renameRecord.id, requestData)
        upsertVaultFiles([updateFileResponse], currentProject?.id)
        if (
          activeDocument &&
          'id' in activeDocument &&
          (activeDocument as VaultFile).id === renameRecord.id
        ) {
          // This is to update the file name in the push sheet
          setActiveDocument(updateFileResponse)
        }
      }

      setNewName('')
      setIsRenameDialogOpen(false)
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 409) {
        displayErrorMessage(
          `Failed to rename ${renameRecord.type} from ${renameRecord.name} to ${newName}. A ${renameRecord.type} with the name ${newName} already exists`
        )
      } else if (e instanceof RequestError || e instanceof HTTPError) {
        displayErrorMessage(
          `Failed to rename ${renameRecord.type} from ${renameRecord.name} to ${newName}`
        )
      }
    }
    setIsRenaming(false)
  }, [
    renameRecord,
    newName,
    activeDocument,
    setIsRenameDialogOpen,
    setIsRenaming,
    upsertVaultFolders,
    upsertVaultFiles,
    setActiveDocument,
    trackEvent,
    userInfo.dbId,
    currentProject?.id,
  ])

  return (
    <Dialog open={isRenameDialogOpen}>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogDescription>{inputDescription}</DialogDescription>
        </DialogHeader>
        <Input
          placeholder={renameRecord?.name}
          disabled={isRenaming}
          value={newName}
          onChange={onFolderNameChange}
          maxLength={64}
        />
        <div>
          <div className="mt-6 flex justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                const renameRecordType = renameRecord?.type
                const key = `${renameRecordType}_id`
                Services.HoneyComb.Record({
                  metric: `ui.vault_rename_${renameRecordType}_dialog_cancelled`,
                  [key]: renameRecord?.id,
                })
                trackEvent(
                  `Vault Rename ${_.upperFirst(
                    renameRecordType
                  )} Dialog Cancelled`,
                  {
                    [key]: renameRecord?.id,
                  }
                )
                setIsRenameDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isRenaming || newName.length === 0}
              onClick={onRenameSubmit}
              data-testid="vault-rename-dialog--rename-button"
            >
              {isRenaming ? (
                <div className="flex items-center">
                  <Spinner size="xxs" className="top-3 mr-2" />
                  <p>Renaming…</p>
                </div>
              ) : (
                <p>Rename {renameRecord?.type}</p>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultRenameDialog
