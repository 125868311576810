import React from 'react'

const DataRow: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex space-x-4">
      <p className="w-24 shrink-0 text-xs text-muted">{label}</p>
      <p className="text-xs">{value}</p>
    </div>
  )
}

export default DataRow
