import React, { memo } from 'react'

import { RowHeightParams } from 'ag-grid-community'
import { useShallow } from 'zustand/react/shallow'

import { DataGrid } from 'components/ui/data-grid/data-grid-v1'
import { CellType } from 'components/workflows/workflow/competitive-analysis/components/results-table'
import { useCompetitiveAnalysisDataGridStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'

import HeaderCell from './cells/header-cell'
import RowNumberCell from './cells/row-number-cell'
import TextCell from './cells/text-cell'

const CompetitiveAnalysisDataGrid = () => {
  const setGridApi = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.setGridApi)
  )

  return (
    <DataGrid
      setGridApi={setGridApi}
      gridOptions={{
        reactiveCustomComponents: true,

        rowSelection: 'multiple',
        suppressRowClickSelection: true,

        suppressScrollOnNewData: true,
        suppressColumnMoveAnimation: true,
        suppressCellFocus: true,
        suppressHeaderFocus: true,
      }}
      getRowHeight={(e: RowHeightParams) => {
        const node = e.node
        const numYears = node.data?.numYears
        return numYears ? 30 * numYears : null
      }}
      defaultColDef={{
        headerComponent: HeaderCell,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          maxNumConditions: Number.MAX_SAFE_INTEGER,
        },
      }}
      columnTypes={{
        [CellType.GUTTER]: {
          useValueFormatterForExport: false,
          resizable: false,
          cellRenderer: () => <div className="h-full w-full bg-secondary" />,
          headerComponent: () => <div className="h-full w-full bg-secondary" />,
        },
        [CellType.NUMBER]: {
          useValueFormatterForExport: false,
          cellRenderer: RowNumberCell,
          sortable: false,
        },
        [CellType.NAME]: {
          useValueFormatterForExport: false,
          cellRenderer: TextCell,
        },
        [CellType.TEXT]: {
          useValueFormatterForExport: false,
          cellRenderer: TextCell,
          pinned: false,
        },
        [CellType.FINANCIAL]: {
          useValueFormatterForExport: false,
          cellRenderer: TextCell,
        },
      }}
    />
  )
}

CompetitiveAnalysisDataGrid.displayName = 'CompetitiveAnalysisDataGrid'
export default memo(CompetitiveAnalysisDataGrid)
