import React from 'react'
import { Route, NavigateOptions } from 'react-router-dom'

import * as Sentry from '@sentry/browser'

import { UserInfo } from 'models/user-info'
import { AuthType } from 'openapi/models/AuthType'
import { ResearchArea } from 'openapi/models/ResearchArea'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import AuthenticationGuard from 'components/auth/auth-guard'
import { BaseAppPath } from 'components/base-app-path'
import { LibraryItemKind } from 'components/library/library-types'
import { RESEARCH_PATH } from 'components/research/research-helpers'
import { projectsPath, queriesPath } from 'components/vault/utils/vault'
import {
  WorkflowTypeToWorkflowConfig,
  KindToWorkflowType,
} from 'components/workflows/workflow-config'
import { userHasPermissionForWorkflow } from 'components/workflows/workflows-utils'

import type { SentryAssignees } from './sentry'
import { TaskType } from './task'
import {
  AssistantV1TaskTypes,
  ResearchV1TaskTypesMovedToV2,
  TaxTaskTypes,
} from './task-definitions'

export const NAV_STATE_PARAM = 'locState'

export const PWD_AUTH_CONN = AuthType.USERNAME_PASSWORD_AUTHENTICATION

interface EventInfo {
  id: number
  kind: TaskType
  libraryItemKind?: LibraryItemKind
  vaultFolderId?: string
}

const getWorkflowRoute = (
  event: EventInfo,
  userInfo: UserInfo
): string | null => {
  const workflowType = KindToWorkflowType[event.kind]
  if (workflowType) {
    const workflowConfig = WorkflowTypeToWorkflowConfig[workflowType]
    if (!workflowConfig) {
      throw new Error(
        `No workflow details found for workflow type: ${workflowType} for event: ${event.id}`
      )
    }
    if (!userHasPermissionForWorkflow(userInfo, workflowType)) {
      console.warn(
        `User does not have permission for workflow type: ${workflowType} for event: ${event.id}`
      )
    }
    return `${workflowConfig.path}/${event.id}`
  }
  return null
}

const getResearchRoute = (event: EventInfo): string | null => {
  const kindToResearchAreaTax: { [K in TaskType]?: string } =
    Object.fromEntries(
      TaxTaskTypes.map((taskType) => [taskType, ResearchArea.TAX])
    )
  const kindToResearchArea: { [K in TaskType]?: string } = {
    ...kindToResearchAreaTax,
    [TaskType.SEC_EDGAR_QA]: ResearchArea.EDGAR,
    [TaskType.MEMOS_QA]: ResearchArea.MEMOS,
    [TaskType.USA_CASELAW]: ResearchArea.USCASELAW,
    [TaskType.FRANCE_CASELAW]: ResearchArea.FRANCECASELAW,
    [TaskType.EURLEX_QA]: ResearchArea.EURLEX,
    [TaskType.AUS_BREACH_REPORTING]: ResearchArea.AUSBREACHREPORTING,
    [TaskType.CUATRECASAS]: ResearchArea.CUATRECASAS,
  }
  const area = kindToResearchArea[event.kind]

  if (TaxTaskTypes.includes(event.kind)) {
    return `/assistant/${AssistantMode.ASSIST}/${event.id}`
  } else if (ResearchV1TaskTypesMovedToV2.includes(event.kind)) {
    return `/assistant/${AssistantMode.ASSIST}/${event.id}`
  }

  if (area) {
    return `${RESEARCH_PATH}/${area}/${event.id}`
  }
  return null
}

const getAssistantRoute = (
  event: EventInfo,
  isAssistantV2User: boolean
): string | null => {
  const assistantTypes: TaskType[] = [
    // TODO: Are there more assistant cases here? We used to default to assistant route so want to make sure I capture everything
    TaskType.ASSISTANT,
    TaskType.OPEN_ENDED,
    TaskType.DOCUMENT_QA,
    TaskType.MULTI_DOC_QA,
    TaskType.CORPUS_QA,
    TaskType.INTERNET_BROWSING,
    TaskType.HELP_ME,
    TaskType.MOTION_RESPONSE,
    TaskType.SUMMARIZE,
    TaskType.CREATE_OUTLINE,
    TaskType.DOCUMENT_INVENTORY,
    TaskType.COMPARATIVE_ANALYSIS,
    TaskType.COMPARATOR_FINDER,
    ...AssistantV1TaskTypes,
  ]

  if (isAssistantV2User && assistantTypes.includes(event.kind)) {
    if (event.libraryItemKind === LibraryItemKind.PROMPT) {
      return '/assistant'
    }
    return `/assistant/${AssistantMode.ASSIST}/${event.id}`
  } else if (assistantTypes.includes(event.kind)) {
    return `/assistant/${event.id}`
  } else {
    return null
  }
}

const getAssistantV2Route = (event: EventInfo): string | null => {
  if (event.kind === TaskType.ASSISTANT_CHAT) {
    if (event.libraryItemKind === LibraryItemKind.PROMPT) {
      return '/assistant'
    }
    return `/assistant/${AssistantMode.ASSIST}/${event.id}`
  } else if (event.kind === TaskType.ASSISTANT_DRAFT) {
    if (event.libraryItemKind === LibraryItemKind.PROMPT) {
      return '/assistant'
    }
    return `/assistant/${AssistantMode.DRAFT}/${event.id}`
  } else {
    return null
  }
}

const getVaultRoute = (event: EventInfo): string | null => {
  if (
    (event.kind === TaskType.VAULT || event.kind === TaskType.VAULT_REVIEW) &&
    event.vaultFolderId
  ) {
    return `${BaseAppPath.Vault}${projectsPath}${event.vaultFolderId}${queriesPath}${event.id}`
  }
  return null
}

const getPwcDealsRoute = (event: EventInfo): string | null => {
  if (event.kind === TaskType.PWC_DEALS) {
    return `/workflows/discovery/${event.id}`
  }
  return null
}

const getDiligenceTranscriptsRoute = (event: EventInfo): string | null => {
  if (event.kind === TaskType.DILIGENCE_TRANSCRIPTS) {
    return `/workflows/diligence-transcripts/${event.id}`
  }
  return null
}

/*
 * A pure function for calculating the route for an event.
 * Easier to test
 */
export const calculateRouteForEvent = (
  event: EventInfo,
  userInfo: UserInfo
): string => {
  // Workflow route?
  const workflowRoute = getWorkflowRoute(event, userInfo)
  if (workflowRoute) return workflowRoute

  // Research route?
  const researchRoute = getResearchRoute(event)
  if (researchRoute) return researchRoute

  // Vault Route?
  const vaultRoute = getVaultRoute(event)
  if (vaultRoute) return vaultRoute

  // Assistant route?
  const assistantRoute = getAssistantRoute(event, userInfo.IsAssistantV2User)
  if (assistantRoute) return assistantRoute

  const assistantV2Route = getAssistantV2Route(event)
  if (assistantV2Route) return assistantV2Route

  const pwcDealsRoute = getPwcDealsRoute(event)
  if (pwcDealsRoute) return pwcDealsRoute

  const diligenceTranscriptsRoute = getDiligenceTranscriptsRoute(event)
  if (diligenceTranscriptsRoute) return diligenceTranscriptsRoute

  // Throw error if no route
  throw new Error(
    `No route found for event: ${event.id} with kind: ${event.kind}`
  )
}

/*
  This function takes an event object and returns the route to navigate to.
  The route is determined by the event kind and the user's permissions.
*/
export const getRouteForEvent = (
  event: EventInfo,
  userInfo: UserInfo,
  navigateOptions?: NavigateOptions
): string => {
  try {
    const route = calculateRouteForEvent(event, userInfo)
    if (navigateOptions?.state) {
      const params = new URLSearchParams({
        [NAV_STATE_PARAM]: JSON.stringify(navigateOptions.state),
      })
      return `${route}?${params.toString()}`
    }
    return route
  } catch (error) {
    Sentry.captureException(error)
    console.warn(error)
    return ''
  }
}

type GetRouteForLibraryEventProps = {
  event: EventInfo
  userInfo: UserInfo
  libraryItemKind: LibraryItemKind
  navigateOptions?: NavigateOptions
}

/*
  This function takes a library event and returns the route to navigate to.
  Examples open as usual, prompts now always open in Assistant V2.
  [Future] Knowledge sources will be used as navigate options to set prompt menu state.
*/
export const getRouteForLibraryEvent = ({
  event,
  userInfo,
  libraryItemKind,
  navigateOptions,
}: GetRouteForLibraryEventProps): string => {
  try {
    // Examples route as usual
    // Research Knowledge Source Non-Users route as usual
    if (libraryItemKind === LibraryItemKind.EXAMPLE) {
      return getRouteForEvent(event, userInfo, navigateOptions)
    }
    // All prompts now open in Assistant V2
    // When event is any type other than ASSISTANT_CHAT or ASSISTANT_DRAFT,
    // getAssistantRoute will return null and we'll default to Assistant V2
    const assistantRoute =
      getAssistantRoute(event, userInfo.IsAssistantV2User) ||
      getAssistantV2Route(event) ||
      '/assistant'
    if (navigateOptions?.state) {
      const params = new URLSearchParams({
        [NAV_STATE_PARAM]: JSON.stringify(navigateOptions.state),
      })
      return `${assistantRoute}?${params.toString()}`
    }
    return assistantRoute
  } catch (error) {
    Sentry.captureException(error)
    console.warn(error)
    return ''
  }
}

/**
 * Extracts the path from a URL or a substring.
 */
const extractPath = (urlOrSubstring: string, leadingSlash = false): string => {
  let path

  try {
    const url = new URL(urlOrSubstring, 'http://dummy.com')
    path = url.pathname
  } catch (error) {
    path = urlOrSubstring.split('?')[0]
  }

  if (leadingSlash) {
    // Ensure the path starts with a leading slash
    return path.startsWith('/') ? path : `/${path}`
  } else {
    // Remove the leading slash if it exists
    return path.startsWith('/') ? path.substring(1) : path
  }
}

/**
 * Extracts the query string from a URL or a substring, and returns the query string without the leading ? character
 */
const extractQueryString = (urlOrSubstring: string): string => {
  try {
    const url = new URL(urlOrSubstring, 'http://dummy.com')
    return url.search ? url.search.substring(1) : ''
  } catch (error) {
    const parts = urlOrSubstring.split('?')
    return parts.length > 1 ? parts[1] : ''
  }
}

const searchParamsToObject = (
  params: URLSearchParams
): Record<string, string> => {
  const result: Record<string, string> = {}
  params.forEach((value, key) => {
    result[key] = value
  })
  return result
}

export const combineSearchParams = (
  params1: URLSearchParams,
  params2: URLSearchParams
): URLSearchParams => {
  const obj1 = searchParamsToObject(params1)
  const obj2 = searchParamsToObject(params2)
  const combined = { ...obj1, ...obj2 }
  const combinedParams = new URLSearchParams(combined)
  return combinedParams
}

export const addQueryParamsToRequestPath = (
  requestPath: string,
  additionalParams: URLSearchParams
): string => {
  const path = extractPath(requestPath)
  const existingQueryString = extractQueryString(requestPath)
  const existingQueryParams = new URLSearchParams(existingQueryString)
  const combinedQueryParams = combineSearchParams(
    existingQueryParams,
    additionalParams
  )
  return `${path}?${combinedQueryParams.toString()}`
}

export const removeTrailingSlash = (path: string) => {
  const pathParts = path.split('/')
  while (pathParts[pathParts.length - 1] === '') {
    // remove trailing slashes
    pathParts.pop()
  }
  return pathParts.join('/')
}

export interface RouteComponentProps {
  path: string
  hasPerms?: boolean
  component: React.FC<any>
  componentProps?: Record<string, any>
  children?: React.ReactNode[]
  sentryAssignee?: SentryAssignees
}
export const createRouteComponent = ({
  path,
  hasPerms = false,
  component,
  componentProps,
  children = [],
  sentryAssignee,
}: RouteComponentProps) => {
  return (
    <Route
      key={path}
      path={path}
      element={
        <AuthenticationGuard
          hasPerms={hasPerms}
          component={component}
          componentProps={componentProps}
          sentryAssignee={sentryAssignee}
        />
      }
    >
      {children.map((c) => c)}
    </Route>
  )
}
