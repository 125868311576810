import React from 'react'

import { SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis } from 'openapi/models/SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

import CountryCard, { computeStatus } from './country-card'
import CountryDetailCard from './country-detail-card'

interface SummaryProps {
  className?: string
  data: {
    [
      key: string
    ]: SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis
  }
}

const getCountriesWithAllThresholdsMet = (data: SummaryProps['data']) => {
  return Object.keys(data).filter((country) => {
    return computeStatus(data[country]).includes('likely met')
  })
}

const getCountriesWithAllThresholdsNotMet = (data: SummaryProps['data']) => {
  return Object.keys(data).filter((country) => {
    return computeStatus(data[country]) === 'Thresholds likely not met'
  })
}

const getCountriesWithMoreInfoRequired = (data: SummaryProps['data']) => {
  return Object.keys(data).filter((country) => {
    return computeStatus(data[country]) === 'More information required'
  })
}

const Summary: React.FC<SummaryProps> = ({ className, data }) => {
  const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
    null
  )

  return (
    <div>
      <div className="mt-2 space-y-1">
        {getCountriesWithAllThresholdsMet(data).length > 0 && (
          <div>
            <p className="font-medium">Thresholds likely met</p>
            <p>
              {getCountriesWithAllThresholdsMet(data)
                .map((country) => data[country].countryName)
                .join(', ')}
            </p>
          </div>
        )}

        {getCountriesWithAllThresholdsNotMet(data).length > 0 && (
          <div>
            <p className="font-medium">Thresholds likely not met</p>
            <p>
              {getCountriesWithAllThresholdsNotMet(data)
                .map((country) => data[country].countryName)
                .join(', ')}
            </p>
          </div>
        )}

        {getCountriesWithMoreInfoRequired(data).length > 0 && (
          <div>
            <p className="font-medium">More information required</p>
            <p>
              {getCountriesWithMoreInfoRequired(data)
                .map((country) => data[country].countryName)
                .join(', ')}
            </p>
          </div>
        )}
      </div>
      <div className={cn('mt-4 w-full rounded border', className)}>
        <div className="border-b px-4 py-3">
          <div className="flex items-center justify-between">
            <p>Country Breakdown</p>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setSelectedCountry(null)}
            >
              View All
            </Button>
          </div>
        </div>
        {!selectedCountry && (
          <div className="grid grid-cols-2 gap-4 px-4 py-3">
            {Object.keys(data)
              .filter(
                (country) =>
                  !['Turkey', 'Ukraine'].includes(data[country].countryName)
              )
              .map((country) => (
                <CountryCard
                  key={country}
                  countryName={country}
                  analysis={data[country]}
                  setSelectedCountry={setSelectedCountry}
                />
              ))}
          </div>
        )}

        {selectedCountry && (
          <div className="w-full px-4 py-3">
            <CountryDetailCard
              countryName={selectedCountry}
              analysis={data[selectedCountry]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Summary
