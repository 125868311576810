import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import produce from 'immer'
import _ from 'lodash'
import {
  ChevronDown,
  ChevronRight,
  Pencil,
  PlusIcon,
  Trash,
} from 'lucide-react'
import pluralize from 'pluralize'

import { PermBundle } from 'models/perms'
import {
  bulkUpdateRolePerms,
  createWorkspaceRole,
  EditDiffs,
  getWorkspaceRoleConfigs,
  getWorkspaceRoles,
  RoleConfig,
  updateWorkspaceRole,
  WorkspaceRole,
  WorkspaceRoleConfig,
} from 'models/roles'
import { Workspace } from 'models/workspace'
import { instanceOfDefaultRole } from 'openapi/models/DefaultRole'
import { DefaultVisibility } from 'openapi/models/DefaultVisibility'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'
import { PermissionCategory } from 'openapi/models/PermissionCategory'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import CreateRoleModal from 'components/settings/roles/create-role-modal'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Dialog } from 'components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import SearchInput from 'components/ui/search-input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

import WorkspaceDeleteRoleDialog from './workspace-delete-role-dialog'
import WorkspaceUpdateRoleDialog from './workspace-update-role-dialog'

/** Categories will be displayed in this order */
const PERMISSION_CATEGORIES = [
  PermissionCategory.ASSISTANT,
  PermissionCategory.VAULT,
  PermissionCategory.LIBRARY,
  PermissionCategory.CLIENT_MATTERS,
  PermissionCategory.ADD_INS,
  PermissionCategory.INTEGRATIONS,
  PermissionCategory.TAX_KNOWLEDGE_SOURCES,
  PermissionCategory.CASE_LAW_KNOWLEDGE_SOURCES,
  PermissionCategory.WORKFLOWS,
  PermissionCategory.HISTORY,
  PermissionCategory.BASE_USER_DEFAULTS,
  PermissionCategory.USER_MANAGEMENT,
  PermissionCategory.ADMIN_ONLY,
  PermissionCategory.HARVEY_EMPLOYEES_ONLY,
  PermissionCategory.ASSIGNED_BY_USER_OPS_ONLY,
]

const VISIBILITY_TO_LABEL: Readonly<Record<DefaultVisibility, string>> & {
  [K in DefaultVisibility]: string
} = {
  [DefaultVisibility.MANAGE]: 'Manage',
  [DefaultVisibility.VIEW]: 'View',
  [DefaultVisibility.HIDE]: 'Hide',
  [DefaultVisibility.INTERNAL_ONLY]: 'Internal only',
} as const

interface Props {
  rows: RowProps[]
  workspace: Workspace
  fetchData: () => void
}

type CategoryRowProps = {
  type: 'category'
  name: PermissionCategory
  children: PermBundleRowProps[]
}

type PermBundleRowProps = {
  type: 'permBundle'
  permBundle: PermBundle
  visibility: DefaultVisibility
  roleConfigs: Partial<Record<string, RoleConfig>>
  children?: PermRowProps[]
}

type PermRowProps = {
  type: 'perm'
  permId: string
  enabled: boolean
  isLast?: boolean
  children?: never
}

type RowProps = CategoryRowProps | PermBundleRowProps | PermRowProps

const getInitialEditDiffs = (): EditDiffs => ({
  diffsByRolePk: {},
  enabledPermIds: new Set<string>(),
  disabledPermIds: new Set<string>(),
})

const getNumDiffs = (editDiffs: EditDiffs) => {
  const numRoleDiffs = Object.values(editDiffs.diffsByRolePk).reduce(
    (acc, roleDiffs) => {
      if (!roleDiffs) return acc
      return (
        acc +
        roleDiffs.enabledPermBundleIds.size +
        roleDiffs.disabledPermBundleIds.size
      )
    },
    0
  )
  return (
    numRoleDiffs +
    editDiffs.enabledPermIds.size +
    editDiffs.disabledPermIds.size
  )
}

const Expander = <TData,>({ row }: { row: Row<TData> }) => (
  <div className="pl-2">
    <Button
      variant="ghost"
      size="smIcon"
      onClick={(e) => {
        e.stopPropagation()
        row.toggleExpanded()
      }}
      className="size-4 rounded"
    >
      <ChevronRight
        className={cn('size-4 transition-transform', {
          'rotate-90': row.getIsExpanded(),
        })}
      />
    </Button>
  </div>
)

const RoleMenu = ({
  role,
  setUpdatingRole,
  setConfirmingDeleteRole,
}: {
  role: WorkspaceRole
  setUpdatingRole: (role: WorkspaceRole) => void
  setConfirmingDeleteRole: (role: WorkspaceRole) => void
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost" size="sm" className="h-6 p-1">
        <ChevronDown className="size-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="w-48">
      <DropdownMenuItem
        className="flex items-center justify-between gap-2"
        onClick={() => setUpdatingRole(role)}
      >
        Edit role title
        <Icon icon={Pencil} className="size-3" />
      </DropdownMenuItem>
      <DropdownMenuItem
        className="flex items-center justify-between gap-2 text-destructive transition-colors hover:text-destructive focus:text-destructive"
        onClick={() => setConfirmingDeleteRole(role)}
      >
        Delete role
        <Icon icon={Trash} className="size-3" />
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
)

const WorkspaceRolesTableV2: React.FC<Props> = ({ workspace }) => {
  const userInfo = useAuthUser()
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<SortingState>([])
  const [expanded, setExpanded] = useState<ExpandedState>(
    PERMISSION_CATEGORIES.reduce(
      (acc, category) => ({
        ...acc,
        [category]: true, // Set all categories to be expanded by default
      }),
      {}
    )
  )
  const [workspaceRoleConfigs, setWorkspaceRoleConfigs] = useState<
    WorkspaceRoleConfig[]
  >([])
  const [roles, setRoles] = useState<WorkspaceRole[]>([])
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] =
    useState<boolean>(false)
  const [updatingRole, setUpdatingRole] = useState<WorkspaceRole>()
  const [confirmingDeleteRole, setConfirmingDeleteRole] =
    useState<WorkspaceRole>()
  const [isLoading, setIsLoading] = useState(true)

  const [isEditing, setIsEditing] = useState(false)
  const [editDiffs, setEditDiffs] = useState<EditDiffs>({
    diffsByRolePk: {},
    enabledPermIds: new Set<string>(),
    disabledPermIds: new Set<string>(),
  })

  const fetchRoles = useCallback(async () => {
    try {
      setIsLoading(true)
      const roleConfigs = await getWorkspaceRoleConfigs(workspace.id)
      setWorkspaceRoleConfigs(roleConfigs)
      const roles = await getWorkspaceRoles(workspace.id)
      setRoles(roles.filter((role) => !role.deletedAt)) // TODO (ken): Move filter to API call
    } catch (error) {
      displayErrorMessage('Failed to fetch workspace roles')
    } finally {
      setIsLoading(false)
    }
  }, [workspace.id])

  useEffect(() => {
    void fetchRoles()
  }, [fetchRoles])

  const getActivePermIdsForBundle = (permBundle: PermBundle) => {
    // TODO (ken): Perm enabled/disabled state is not stored in the DB yet, for now consider all perms enabled.
    // Eventually it should only return the subset of enabled perms for the given workspace.
    return permBundle.permissions
  }

  const handleCreateRole = async (name: string, description: string) => {
    await createWorkspaceRole(workspace.id, {
      name,
      desc: description,
    })
    setIsCreateRoleModalOpen(false)
    void fetchRoles()
  }

  const handleUpdateRole = async ({
    rolePk,
    name,
    description,
  }: {
    rolePk: string
    name?: string
    description?: string
  }) => {
    try {
      const updatedRole = await updateWorkspaceRole(rolePk, {
        name: name ?? '',
        desc: description ?? '',
      })
      setRoles((roles) =>
        roles.map((role) => (role.rolePk === rolePk ? updatedRole : role))
      )
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to update role')
      }
    }
  }

  const handleDeleteRole = async () => {
    setConfirmingDeleteRole(undefined)
    void fetchRoles()
  }

  const handleSaveEdits = async () => {
    try {
      await bulkUpdateRolePerms(workspace.id, editDiffs)
      setIsEditing(false)
      await fetchRoles()
      setEditDiffs(getInitialEditDiffs())
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to save changes')
      }
    }
  }

  const columns = useMemo<ColumnDef<RowProps>[]>(
    () => [
      {
        accessorKey: 'permBundle',
        header: ({ column }) => (
          <div className="ml-6">
            <DataTableSortHeader column={column} header="Permission" />
          </div>
        ),
        cell: ({ row }) => {
          if (row.original.type === 'category') {
            return (
              <div className="-m-3 bg-secondary p-3 text-sm">
                {row.original.name}
                {row.subRows.length ? (
                  <span className="ml-2 text-sm text-muted">
                    {`(${row.subRows.length} ${pluralize(
                      'permission',
                      row.subRows.length
                    )})`}
                  </span>
                ) : null}
              </div>
            )
          }
          if (row.original.type === 'perm') {
            const { permId, enabled } = row.original
            let checked = enabled || editDiffs.enabledPermIds.has(permId)
            if (editDiffs.disabledPermIds.has(permId)) {
              checked = false
            }
            return (
              <div className="ml-3 flex items-center gap-4">
                <Checkbox
                  disabled={!isEditing}
                  checked={checked}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setEditDiffs((editDiffs) =>
                        produce(editDiffs, (draft) => {
                          draft.enabledPermIds.add(permId)
                          draft.disabledPermIds.delete(permId)
                        })
                      )
                    } else {
                      setEditDiffs((editDiffs) =>
                        produce(editDiffs, (draft) => {
                          draft.disabledPermIds.add(permId)
                          draft.enabledPermIds.delete(permId)
                        })
                      )
                    }
                  }}
                />
                <div className="text-sm">{row.original.permId}</div>
              </div>
            )
          }
          const permBundle = row.original.permBundle
          return (
            <div className="-ml-4 flex items-center gap-2 bg-primary">
              <Expander row={row} />
              <div className="w-[360px] text-sm">
                {permBundle.name}
                <div className="text-sm text-muted">
                  {permBundle.description}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        accessorKey: 'visibility',
        header: 'Visibility',
        cell: ({ row }) => {
          if (row.original.type !== 'permBundle') return null
          return (
            <Select
              disabled
              value={row.original.visibility}
              onValueChange={() => {}} // TODO: To implement in a future PR
            >
              <SelectTrigger className="h-8 w-28">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {Object.values(DefaultVisibility).map((visibility) => (
                  <SelectItem key={visibility} value={visibility}>
                    {VISIBILITY_TO_LABEL[visibility]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )
        },
      },
      ...roles.map<ColumnDef<RowProps>>((role) => ({
        accessorFn: (row) => {
          if (row.type === 'category' || row.type === 'perm') {
            return null
          }
          return row.roleConfigs[role.rolePk]
        },
        id: role.rolePk,
        header: () => {
          const baseSlug = role.roleId.slice(workspace.slug.length + 1)
          return (
            <div className="flex items-center gap-1 text-sm font-semibold">
              {role.name}
              {!instanceOfDefaultRole(baseSlug) && (
                <RoleMenu
                  role={role}
                  setUpdatingRole={setUpdatingRole}
                  setConfirmingDeleteRole={setConfirmingDeleteRole}
                />
              )}
            </div>
          )
        },
        cell: ({ row }) => {
          if (row.original.type !== 'permBundle') return null
          const permBundle = row.original.permBundle
          const roleConfig = row.original.roleConfigs[role.rolePk]

          let savedCheckedState: ComponentProps<typeof Checkbox>['checked'] =
            false
          if (roleConfig) {
            if (roleConfig.missingPerms.length === 0) {
              savedCheckedState = true
            } else if (
              roleConfig.missingPerms.length < permBundle.enabledPerms.length
            ) {
              savedCheckedState = 'indeterminate'
            }
          }

          // Apply diffs from saved state
          let checkedState = savedCheckedState
          if (
            editDiffs.diffsByRolePk[role.rolePk]?.enabledPermBundleIds.has(
              permBundle.id
            )
          ) {
            checkedState = true
          } else if (
            editDiffs.diffsByRolePk[role.rolePk]?.disabledPermBundleIds.has(
              permBundle.id
            )
          ) {
            checkedState = false
          }

          return (
            <Button
              variant="ghost"
              size="smIcon"
              tooltip={
                checkedState === 'indeterminate' &&
                roleConfig &&
                roleConfig.missingPerms.length > 0
                  ? `Missing: ${roleConfig.missingPerms.join(', ')}`
                  : undefined
              }
            >
              <Checkbox
                checked={checkedState}
                isIndeterminate={checkedState === 'indeterminate'}
                disabled={!isEditing}
                onCheckedChange={(checked) => {
                  if (checked === 'indeterminate') checked = true
                  setEditDiffs((editDiffs) =>
                    produce(editDiffs, (draft) => {
                      let roleDiffs = draft.diffsByRolePk[role.rolePk]
                      if (!roleDiffs) {
                        roleDiffs = {
                          enabledPermIds: new Set<string>(),
                          disabledPermIds: new Set<string>(),
                          enabledPermBundleIds: new Set<PermissionBundleId>(),
                          disabledPermBundleIds: new Set<PermissionBundleId>(),
                        }
                        draft.diffsByRolePk[role.rolePk] = roleDiffs
                      }
                      // TODO (ken): Can clean this up if we don't need to splay perms here
                      const permIds = getActivePermIdsForBundle(permBundle)
                      if (checked) {
                        roleDiffs.disabledPermBundleIds.delete(permBundle.id)
                        permIds.forEach((permId) => {
                          roleDiffs.disabledPermIds.delete(permId)
                        })
                        if (checked !== savedCheckedState) {
                          roleDiffs.enabledPermBundleIds.add(permBundle.id)
                          permIds.forEach((permId) => {
                            roleDiffs.enabledPermIds.add(permId)
                          })
                        }
                      } else {
                        roleDiffs.enabledPermBundleIds.delete(permBundle.id)
                        permIds.forEach((permId) => {
                          roleDiffs.enabledPermIds.delete(permId)
                        })
                        if (checked !== savedCheckedState) {
                          roleDiffs.disabledPermBundleIds.add(permBundle.id)
                          permIds.forEach((permId) => {
                            roleDiffs.disabledPermIds.add(permId)
                          })
                        }
                      }
                    })
                  )
                }}
              />
            </Button>
          )
        },
        enableSorting: false,
      })),
    ],
    [roles, workspace.slug, isEditing, editDiffs]
  )

  const categoryRows: CategoryRowProps[] = useMemo(
    // Memo is needed to prevent infinite re-renders on expand/collapse
    () =>
      PERMISSION_CATEGORIES.map<CategoryRowProps>((category) => {
        return {
          type: 'category',
          name: category,
          children: workspaceRoleConfigs
            .filter((roleConfig) => roleConfig.permBundle.category === category)
            .map<PermBundleRowProps>((roleConfig) => ({
              type: 'permBundle',
              permBundle: roleConfig.permBundle,
              visibility:
                roleConfig.visibility ||
                roleConfig.permBundle.defaultVisibility,
              roleConfigs: roleConfig.roleConfigs.reduce(
                (acc, config) => ({
                  ...acc,
                  [config.roleId]: config,
                }),
                {}
              ),
              children: roleConfig.permBundle.permissions.map<PermRowProps>(
                (perm, index, array) => ({
                  type: 'perm',
                  permId: perm,
                  enabled: roleConfig.permBundle.enabledPerms.includes(perm),
                  isLast: index === array.length - 1,
                })
              ),
            })),
        }
      }).filter((category) => category.children.length > 0), // Hide empty categories for now
    [workspaceRoleConfigs]
  )

  const table = useReactTable({
    data: categoryRows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getRowId: (row) => {
      switch (row.type) {
        case 'category':
          return row.name
        case 'perm':
          return row.permId
        default:
          return row.permBundle.id
      }
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.children,
    state: {
      sorting,
      globalFilter: filter,
      expanded,
    },
  })

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return null

  return (
    <div className="flex flex-col px-4">
      <div className="flex items-center justify-between gap-2 py-2">
        <p>Manage roles and permissions in this workspace.</p>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            onClick={() => setIsCreateRoleModalOpen(true)}
          >
            <Icon icon={PlusIcon} className="mr-1" />
            Create role
          </Button>
          <Button
            variant="outline"
            disabled={isEditing}
            onClick={() => {
              setIsEditing(!isEditing)
            }}
          >
            <Icon icon={Pencil} className="mr-1" />
            Edit table
          </Button>
        </div>
      </div>

      <div className="mb-4 flex items-center justify-between gap-2">
        <SearchInput value={filter} setValue={setFilter} withIcon />
      </div>

      <DataTable
        className={cn(isEditing && 'rounded-b-none')}
        table={table}
        marginTop={75}
        isLoading={isLoading}
        stickyFirstColumn
        tableCellClassName="p-3"
        tableRowClassName={(row) =>
          cn('hover:bg-transparent', {
            'bg-secondary': row.original.type === 'category',
            'hover:bg-transparent border-t-0': row.original.type === 'perm',
            'border-b-0': row.original.type === 'perm' && !row.original.isLast,
          })
        }
      />

      {isEditing && (
        <div className="sticky inset-x-0 bottom-0 -mt-px flex items-center justify-between rounded-b-lg border bg-primary p-4">
          <div className="text-sm text-muted">
            {getNumDiffs(editDiffs)} unsaved changes
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                setIsEditing(false)
                setEditDiffs(getInitialEditDiffs())
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveEdits}
              disabled={getNumDiffs(editDiffs) === 0}
            >
              Save changes
            </Button>
          </div>
        </div>
      )}

      <CreateRoleModal
        open={isCreateRoleModalOpen}
        onOpenChange={setIsCreateRoleModalOpen}
        onAdd={handleCreateRole}
      />
      {updatingRole && (
        <WorkspaceUpdateRoleDialog
          isOpen
          onClose={() => setUpdatingRole(undefined)}
          onSubmit={async ({ name, description }) => {
            await handleUpdateRole({
              rolePk: updatingRole.rolePk,
              name,
              description,
            })
          }}
          initialName={updatingRole.name}
          initialDescription={updatingRole.desc}
        />
      )}
      {confirmingDeleteRole && (
        <Dialog open onOpenChange={() => setConfirmingDeleteRole(undefined)}>
          <WorkspaceDeleteRoleDialog
            isOpen
            onClose={() => setConfirmingDeleteRole(undefined)}
            onDelete={handleDeleteRole}
            role={confirmingDeleteRole}
            availableRoles={roles}
            workspaceSlug={workspace.slug}
          />
        </Dialog>
      )}
    </div>
  )
}

export default WorkspaceRolesTableV2
