import React, { useCallback } from 'react'

import { Maybe } from 'ag-grid-community/dist/types/core/columns/columnModel'

import { useFileCache } from 'stores/file-cache'

import { getSourceClickedFromSource } from 'utils/source'
import { Source } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'

import { useSetViewingFile } from 'components/assistant/workflows/components/workflow-file-popover'
import CitationPopover from 'components/common/citation/citation-popover'

interface SourceUtils {
  sources: Source[]
}

export const useSourceUtils = ({ sources }: SourceUtils) => {
  const setViewingFile = useSetViewingFile()
  const [getFile] = useFileCache((state) => [state.getFile])

  const handleSourceClick = useCallback(
    (fileId: Maybe<string>, sourceId?: string) => {
      if (!fileId) return
      getFile(fileId)
        .then((file) => {
          if (file) {
            setViewingFile(file, sourceId, (sources || []) as Source[])
          }
        })
        .catch(() => {
          displayErrorMessage('Failed to load file')
        })
    },
    [getFile, setViewingFile, sources]
  )

  const getHrvyInfoMetadata = useCallback(
    (sourceId: string) => {
      const source = getSourceClickedFromSource(
        sourceId,
        (sources || []) as Source[]
      )

      if (!source || !source.documentId) return

      const hoverContent = (
        <CitationPopover
          source={source}
          onClick={() => handleSourceClick(source.documentId!, source.id)}
        />
      )

      return {
        hoverContent,
        onClick: () => handleSourceClick(source.documentId!, source.id),
      }
    },
    [sources, handleSourceClick]
  )

  return {
    handleSourceClick,
    getHrvyInfoMetadata,
  }
}
