import React, { useRef, useMemo } from 'react'

import { CustomCellRendererProps } from 'ag-grid-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  addNewColumn,
  ADD_COLUMN_WIDTH_WITH_ICON,
  handleMouseLeave,
  handleMouseEnter,
  handleLeftBorderReset,
  HAS_REACHED_QUESTIONS_LIMIT_TOOLTIP,
  NO_EDIT_PERMISSIONS_TOOLTIP,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

export const AddColumnCell = (props: CustomCellRendererProps) => {
  const { trackEvent } = useAnalytics()
  const ref = useRef<HTMLButtonElement>(null)
  const [
    gridApi,
    queryId,
    historyItem,
    hasReachedQuestionsLimit,
    currentPendingColumnId,
    pendingQueryQuestions,
    isQueryLoading,
    setIsHoveringAddColumn,
    addToPendingQueryQuestions,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.gridApi,
      state.queryId,
      state.historyItem,
      state.hasReachedQuestionsLimit,
      state.currentPendingColumnId,
      state.pendingQueryQuestions,
      state.isQueryLoading,
      state.setIsHoveringAddColumn,
      state.addToPendingQueryQuestions,
    ])
  )

  const [currentProject, exampleProjectIds] = useVaultStore(
    useShallow((state) => [state.currentProject, state.exampleProjectIds])
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const { hasEmptyCells } = useRunReview()
  const canResumeQuery = !isQueryLoading && hasEmptyCells

  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const { colDef } = props
  const colWidth = colDef?.width
  const isPinned = colDef?.pinned === 'right'

  const isIconOnly = colWidth === ADD_COLUMN_WIDTH_WITH_ICON
  const hasCurrentPendingColumnId = !!currentPendingColumnId

  const isAddColumnDisabled =
    !gridApi ||
    !canCurrentUserEditProject ||
    hasReachedQuestionsLimit ||
    hasCurrentPendingColumnId ||
    canResumeQuery

  const tooltip = useMemo(() => {
    if (hasReachedQuestionsLimit) {
      return HAS_REACHED_QUESTIONS_LIMIT_TOOLTIP
    }
    if (!canCurrentUserEditProject) {
      return NO_EDIT_PERMISSIONS_TOOLTIP
    }
    if (canResumeQuery) {
      return RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE
    }
    return ''
  }, [canCurrentUserEditProject, hasReachedQuestionsLimit, canResumeQuery])

  const onAddColumn = () => {
    if (isAddColumnDisabled) return
    trackEvent('Vault Review Query Add Column Button Clicked', {
      query_id: queryId,
    })
    const reviewEvent = historyItem as ReviewHistoryItem
    const pendingQuestions = pendingQueryQuestions ?? []
    const allColumns = [...pendingQuestions, ...(reviewEvent?.columns ?? [])]
    handleLeftBorderReset()
    addNewColumn(gridApi, allColumns, addToPendingQueryQuestions)
  }

  const onMouseEnter = () => {
    if (isAddColumnDisabled) return
    setIsHoveringAddColumn(true)
    handleMouseEnter(gridApi, isIconOnly)
  }

  const onMouseLeave = (e: React.MouseEvent) => {
    if (isAddColumnDisabled) return
    setIsHoveringAddColumn(false)
    handleMouseLeave(e, gridApi, isPinned)
  }

  return (
    <Button
      ref={ref}
      variant="unstyled"
      onClick={onAddColumn}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tooltip={tooltip}
      tooltipPopoverClassName="max-w-64"
      tooltipSide="left"
      className={cn(
        'h-full w-full cursor-pointer rounded-none bg-button-secondary',
        {
          'border-l-0': !isPinned,
          'border-l border-l-primary': isPinned,
        }
      )}
    />
  )
}

export default AddColumnCell
