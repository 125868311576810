import React from 'react'
import { DateRange } from 'react-day-picker'

import {
  CloudUpload,
  Coins,
  Euro,
  Landmark,
  Mountain,
  NotebookText,
  ScaleIcon,
} from 'lucide-react'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { UserInfo } from 'models/user-info'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { TaxCorpusFilterIDs } from 'openapi/models/TaxCorpusFilterIDs'
import { Maybe } from 'types'

import { SafeRecord } from 'utils/safe-types'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'

// TODO: OpenAPI these
export enum FileSource {
  FILES = 'files',
  VAULT = 'vault',
}

export enum DatabaseSource {
  CUATRECASAS = 'cuatrecasas',
  EDGAR = 'edgar',
  EURLEX = 'eurlex',
  FRENCH_CASELAW = 'french_caselaw',
  MEMOS = 'memo',
  TAX = 'tax',
  US_CASELAW = 'us_caselaw',
  SWEDEN_CASELAW = 'sweden_caselaw',
  SINGAPORE_CASELAW = 'singapore_caselaw',
  MACFARLANES = 'macfarlanes',
  AUSBREACHREPORTING = 'aus_breach',
}

export type KnowledgeSource = FileSource | DatabaseSource

export type VaultKnowledgeSource = {
  type: FileSource.VAULT
  folderId?: string
  fileIds?: string[]
  query?: string
}

export type ResearchKnowledgeSource = {
  type: DatabaseSource
  filterIds: string[]
  dateRange?: DateRange
}

// TODO: OpenAPI these...
export type KnowledgeSourceItem = ResearchKnowledgeSource | VaultKnowledgeSource

export const useKnowledgeSources = (
  userInfo: UserInfo
): Set<KnowledgeSource> => {
  const { isLoading, taxonomy } = useResearchTaxonomyQuery(
    null,
    userInfo.IsHarveyV1ResearchUser /* isEnabled */,
    false /* refetchOnWindowFocus */
  )
  const taxIds = Object.values(TaxCorpusFilterIDs)

  const hasFeature = (featureId: string) => {
    return (
      !isLoading &&
      Array.isArray(taxonomy) &&
      taxonomy.some(({ id }) => id.includes(featureId))
    )
  }

  // Copied from ResearchIndexPage, should be cleaned up
  const hasMemos = hasFeature('memos')
  const hasUsaCaselaw = hasFeature('usa_caselaw')
  const hasFranceCaselaw = hasFeature('france_caselaw')
  const hasTax = taxIds.some((id) => hasFeature(id))
  const hasEURLex = hasFeature('eurlex')
  const hasEdgar = hasFeature('sec_edgar')
  const hasCuatrecasas = hasFeature(ResearchArea.CUATRECASAS)
  const hasSwedenCaselaw = hasFeature('sweden_caselaw')
  const hasSingaporeCaselaw = hasFeature('singapore_caselaw')
  const hasMacfarlanes = hasFeature('macfarlanes')
  const hasAusBreachReporting = hasFeature('aus_breach')
  const sources: Set<KnowledgeSource> = new Set()
  if (userInfo.isDocumentUser) {
    sources.add(FileSource.FILES)
  }
  if (userInfo.IsVaultKnowledgeSourceUser) {
    sources.add(FileSource.VAULT)
  }
  // Alphabetize set insertion order
  if (hasCuatrecasas) {
    sources.add(DatabaseSource.CUATRECASAS)
  }
  if (hasEdgar) {
    sources.add(DatabaseSource.EDGAR)
  }
  if (hasEURLex) {
    sources.add(DatabaseSource.EURLEX)
  }
  if (hasFranceCaselaw) {
    sources.add(DatabaseSource.FRENCH_CASELAW)
  }
  if (hasMemos) {
    sources.add(DatabaseSource.MEMOS)
  }
  if (hasTax) {
    sources.add(DatabaseSource.TAX)
  }
  if (hasSwedenCaselaw && userInfo.IsSwedenCaseLawUser) {
    sources.add(DatabaseSource.SWEDEN_CASELAW)
  }
  if (hasSingaporeCaselaw && userInfo.IsSingaporeCaseLawUser) {
    sources.add(DatabaseSource.SINGAPORE_CASELAW)
  }
  if (hasAusBreachReporting && userInfo.IsAusBreachReportingUser) {
    sources.add(DatabaseSource.AUSBREACHREPORTING)
  }
  if (hasUsaCaselaw) {
    sources.add(DatabaseSource.US_CASELAW)
  }
  if (hasMacfarlanes && userInfo.IsMacfarlanesUser) {
    sources.add(DatabaseSource.MACFARLANES)
  }
  return sources
}

export const KnowledgeSourceConfig: Record<
  KnowledgeSource,
  {
    icon: React.ReactElement
    name: string // The string shown in source footer, e.g. 'Vault • 2 sources'
    label: string // The string shown in the dropzone to choose the source
    hasDateRange?: boolean
    hideHeader?: boolean
    modes: AssistantMode[]
    dataRefreshMessage?: string
    tipsText?: string
  }
> = {
  [FileSource.FILES]: {
    icon: <CloudUpload />,
    name: 'Files',
    label: 'Drag and drop files',
    hideHeader: true,
    modes: [AssistantMode.ASSIST, AssistantMode.DRAFT],
  },
  [FileSource.VAULT]: {
    icon: <FolderShieldIcon />,
    name: 'Vault',
    label: 'Choose from Vault',
    hideHeader: true,
    modes: [AssistantMode.ASSIST, AssistantMode.DRAFT],
  },
  // TODO(reggie): consider adding DMS integrations here
  // but we need to figure out how to pass in onDrop function to button / dialog
  [DatabaseSource.MEMOS]: {
    icon: <NotebookText />,
    name: 'Memos',
    label: 'Memos',
    dataRefreshMessage: 'Memos data is refreshed weekly',
    tipsText: 'Comprehensive databases of law firm memos, refreshed weekly.',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.TAX]: {
    icon: <Coins />,
    name: 'Tax AI Assistant',
    label: 'Tax AI Assistant',
    dataRefreshMessage: 'Tax data is refreshed on a region-specific cadence',
    tipsText: 'Region-specific Tax legislation, case law, and guidance.',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.EURLEX]: {
    icon: <Euro />,
    name: 'EUR-Lex',
    label: 'EUR-Lex',
    dataRefreshMessage: 'EUR-Lex data is refreshed weekly',
    tipsText:
      'Cases from the Court of Justice of the European Union, refreshed weekly.',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.FRENCH_CASELAW]: {
    icon: <ScaleIcon />,
    name: 'French Case Law',
    label: 'French Case Law',
    dataRefreshMessage: 'French Case Law has data up to September 2024',
    tipsText:
      'Case law from French civil, administrative, and constitutional courts.',
    hasDateRange: true,
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.US_CASELAW]: {
    icon: <ScaleIcon />,
    name: 'US Case Law',
    label: 'US Case Law',
    dataRefreshMessage: 'Last refreshed May 2024',
    tipsText:
      'Federal and state case law from all 50 states, last updated May 2024.',
    hasDateRange: true,
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.EDGAR]: {
    icon: <Landmark />,
    name: 'EDGAR',
    label: 'EDGAR',
    dataRefreshMessage: 'EDGAR data is refreshed live',
    tipsText:
      'Key recurring and event-based filings by public companies. Harvey will answer questions or generate new content based on annual reports (Form 10-K), quarterly reports (Form 10-Q), annual proxy statements (Form DEF 14A), and current reports (Form 8-K) by searching EDGAR. The output includes linked references to highlighted sections of source documents so that you can check Harvey’s work.',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.CUATRECASAS]: {
    icon: <Mountain />,
    name: 'Cuatrecasas',
    label: 'Cuatrecasas',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.SWEDEN_CASELAW]: {
    icon: <ScaleIcon />,
    name: 'Sweden Case Law',
    label: 'Sweden Case Law',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.SINGAPORE_CASELAW]: {
    icon: <ScaleIcon />,
    name: 'Singapore Case Law',
    label: 'Singapore Case Law',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.AUSBREACHREPORTING]: {
    icon: <ScaleIcon />,
    name: 'Aus Breach Reporting',
    label: 'Aus Breach Reporting',
    modes: [AssistantMode.ASSIST],
  },
  [DatabaseSource.MACFARLANES]: {
    icon: <Mountain />,
    name: 'Macfarlanes',
    label: 'Macfarlanes',
    modes: [AssistantMode.ASSIST],
  },
}

export const DATABASE_SOURCE_TO_RESEARCH_AREA: SafeRecord<
  DatabaseSource,
  ResearchArea
> = {
  [DatabaseSource.CUATRECASAS]: ResearchArea.CUATRECASAS,
  [DatabaseSource.EDGAR]: ResearchArea.EDGAR,
  [DatabaseSource.EURLEX]: ResearchArea.EURLEX,
  [DatabaseSource.FRENCH_CASELAW]: ResearchArea.FRANCECASELAW,
  [DatabaseSource.MEMOS]: ResearchArea.MEMOS,
  [DatabaseSource.TAX]: ResearchArea.TAX,
  [DatabaseSource.US_CASELAW]: ResearchArea.USCASELAW,
  [DatabaseSource.SWEDEN_CASELAW]: ResearchArea.SWEDENCASELAW,
  [DatabaseSource.SINGAPORE_CASELAW]: ResearchArea.SINGAPORECASELAW,
  [DatabaseSource.MACFARLANES]: ResearchArea.MACFARLANES,
  [DatabaseSource.AUSBREACHREPORTING]: ResearchArea.AUSBREACHREPORTING,
}

export const RESEARCH_AREA_TO_DATABASE_SOURCE: SafeRecord<
  ResearchArea,
  DatabaseSource | null
> = {
  [ResearchArea.CUATRECASAS]: DatabaseSource.CUATRECASAS,
  [ResearchArea.EDGAR]: DatabaseSource.EDGAR,
  [ResearchArea.EURLEX]: DatabaseSource.EURLEX,
  [ResearchArea.FRANCECASELAW]: DatabaseSource.FRENCH_CASELAW,
  [ResearchArea.SWEDENCASELAW]: DatabaseSource.SWEDEN_CASELAW,
  [ResearchArea.SINGAPORECASELAW]: DatabaseSource.SINGAPORE_CASELAW,
  [ResearchArea.MEMOS]: DatabaseSource.MEMOS,
  [ResearchArea.TAX]: DatabaseSource.TAX,
  [ResearchArea.USCASELAW]: DatabaseSource.US_CASELAW,
  [ResearchArea.MACFARLANES]: DatabaseSource.MACFARLANES,
  [ResearchArea.USACASELAW]: null,
  [ResearchArea.AUSBREACHREPORTING]: DatabaseSource.AUSBREACHREPORTING,
  [ResearchArea.FROMCOUNSEL]: null,
}

export const VAULT_SOURCE_SELECT_FILES_DESCRIPTION =
  'Select files from this project to query'

export const isVaultKnowledgeSource = (
  item: Maybe<KnowledgeSourceItem>
): item is VaultKnowledgeSource => {
  return !!item && item.type === FileSource.VAULT
}

export const isDatabaseSource = (
  source: KnowledgeSource
): source is DatabaseSource => {
  return Object.values(DatabaseSource).includes(source as DatabaseSource)
}

export const isResearchKnowledgeSource = (
  item: Maybe<KnowledgeSourceItem>
): item is ResearchKnowledgeSource => {
  return !!(item && isDatabaseSource(item.type))
}

export const isCuatreKnowledgeSource = (
  item: Maybe<KnowledgeSourceItem>
): item is ResearchKnowledgeSource => {
  return !!item && item.type === DatabaseSource.CUATRECASAS
}

export const isMacfarlanesKnowledgeSource = (
  item: Maybe<KnowledgeSourceItem>
): item is ResearchKnowledgeSource => {
  return !!item && item.type === DatabaseSource.MACFARLANES
}

export const getDateRange = (
  item: Maybe<KnowledgeSourceItem>
): DateRange | null => {
  if (!isResearchKnowledgeSource(item)) return null
  return item.dateRange ?? null
}
