import _ from 'lodash'

import { Event } from 'models/event'

import { getCleanedEvent } from 'utils/task-definitions'

// Enum for type of history we're looking at
enum HistoryTypeEnum {
  USER = 'user',
  WORKSPACE = 'workspace',
}

interface HistoryStore {
  events: Event[]
}

// Args needed to fetch history
interface FetchHistoryArgs {
  currentPage: number
  pageSize: number
  workspaceSlug?: string
  historyType: HistoryTypeEnum
}

// Helper to make URL params given all of the
const makeParamsShort = ({ workspaceSlug, historyType }: FetchHistoryArgs) => {
  const params = new URLSearchParams()
  if (historyType === HistoryTypeEnum.WORKSPACE && !_.isNil(workspaceSlug)) {
    params.append('workspace_slug', workspaceSlug)
  }
  return params
}

// Transform and clean up history, using another function for cleaning events
const cleanUpHistory = (data: Event[]) => {
  const cleanedEvents = data.map((historyEvent: Event) => {
    return getCleanedEvent(historyEvent)
  })
  return cleanedEvents
}

// For checking the neighbor pages, need to make sure they don't go past indexes
const isValidPageNumber = (page: number, totalPages: number) => {
  return page >= 1 && page <= totalPages
}

// exports
export { makeParamsShort, HistoryTypeEnum, cleanUpHistory, isValidPageNumber }
export type { FetchHistoryArgs, HistoryStore }
