import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { EM_DASH } from 'utils/utils'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'
import { DEFAULT_FILES_WARNING_THRESHOLD } from 'components/vault/components/file-explorer/vault-file-explorer'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'
import { getReadyToQueryFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultWorkflowAddFiles from 'components/vault/workflows/vault-workflow-add-files'

import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

const VaultWorkflowBuilder = () => {
  const { projectId } = useParams()
  const { trackEvent } = useAnalytics()
  const [currentProjectMetadata, folderIdToVaultFolder] = useVaultStore(
    useShallow((state) => [
      state.currentProjectMetadata,
      state.folderIdToVaultFolder,
    ])
  )
  const [
    workflowModalState,
    selectedWorkflow,
    setWorkflowModalState,
    setSelectedWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.workflowModalState,
      state.selectedWorkflow,
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  const [preselectFileIds, setPreselectFileIds] = useState<Set<string>>(
    new Set()
  )

  const userInfo = useAuthUser()
  const [isShowingWorkflowAdminInfo, setIsShowingWorkflowAdminInfo] =
    useState(false)

  useHotkeys(
    'Control+h',
    () => {
      if (userInfo.IsVaultWorkflowAdminUser) {
        setIsShowingWorkflowAdminInfo(!isShowingWorkflowAdminInfo)
      }
    },
    [isShowingWorkflowAdminInfo]
  )

  const handleOpenAddFiles = () => {
    trackEvent('Vault File Selector Viewed', {
      source: 'Vault Workflow',
      workflow_id: selectedWorkflow?.id,
      workflow_name: selectedWorkflow?.name,
    })

    const readyToQueryFileIds = getReadyToQueryFileIds(
      currentProjectMetadata,
      null,
      folderIdToVaultFolder
    )
    const fileIds = new Set(readyToQueryFileIds)
    if (fileIds.size <= DEFAULT_FILES_WARNING_THRESHOLD) {
      // If the number of files is less than the threshold, preselect them.
      // Otherwise, we'll show a warning in the add files dialog if user tries
      // to select all these files.
      setPreselectFileIds(fileIds)
    }
    setWorkflowModalState(WorkflowModalState.AddFilesWorkflow)
  }

  if (!projectId) {
    return null
  }

  return (
    <Dialog
      open={
        workflowModalState === WorkflowModalState.Builder ||
        workflowModalState === WorkflowModalState.AddFilesWorkflow
      }
      onOpenChange={(open) => {
        setWorkflowModalState(
          open ? WorkflowModalState.Builder : WorkflowModalState.None
        )
        if (!open) {
          setSelectedWorkflow(null)
        }
      }}
    >
      <DialogContent
        className="sm:max-w-[800px]"
        innerClassName="flex flex-col p-0"
        hasContainer={false}
      >
        {workflowModalState === WorkflowModalState.Builder && (
          <VaultWorkflowBuilderDialogContents
            handleOpenAddFiles={handleOpenAddFiles}
            isShowingWorkflowAdminInfo={isShowingWorkflowAdminInfo}
          />
        )}
        {workflowModalState === WorkflowModalState.AddFilesWorkflow && (
          <VaultWorkflowAddFiles
            projectId={projectId}
            onBackButtonClicked={() =>
              setWorkflowModalState(WorkflowModalState.Builder)
            }
            buttonLabel="Create table"
            preselectFileIds={preselectFileIds}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const VaultWorkflowBuilderDialogContents = ({
  handleOpenAddFiles,
  isShowingWorkflowAdminInfo,
}: {
  handleOpenAddFiles: () => void
  isShowingWorkflowAdminInfo: boolean
}) => {
  const [selectedWorkflow, setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.selectedWorkflow, state.setWorkflowModalState])
  )
  const hasTags = selectedWorkflow?.tags && selectedWorkflow.tags.length > 0

  return (
    <>
      <DialogHeader className="gap-4 px-5 pt-4">
        <DialogTitle className="w-full text-center">
          Create a {selectedWorkflow?.name} table
        </DialogTitle>
        <div className="-mx-6 border-b" />
        <DialogDescription className="text-left text-primary">
          {selectedWorkflow?.description}
        </DialogDescription>
      </DialogHeader>
      <div className="mx-5 flex max-h-[40vh] rounded border">
        <ScrollArea className="flex max-h-[40vh] w-full flex-col">
          <Table className="w-full rounded">
            <TableHeader className="transitionbg-accent hover:bg-accent [&_tr]:hover:bg-accent">
              <TableRow className="transition hover:bg-accent">
                <TableHead className="h-8 px-2 text-xs font-normal text-primary">
                  Title
                </TableHead>
                <TableHead className="h-8 px-2 text-xs font-normal text-primary">
                  Query
                </TableHead>
                {isShowingWorkflowAdminInfo && (
                  <TableHead className="h-8 px-2 text-xs font-normal text-primary">
                    User prompt
                  </TableHead>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedWorkflow?.columns.map((column) => (
                <TableRow
                  key={column.id}
                  className="transition hover:bg-primary"
                >
                  <TableCell className="px-2 py-2 text-xs">
                    {column.header}
                  </TableCell>
                  <TableCell className="px-2 py-2 text-xs">
                    {column.fullText}
                  </TableCell>
                  {isShowingWorkflowAdminInfo && (
                    <TableCell className="px-2 py-2 text-xs">
                      {column.userPrompt ?? EM_DASH}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      {hasTags && (
        <div className="flex flex-col gap-1 px-5">
          <p className="text-xs text-muted">Recommended for</p>
          <div className="flex flex-wrap gap-1">
            {selectedWorkflow.tags?.map((tag) => (
              <SimpleDocumentClassificationPill
                isPill
                key={tag.id}
                tagName={tag.tagName}
                tagId={tag.id}
              />
            ))}
          </div>
        </div>
      )}
      <div
        className={cn('flex items-center justify-between px-5 pb-4', {
          'border-t pt-4': hasTags,
        })}
      >
        <p className="text-xs text-muted">
          You can remove, rename, or add columns after the table is created.
        </p>
        <div className="flex justify-end gap-2">
          <Button
            variant="outline"
            onClick={() => setWorkflowModalState(WorkflowModalState.None)}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault()
              handleOpenAddFiles()
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}

export default VaultWorkflowBuilder
