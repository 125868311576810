import _ from 'lodash'

import { DefaultVisibility } from 'openapi/models/DefaultVisibility'
import { PermSource } from 'openapi/models/PermSource'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'
import { PermissionCategory } from 'openapi/models/PermissionCategory'
import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'

import { Permission } from './user-info'

export type { PermWithSource } from 'openapi/models/PermWithSource'

export interface Perm {
  permId: string
  name: string
  desc: string
  permSources?: string[]
  expiresAt?: Maybe<string>
}

export interface PermBundle {
  id: PermissionBundleId
  name: string
  category: PermissionCategory
  description: string
  permissions: Permission[]
  enabledPerms: Permission[]
  defaultVisibility: DefaultVisibility
}

export interface BulkManagePermsUsersResult {
  userNoAction: string[]
  userManagedPerms: string[]
  errorUser: string[]
}

export async function FetchAllPerms(): Promise<Perm[]> {
  const result = await Services.Backend.Get<Perm[]>(`internal_admin/perms`)
  const perms = result ?? []
  return perms
}

export async function CreatePerm(perm: Perm): Promise<string> {
  const result = await Services.Backend.Post('internal_admin/perms', perm)
  if (_.isNil(result)) {
    throw new Error(`Failed to create perm`)
  }
  return (result as Perm).permId
}

export async function UpdatePerm(perm: Perm): Promise<void> {
  await Services.Backend.Patch(`internal_admin/perms/${perm.permId}`, perm)
}

export async function DeletePerm(perm: Perm): Promise<void> {
  await Services.Backend.Delete(`internal_admin/perms/${perm.permId}`)
}

interface AddPermsToUserParams {
  userEmail: string
  perms: Permission[]
  workspaceId: number
  sensitiveDataPermReason?: string
  sensitiveDataPermGrantCategory?: string
  expiresAt?: string
}

export async function AddPermsToUser({
  userEmail,
  perms,
  workspaceId,
  sensitiveDataPermReason,
}: AddPermsToUserParams): Promise<boolean> {
  return Services.Backend.Post(
    `internal_admin/perms/add_user_perms`,
    {
      userEmail,
      perms,
      workspaceId,
      sensitiveDataPermReason,
    },
    { maxRetryCount: 0 }
  )
}

export async function DeleteUserPerm(
  userEmail: string,
  permId: Permission,
  workspaceId: number
): Promise<boolean> {
  return Services.Backend.Post(`internal_admin/perms/delete_user_perm`, {
    userEmail,
    permId,
    workspaceId,
  })
}

export interface PermBundleMigrationResult {
  [workspaceId: number]: string[]
}

export async function MigrateWorkspaceToPermBundles(
  workspaceIds: number[],
  dryRun: boolean = true
): Promise<PermBundleMigrationResult> {
  const result = await Services.Backend.Post<PermBundleMigrationResult>(
    `internal_admin/permission_bundles/migrate`,
    {
      workspaceIds,
      dryRun,
    },
    { throwOnError: true }
  )
  if (result instanceof RequestError) {
    throw result
  }
  return result
}

// new endpoints should point to /settings in the backend

export async function BulkGrantPermsToUsers(
  emails: string[],
  permIds: Permission[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(`settings/perms/bulk_grant_user_perms`, {
    userEmails: emails,
    perms: permIds,
  })
}

export async function BulkRevokePermsFromUsers(
  emails: string[],
  permIds: Permission[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(`settings/perms/bulk_revoke_user_perms`, {
    userEmails: emails,
    perms: permIds,
  })
}

export interface UserPermInfo {
  email: string
  workspace: string
  sources: string[]
}

export const fetchPermUsers = async (
  permId: Permission
): Promise<UserPermInfo[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('perm_id', permId)
  const result = await Services.Backend.Get<UserPermInfo[]>(
    `internal_admin/perms/users?${searchParams.toString()}`
  )
  return result
}

export const grantSensitiveDataPerm = async ({
  userEmail,
  perms,
  workspaceId,
  sensitiveDataPermReason,
  sensitiveDataPermGrantCategory,
  expiresAt,
}: AddPermsToUserParams) => {
  return Services.Backend.Post(
    `internal_admin/perms/temporary_user_perm`,
    {
      userEmail,
      perms,
      workspaceId,
      sensitiveDataPermReason,
      sensitiveDataPermGrantCategory,
      expiresAt,
    },
    { maxRetryCount: 0, throwOnError: true }
  )
}

// more exports
export { PermSource }
