import React from 'react'

import { Info } from 'lucide-react'
import pluralize from 'pluralize'

import { IntegrationType } from 'openapi/models/IntegrationType'
import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'

import { mbToReadable } from 'utils/file-utils'

import { useAuthUser } from 'components/common/auth-context'
import GoogleDriveButton from 'components/common/google-drive-button'
import SharepointButton from 'components/common/sharepoint-button'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { MAX_EXCEL_FILE_SIZE_IN_MB } from 'components/vault/utils/vault'
import {
  maxFileSizeInMb,
  maxTotalFileSizeInMb,
} from 'components/vault/utils/vault'

interface VaultCreateFileDropUpload {
  acceptedFileTypes: FileType[]
  open: () => void
  onUploadFromIntegration: (files: File[]) => Promise<void>
}

const VaultCreateFileUpload: React.FC<VaultCreateFileDropUpload> = ({
  acceptedFileTypes,
  open,
  onUploadFromIntegration,
}) => {
  const userInfo = useAuthUser()

  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(userInfo)
  const googleDriveEnabled =
    IntegrationDefinitions[IntegrationType.GOOGLE_DRIVE].available(userInfo)

  const customFileTypes =
    mbToReadable(maxFileSizeInMb(userInfo)) !==
    mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB)
      ? [
          {
            fileType: FileType.EXCEL,
            maxSize: mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB),
          },
        ]
      : undefined

  const maxFileSize = mbToReadable(maxFileSizeInMb(userInfo))
  const totalSize = mbToReadable(maxTotalFileSizeInMb(userInfo))
  const maxFiles = userInfo.workspace.getVaultFilesCountLimit(
    userInfo.vaultFeature
  )

  return (
    <div className="space-y-4">
      <div
        data-testid="dropzone"
        className="mt-4 flex size-full items-center justify-between rounded-md border border-dashed p-4"
      >
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <p>Drag and drop files here</p>
            <Tooltip>
              <TooltipTrigger>
                <Icon icon={Info} size="small" />
              </TooltipTrigger>
              <TooltipContent>
                Supported file types:{' '}
                {removeSubsetDuplicates(
                  acceptedFileTypes.map(
                    (fileType) => FileTypeReadableName[fileType]
                  )
                )
                  .sort((a, b) => a.localeCompare(b))
                  .join(', ')}
                <br />
                Maximum size per file: {maxFileSize}
                {customFileTypes &&
                  ` (${customFileTypes
                    .map(
                      (fileType) =>
                        `${fileType.maxSize} for ${
                          FileTypeReadableName[fileType.fileType]
                        }`
                    )
                    .join(', ')})`}
                <br />
                Upload limit:{' '}
                {maxFiles && `${maxFiles} ${pluralize('file', maxFiles)}`}
                {totalSize && (
                  <>
                    {maxFiles && ', '}
                    {totalSize} total
                  </>
                )}
              </TooltipContent>
            </Tooltip>
          </div>
          <p className="text-muted">Choose from your computer</p>
        </div>
        <Button variant="outline" onClick={open}>
          Browse files
        </Button>
      </div>
      {(sharepointEnabled || googleDriveEnabled) && (
        <>
          <p className="text-muted">Or choose from</p>
          <div className="flex w-full flex-col items-center gap-2">
            {sharepointEnabled && (
              <SharepointButton
                acceptedFileTypes={acceptedFileTypes}
                onUploadFromSharepoint={onUploadFromIntegration}
                size="lg"
              />
            )}
            {googleDriveEnabled && (
              <GoogleDriveButton
                acceptedFileTypes={acceptedFileTypes}
                onUploadFromGoogleDrive={onUploadFromIntegration}
                size="lg"
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default VaultCreateFileUpload
