import React, { useCallback, useEffect, useState } from 'react'
import { useMount } from 'react-use'

import _ from 'lodash'
import { Loader2 } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import Services from 'services'
import { useIntegrationsStore } from 'stores/integrations-store'

import { downloadGoogleDriveFiles } from 'utils/file-utils'
import { googleDriveIntegrationAppId } from 'utils/server-data'
import { googleDriveIntegrationApiKey } from 'utils/server-data'
import { displayErrorMessage } from 'utils/toast'

import { useOauthConnect } from 'components/settings/integrations/use-oauth-connect'
import { fetchIntegrationToken } from 'components/settings/integrations/utils'

import { useAnalytics } from './analytics/analytics-context'
import { useAuthUser } from './auth-context'

const integrationType = IntegrationType.GOOGLE_DRIVE
const GOOGLE_API_SCRIPT_ID = 'google-api-script'
const GOOGLE_API_SCRIPT_SRC = '/scripts/google-api.js'
const GOOGLE_DRIVE_CONNECTED_METRIC = 'google_drive_connected'
const GOOGLE_DRIVE_FILES_DOWNLOADED_METRIC = 'google_drive_files_downloaded'
const GOOGLE_DRIVE_FILE_PICKER_CLICKED_METRIC =
  'google_drive_file_picker_clicked'
const GOOGLE_DRIVE_FILE_PICKER_OPENED_METRIC = 'google_drive_file_picker_opened'

const GoogleDriveFilePicker = () => {
  useMount(() => {
    if (!document.getElementById(GOOGLE_API_SCRIPT_ID)) {
      const script = document.createElement('script')
      script.id = GOOGLE_API_SCRIPT_ID
      script.src = GOOGLE_API_SCRIPT_SRC
      script.async = true
      script.defer = true
      script.onload = () => {
        gapi.load('picker', () => {})
      }
      document.body.appendChild(script)
    }
  })

  const [
    integrationFilePickerOpenState,
    setIntegrationFilePickerOpenState,
    getIntegrationToken,
    setIntegrationToken,
  ] = useIntegrationsStore(
    useShallow((state) => [
      state.integrationFilePickerOpenState,
      state.setIntegrationFilePickerOpenState,
      state.getIntegrationToken,
      state.setIntegrationToken,
    ])
  )

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const onConnectCallback = useCallback(() => {
    Services.HoneyComb.Record({
      metric: GOOGLE_DRIVE_CONNECTED_METRIC,
      user_id: userInfo.id,
      workspace: userInfo.workspace.id,
      integration: integrationType,
    })
    trackEvent(`${integrationType} connected`)
  }, [userInfo, trackEvent])

  const { error, handleConnect } = useOauthConnect({
    integrationType,
    onConnectCallback,
  })

  useEffect(() => {
    if (!_.isNil(integrationFilePickerOpenState)) {
      Services.HoneyComb.Record({
        metric: GOOGLE_DRIVE_FILE_PICKER_CLICKED_METRIC,
        user_id: userInfo.id,
        workspace: userInfo.workspace.id,
      })
      trackEvent('Google Drive File Picker Clicked')
    }
  }, [
    integrationFilePickerOpenState,
    trackEvent,
    userInfo.id,
    userInfo.workspace.id,
  ])

  const [loading, setLoading] = useState(false)

  const fetchGoogleDriveAccessToken = useCallback(
    async (silent: boolean = false) => {
      try {
        const response = await fetchIntegrationToken(integrationType)
        if (_.isNil(response)) {
          return null
        }
        setIntegrationToken(integrationType, response)
        return response.accessToken
      } catch (error) {
        console.error('Error fetching access token', error)
        if (!silent) {
          displayErrorMessage('Error fetching access token')
        }
        return null
      }
    },
    [setIntegrationToken]
  )

  const getToken = useCallback(async () => {
    // TODO manage refresh of token if tab sits idle
    const integrationToken = getIntegrationToken(integrationType)
    if (_.isNil(integrationToken)) {
      return await fetchGoogleDriveAccessToken()
    }
    return integrationToken.accessToken
  }, [getIntegrationToken, fetchGoogleDriveAccessToken])

  const shouldShowPicker =
    !_.isNil(integrationFilePickerOpenState) &&
    integrationFilePickerOpenState.integrationType === integrationType

  // TODO fix prevent default when scrolling inside picker
  useEffect(() => {
    let picker: google.picker.Picker | null = null

    const onResults = async (results: google.picker.ResponseObject) => {
      if (
        results[google.picker.Response.ACTION] === google.picker.Action.PICKED
      ) {
        const integrationToken = getIntegrationToken(integrationType)
        if (_.isNil(integrationToken)) {
          return
        }
        const files = await downloadGoogleDriveFiles(
          results[google.picker.Response.DOCUMENTS] ?? [],
          integrationToken.accessToken
        )
        await integrationFilePickerOpenState?.onUploadFromIntegration(files)
        setIntegrationFilePickerOpenState(null)
        Services.HoneyComb.Record({
          metric: GOOGLE_DRIVE_FILES_DOWNLOADED_METRIC,
          user_id: userInfo.id,
          workspace: userInfo.workspace.id,
          integration: integrationType,
          filesCount: files.length,
        })
        trackEvent(`${integrationType} files downloaded`)
      } else if (
        results[google.picker.Response.ACTION] === google.picker.Action.CANCEL
      ) {
        setIntegrationFilePickerOpenState(null)
      } else if (
        results[google.picker.Response.ACTION] === google.picker.Action.ERROR
      ) {
        displayErrorMessage('An unexpected error occurred')
        setIntegrationFilePickerOpenState(null)
      }
    }

    const maybeInitializePicker = async () => {
      if (shouldShowPicker) {
        let accessToken = await getToken()
        if (_.isNil(accessToken)) {
          setLoading(true)
          await handleConnect()
          setLoading(false)
          accessToken = await getToken()
        }

        if (_.isNil(accessToken)) {
          if (!_.isNil(error)) {
            displayErrorMessage(error)
          } else {
            displayErrorMessage(
              'We encountered an error while connecting to Google Drive. Please try again.'
            )
          }
          setIntegrationFilePickerOpenState(null)
          return
        }

        picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setOAuthToken(accessToken)
          .addView(google.picker.ViewId.DOCS)
          .setAppId(googleDriveIntegrationAppId)
          .setDeveloperKey(googleDriveIntegrationApiKey)
          .addView(google.picker.ViewId.DOCS)
          .setCallback(onResults)
          .setSize(1200, 700)
          .build()
        picker.setVisible(true)
        Services.HoneyComb.Record({
          metric: GOOGLE_DRIVE_FILE_PICKER_OPENED_METRIC,
          user_id: userInfo.id,
          workspace: userInfo.workspace.id,
        })
        trackEvent('Google Drive File Picker Opened')
      }
    }
    void maybeInitializePicker()

    return () => {
      // TODO maybe we don't dispose but re-use?
      picker?.dispose()
      if (!_.isNil(integrationFilePickerOpenState)) {
        setIntegrationFilePickerOpenState(null)
      }
    }
  }, [
    shouldShowPicker,
    integrationFilePickerOpenState,
    setIntegrationFilePickerOpenState,
    getToken,
    handleConnect,
    error,
    getIntegrationToken,
    trackEvent,
    userInfo.id,
    userInfo.workspace.id,
  ])

  return shouldShowPicker ? (
    <>
      <div className="pointer-events-auto fixed inset-0 z-[100] bg-primary/80 backdrop-blur-sm" />
      <div className="pointer-events-auto fixed inset-0 z-[100] flex items-center justify-center px-4">
        {(_.isNil(getIntegrationToken(integrationType)) || loading) && (
          <Loader2 className="size-8 animate-spin" />
        )}
      </div>
    </>
  ) : null
}

export default GoogleDriveFilePicker
