import React, { useEffect, useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import { Alert, Modal, Typography } from 'antd'
import _ from 'lodash'
import { PlusIcon } from 'lucide-react'

import { getWorkspaceRoles, setUserRole, WorkspaceRole } from 'models/roles'
import {
  BulkDeleteUsersResult,
  InternalAdminBulkDeleteUser,
  RawUserEnriched,
} from 'models/users'
import { Workspace } from 'models/workspace'
import { AuditLogType } from 'openapi/models/AuditLogType'
import { DefaultRole } from 'openapi/models/DefaultRole'

import { usePostAuditLog } from 'utils/audit-log'
import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import CsvDownload from 'components/ui/csv-download'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Dialog } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import SearchInput from 'components/ui/search-input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import ExpandablePermissionsCell from './expandable-permissions-cell'

interface UserInfoTableProps {
  users: RawUserEnriched[]
  workspace: Workspace
  fetchData: () => void
}

// TODO (ken): Feels like this could be in components/ui
const SelectHeader: React.FC<{ table: Table<RawUserEnriched> }> = ({
  table,
}) => {
  return (
    <div className="flex w-full justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          table.toggleAllRowsSelected(!table.getIsAllRowsSelected())
        }}
      >
        <Checkbox
          checked={
            table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()
          }
          isIndeterminate={table.getIsSomeRowsSelected()}
          onCheckedChange={(value) => {
            table.toggleAllRowsSelected(!!value)
          }}
          aria-label="Select all"
        />
      </Button>
    </div>
  )
}

const SelectCell: React.FC<{ row: Row<RawUserEnriched> }> = ({ row }) => {
  return (
    <div className="flex w-full justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          row.toggleSelected(!row.getIsSelected())
        }}
      >
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      </Button>
    </div>
  )
}

const WorkspaceUsersTableV2: React.FC<UserInfoTableProps> = ({
  users,
  fetchData,
  workspace,
}) => {
  const userInfo = useAuthUser()
  const userManagementPerm = userInfo.isUserManagement
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'deletedAt', desc: false },
    { id: 'email', desc: false },
  ])
  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleteAcknowledge, setDeleteAcknowledge] = useState<boolean>(false)
  const [deleteErrorUsers, setDeleteErrorUsers] = useState<string[]>([])

  const [roles, setRoles] = useState<WorkspaceRole[]>([])
  const [pendingUserRole, setPendingUserRole] = useState<{
    userId: string
    userEmail: string
    role: WorkspaceRole
  }>()

  const { postAuditLog } = usePostAuditLog()

  useEffect(() => {
    const fetchRoles = async () => {
      const roles = await getWorkspaceRoles(workspace.id)
      setRoles(
        roles.filter((role) => {
          if (role.deletedAt) return false // TODO (ken): Move filter to API call
          const baseRoleId = role.roleId.slice(workspace.slug.length + 1)
          if (
            baseRoleId === DefaultRole.API ||
            baseRoleId === DefaultRole.VAULT_ADD_ON
          ) {
            // API should be restricted to svc users and Vault seats should be managed in Vault settings
            return false
          }

          return true
        })
      )
    }
    void fetchRoles()
  }, [workspace.id, workspace.slug])

  const columns: ColumnDef<RawUserEnriched>[] = [
    {
      id: 'select',
      header: ({ table }) => <SelectHeader table={table} />,
      cell: ({ row }) => <SelectCell row={row} />,
      enableSorting: false,
      size: 40,
    },
    {
      accessorKey: 'email',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Email" />
      ),
      cell: ({ getValue }) => <div className="ml-3">{String(getValue())}</div>,
    },
    {
      accessorKey: 'roles',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Roles" />
      ),
      cell: ({ row }) => {
        const userRoles = row.original.roles

        if (userRoles.length === 0) {
          return (
            <div className="flex items-center gap-1">
              <AlertIcon size="small" />
              None
            </div>
          )
        }

        if (userRoles.length > 1) {
          return (
            <div>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex items-center gap-1">
                    <AlertIcon size="small" />
                    {`${userRoles.length} roles`}
                  </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="max-w-96">
                  {userRoles.map((role) => {
                    return (
                      <div key={role.name} className="py-0.5 text-sm">
                        {role.name}
                      </div>
                    )
                  })}
                </TooltipContent>
              </Tooltip>
            </div>
          )
        }

        return (
          <Select
            value={pendingUserRole?.role.roleId || userRoles[0].id}
            onValueChange={(value) => {
              const role = roles.find((role) => role.roleId === value)
              if (_.isNil(role)) return
              setPendingUserRole({
                userId: row.original.id,
                userEmail: row.original.email,
                role,
              })
            }}
          >
            <SelectTrigger>
              <SelectValue>
                <div className="pr-2">
                  {pendingUserRole?.role.name || userRoles[0].name}
                </div>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {roles
                .filter((role) => role.rolePk !== userRoles[0].id)
                .map((role) => (
                  <SelectItem key={role.roleId} value={role.roleId}>
                    {role.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        )
      },
    },
    {
      accessorKey: 'extraPermissions',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Extra permissions" />
      ),
      cell: ({ row }) => (
        <ExpandablePermissionsCell
          permissions={row.original.extraPermissions}
          rowId={row.original.email}
        />
      ),
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Account created" />
      ),
      cell: ({ getValue }) => {
        const value = getValue()
        if (_.isNil(value)) {
          return null
        }
        return <div className="ml-3">{getTableDateString(String(value))}</div>
      },
    },
  ]

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    onSortingChange: setSorting,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (updater) => {
      if (typeof updater === 'function') {
        const newSelection = updater(
          Object.fromEntries(selectedRowKeys.map((key) => [key, true]))
        )
        setSelectedRowKeys(Object.keys(newSelection))
      } else {
        setSelectedRowKeys(Object.keys(updater))
      }
    },
    getRowId: (row) => row.email,
    state: {
      sorting,
      globalFilter: filter,
      pagination: tablePaginationState,
      rowSelection: Object.fromEntries(
        selectedRowKeys.map((key) => [key, true])
      ),
    },
  })

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return null

  const handleAddUser = () => {
    // TODO (ken): Implement in follow up PR
    return
  }

  const handleSetUserRole = async () => {
    if (_.isNil(pendingUserRole)) {
      return
    }
    try {
      await setUserRole(pendingUserRole.userId, pendingUserRole.role.rolePk)
      displaySuccessMessage('Role updated successfully')
      fetchData()
    } catch (error) {
      displayErrorMessage('Failed to update role')
    }
  }

  const bulkDeleteUsers = async () => {
    if (_.isNil(userInfo) || !userManagementPerm) {
      displayErrorMessage('You do not have permission to bulk delete users')
      return
    }
    const emails = selectedRowKeys as string[]
    if (_.isEmpty(emails)) {
      displayErrorMessage('No users selected')
      return
    }
    const result: BulkDeleteUsersResult =
      await InternalAdminBulkDeleteUser(emails)
    if (_.isNil(result)) {
      displayErrorMessage('Failed to delete users')
      return
    } else {
      const { usersDeleted, usersFailed } = result
      if (!_.isEmpty(usersDeleted)) {
        displaySuccessMessage(`${usersDeleted.length} users deleted`)
      }
      setDeleteErrorUsers(usersFailed)
    }
    setDeleteAcknowledge(false)
    setSelectedRowKeys([])
    setFilter('')
    fetchData()
    setDeleteModalOpen(false)
  }

  const exportButton = table.getFilteredRowModel().rows.length > 0 && (
    <CsvDownload
      hasIcon
      buttonText="Export"
      filename={`${workspace.clientName}_users_internal_admin.csv`}
      data={table.getFilteredRowModel().rows.map((row) => ({
        id: row.original.id,
        email: row.original.email,
        pseudonymizedEmail: row.original.pseudonymizedEmail,
        role: row.original.roles.map((role) => role.name).join(', '),
        extraPermissions: row.original.extraPermissions
          .map((perm) => perm.permBundleName)
          .join(', '),
        createdAt: row.original.createdAt,
        updatedAt: row.original.updatedAt,
      }))}
      headers={[
        { label: 'Id', key: 'id' },
        { label: 'Email', key: 'email' },
        { label: 'Pseudonymized Email', key: 'pseudonymizedEmail' },
        { label: 'Role', key: 'role' },
        { label: 'Extra Permissions', key: 'extraPermissions' },
        { label: 'Created At', key: 'createdAt' },
        { label: 'Updated At', key: 'updatedAt' },
      ]}
      onClickCallback={async () => {
        await postAuditLog(
          AuditLogType.INTERNAL_ADMINCLIENT_EXPORT_WORKSPACE_USERS,
          workspace.id,
          {
            numUsers: table.getFilteredRowModel().rows.length,
          }
        )
      }}
    />
  )

  return (
    <>
      {deleteErrorUsers.length > 0 && (
        <div className="mb-4">
          <Alert
            message={`Failed to delete ${deleteErrorUsers.length} users`}
            description={
              <Typography.Paragraph
                ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
              >
                {deleteErrorUsers.join(', ')}
              </Typography.Paragraph>
            }
            type="error"
            showIcon
            closable
            onClose={() => setDeleteErrorUsers([])}
          />
        </div>
      )}
      <div className="flex flex-col gap-4 px-4">
        <div className="flex items-center justify-between gap-2 py-2">
          <p>Manage user roles, add new users, and view their permissions.</p>
          <div className="flex items-center gap-2">
            {exportButton}
            <Button onClick={handleAddUser}>
              <Icon icon={PlusIcon} className="mr-1" />
              Add user
            </Button>
          </div>
        </div>

        <div className="flex items-center justify-between gap-2">
          <SearchInput value={filter} setValue={setFilter} withIcon />
          <div className="flex items-center gap-2">
            {!_.isEmpty(selectedRowKeys) && userManagementPerm ? (
              <div className="flex space-x-2">
                <Button
                  variant="destructive"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  {`Bulk delete ${selectedRowKeys.length} users`}
                </Button>
                <Button onClick={() => setSelectedRowKeys([])}>
                  Clear all
                </Button>
              </div>
            ) : (
              <p>{`${table.getFilteredRowModel().rows.length} users`}</p>
            )}
          </div>
        </div>

        <DataTable
          table={table}
          useVirtual={users.length > 100}
          virtualEstimateSize={65}
          hrefForRow={(row) =>
            row.original.deletedAt
              ? ''
              : `/settings/internal_admin/user-inspector`
          }
          hrefOptions={(row) => ({
            navigateOptions: { state: { email: row.original.email } },
          })}
          isRowDisabled={(row) => Boolean(row.original.deletedAt)}
        />
        <DataTableFooter table={table} />
      </div>

      {pendingUserRole && (
        <Dialog open onOpenChange={() => setPendingUserRole(undefined)}>
          <ConfirmationDialog
            title="Change user role"
            description={`Are you sure you want to set ${pendingUserRole.userEmail}'s role to ${pendingUserRole.role.name}?`}
            cta={{
              label: 'Change role',
              onClick: handleSetUserRole,
            }}
            secondaryCta={{
              label: 'Cancel',
              onClick: () => {
                setPendingUserRole(undefined)
              },
            }}
          />
        </Dialog>
      )}
      {userManagementPerm && (
        <Modal
          title={
            <div>
              <div className="text-destructive">Delete users</div>
            </div>
          }
          open={deleteModalOpen}
          onOk={bulkDeleteUsers}
          onCancel={() => {
            setDeleteAcknowledge(false)
            setDeleteModalOpen(false)
          }}
          okText={`Delete ${selectedRowKeys.length} Users`}
          cancelText="Cancel"
          width={600}
          okButtonProps={{
            disabled: !deleteAcknowledge,
            danger: true,
          }}
        >
          <div>
            <Typography>
              This is non-reversible. It will delete the users from Auth0 and
              remove their access from Harvey.
              <div className="mt-2">
                <Typography.Text>
                  <b>Users to be deleted:</b>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                  >
                    <div className="mt-1">
                      {(selectedRowKeys as string[]).map((email) => (
                        <span key={email}>
                          <Badge variant="outline">{email}</Badge>
                        </span>
                      ))}
                    </div>
                  </Typography.Paragraph>
                </Typography.Text>
              </div>
              <div className="mt-4 pb-2">
                <Alert
                  message={
                    <div className="flex justify-between">
                      <div>{`Please acknowledge you want to delete ${selectedRowKeys.length} users`}</div>
                      <Checkbox
                        checked={deleteAcknowledge}
                        onCheckedChange={() =>
                          setDeleteAcknowledge(!deleteAcknowledge)
                        }
                      />
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            </Typography>
          </div>
        </Modal>
      )}
    </>
  )
}

export default WorkspaceUsersTableV2
