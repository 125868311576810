import React from 'react'

import GoogleDriveIcon from 'components/ui/icons/google-drive-icon'

const GoogleDriveLogo: React.FC = () => {
  return (
    <div className="flex items-center space-x-2 pl-px">
      <div className="flex items-center space-x-2">
        <GoogleDriveIcon className="size-4" />
        <p className="text-sm text-primary">Google Drive</p>
      </div>
    </div>
  )
}

export default GoogleDriveLogo
