import React from 'react'

import DataRow from './data-row'

const RequirementComponent: React.FC<{
  requirement: any
  idx: number
}> = ({ requirement, idx }) => {
  return (
    <div className="space-y-2">
      <div className="mb-1 flex space-x-4">
        <p className="w-24 text-xs font-semibold">Requirement {idx + 1}</p>
        <p className="text-xs font-semibold">
          {requirement.requirementConclusion.charAt(0).toUpperCase() +
            requirement.requirementConclusion.slice(1)}
        </p>
      </div>

      <DataRow label="Requirement" value={requirement.requirement} />
      <DataRow label="Analysis" value={requirement.requirementAnalysis} />
    </div>
  )
}

export default RequirementComponent
