/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkflowInputComponentBlocks = {
    FILE_UPLOAD: 'FileUpload',
    SELECT: 'Select',
    TABLE_SELECT: 'TableSelect'
} as const;
export type WorkflowInputComponentBlocks = typeof WorkflowInputComponentBlocks[keyof typeof WorkflowInputComponentBlocks];


export function instanceOfWorkflowInputComponentBlocks(value: any): boolean {
    for (const key in WorkflowInputComponentBlocks) {
        if (Object.prototype.hasOwnProperty.call(WorkflowInputComponentBlocks, key)) {
            if (WorkflowInputComponentBlocks[key as keyof typeof WorkflowInputComponentBlocks] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkflowInputComponentBlocksFromJSON(json: any): WorkflowInputComponentBlocks {
    return WorkflowInputComponentBlocksFromJSONTyped(json, false);
}

export function WorkflowInputComponentBlocksFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowInputComponentBlocks {
    return json as WorkflowInputComponentBlocks;
}

export function WorkflowInputComponentBlocksToJSON(value?: WorkflowInputComponentBlocks | null): any {
    return value as any;
}

export function WorkflowInputComponentBlocksToJSONTyped(value: any, ignoreDiscriminator: boolean): WorkflowInputComponentBlocks {
    return value as WorkflowInputComponentBlocks;
}

