import React, { useCallback, useMemo } from 'react'
import { useUnmount } from 'react-use'

import { Blocks, FilePlus2 } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useVaultV2TourStore } from 'components/common/product-tours/vault-v2-tour'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE,
  RESUME_RUN_BEFORE_ADDING_NEW_FILES_MESSAGE,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { getQuestionsLimit } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

import ErrorsPopover from './errors-popover'
import FilterDropdown from './filter-dropdown'

const AddFileButton = ({
  isAddFilesDisabled,
  addFilesDisabledTooltip,
}: {
  isAddFilesDisabled: boolean
  addFilesDisabledTooltip: string | undefined
}) => {
  const { trackEvent } = useAnalytics()

  const setIsAddFilesDialogOpen = useVaultStore(
    useShallow((state) => state.setIsAddFilesDialogOpen)
  )

  const addFileHandler = () => {
    trackEvent('Vault Review Add Button Clicked')
    setIsAddFilesDialogOpen(true)
  }

  return (
    <Button
      onClick={addFileHandler}
      className="flex items-center gap-1"
      variant="outline"
      size="sm"
      disabled={isAddFilesDisabled}
      tooltip={addFilesDisabledTooltip}
    >
      <Icon icon={FilePlus2} size="small" />
      <p className="truncate text-xs">Add file</p>
    </Button>
  )
}

const ColumnBuilderButton = ({
  isColumnBuilderDisabled,
  columnCreationDisabledTooltip,
}: {
  isColumnBuilderDisabled: boolean
  columnCreationDisabledTooltip: string | undefined
}) => {
  const { trackEvent } = useAnalytics()
  const setIsColumnBuilderDialogOpen = useVaultStore(
    useShallow((state) => state.setIsColumnBuilderDialogOpen)
  )

  return (
    <Button
      className="flex items-center gap-1"
      id="column-builder"
      variant="outline"
      size="sm"
      onClick={() => {
        setIsColumnBuilderDialogOpen(true)
        trackEvent('Vault Review Column Builder Viewed')
      }}
      disabled={isColumnBuilderDisabled}
      tooltip={columnCreationDisabledTooltip}
    >
      <Icon icon={Blocks} size="small" />
      <p className="truncate text-xs">Column builder</p>
    </Button>
  )
}

const VaultDataGridHeader = () => {
  const [currentProject, currentProjectMetadata, exampleProjectIds] =
    useVaultStore(
      useShallow((state) => [
        state.currentProject,
        state.currentProjectMetadata,
        state.exampleProjectIds,
      ])
    )

  const [resetFilterState, clearSelectedRows] = useVaultDataGridFilterStore(
    useShallow((state) => [state.resetFilterState, state.clearSelectedRows])
  )

  const [reviewFilesPerQueryLimit, reviewQuestionsPerQueryLimit] =
    useVaultUsageStore(
      useShallow((state) => [
        state.reviewFilesPerQueryLimit,
        state.reviewQuestionsPerQueryLimit,
      ])
    )
  const [
    gridApi,
    historyItem,
    pendingQueryFileIds,
    pendingQueryQuestions,
    currentPendingColumnId,
    setCurrentPendingColumnId,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.gridApi,
      state.historyItem,
      state.pendingQueryFileIds,
      state.pendingQueryQuestions,
      state.currentPendingColumnId,
      state.setCurrentPendingColumnId,
    ])
  )

  const isTourActive = useVaultV2TourStore(
    useShallow((state) => state.isTourActive)
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const { hasEmptyCells } = useRunReview()
  const [isQueryLoading] = useVaultQueryDetailStore(
    useShallow((s) => [s.isQueryLoading])
  )
  const canResumeQuery = !isQueryLoading && hasEmptyCells

  const pendingFileIds = pendingQueryFileIds ?? []
  const pendingQuestions = pendingQueryQuestions ?? []

  const reviewEvent = historyItem as ReviewHistoryItem
  const filesLimit = reviewEvent?.filesLimit ?? reviewFilesPerQueryLimit
  const numQueryFiles = reviewEvent?.numFiles ?? 0
  const numQueryQuestions = reviewEvent?.numQuestions ?? 0
  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const questionsLimit = getQuestionsLimit(
    reviewEvent?.questionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const totalFileIds = numQueryFiles + pendingFileIds.length
  const totalQuestions = numQueryQuestions + pendingQuestions.length

  const isAddFilesDisabled =
    !canCurrentUserEditProject ||
    !!(filesLimit && totalFileIds >= filesLimit) ||
    totalFileIds ===
      currentProjectMetadata.completedFiles -
        currentProjectMetadata.failedFiles ||
    canResumeQuery ||
    !!currentPendingColumnId

  const addFilesDisabledTooltip = useMemo(() => {
    if (!canCurrentUserEditProject)
      return 'You do not have permission to add files'
    if (filesLimit && totalFileIds >= filesLimit)
      return 'You have reached the maximum number of files for this query'
    if (
      totalFileIds ===
      currentProjectMetadata.completedFiles - currentProjectMetadata.failedFiles
    )
      return 'All processed files have already been added to this query'
    if (canResumeQuery) return RESUME_RUN_BEFORE_ADDING_NEW_FILES_MESSAGE
    return undefined
  }, [
    canCurrentUserEditProject,
    totalFileIds,
    currentProjectMetadata.completedFiles,
    currentProjectMetadata.failedFiles,
    filesLimit,
    canResumeQuery,
  ])

  const isColumnCreationDisabled =
    (!canCurrentUserEditProject ||
      totalFileIds === 0 ||
      totalQuestions >= questionsLimit ||
      canResumeQuery ||
      !!currentPendingColumnId) &&
    !isTourActive

  const columnCreationDisabledTooltip = useMemo(() => {
    if (!canCurrentUserEditProject)
      return 'You do not have permission to add columns'
    if (totalFileIds === 0)
      return 'You must add files to the query before adding columns.'
    if (totalQuestions >= questionsLimit)
      return 'You have reached the maximum number of questions for this query'
    if (canResumeQuery) return RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE
    return undefined
  }, [
    canCurrentUserEditProject,
    totalFileIds,
    totalQuestions,
    questionsLimit,
    canResumeQuery,
  ])

  const clearFilters = useCallback(() => {
    resetFilterState()
    gridApi?.setFilterModel(null)
  }, [resetFilterState, gridApi])

  useUnmount(() => {
    resetFilterState()
    clearSelectedRows()
    setCurrentPendingColumnId(null)
  })

  return (
    <div className="flex max-h-[49px] min-h-[49px] w-full shrink-0 items-center justify-between gap-2 px-4 py-2">
      <div className="flex items-center gap-2">
        <FilterDropdown clearFilters={clearFilters} />
        <div className="min-h-6 w-px border-r border-primary" />
        <AddFileButton
          isAddFilesDisabled={isAddFilesDisabled}
          addFilesDisabledTooltip={addFilesDisabledTooltip}
        />
        <ColumnBuilderButton
          isColumnBuilderDisabled={isColumnCreationDisabled}
          columnCreationDisabledTooltip={columnCreationDisabledTooltip}
        />
      </div>
      <ErrorsPopover />
    </div>
  )
}

export default VaultDataGridHeader
