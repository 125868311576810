import * as React from 'react'

import { Button } from 'components/ui/button'

interface WorkflowInputProps {
  children?: React.ReactNode
  footer?: string
  submit?: {
    disabled?: boolean
    onClick: () => void
  }
}

export const WorkflowInputHeader = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <div className="rounded-md bg-primary px-4 py-[14px] text-sm font-medium">
      {children}
    </div>
  )
}

const WorkflowInput: React.FC<WorkflowInputProps> = ({
  children,
  footer,
  submit,
}) => {
  return (
    <div className="mx-auto w-full py-6">
      <div className="space-y-2 rounded-md bg-accent p-2">
        {children}
        {!!footer && (
          <div className="ml-2 flex justify-between align-middle">
            <p className="mt-1 text-secondary">{footer}</p>
            {!!submit && (
              <Button onClick={submit.onClick} disabled={submit.disabled}>
                Send
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default WorkflowInput
