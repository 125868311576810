import React, { useState } from 'react'

import { LaptopIcon, LucideIcon } from 'lucide-react'

import { IntegrationType } from 'openapi/models/IntegrationType'

import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'

import { useAuthUser } from './auth-context'

interface AddMoreFilesProps {
  onUploadFromComputer: () => void
  onUploadFromIntegration: (integrationType: IntegrationType) => void
  side?: 'bottom' | 'top'
  align?: 'start' | 'end'
  buttonText?: string
  leadingIcon?: LucideIcon
  buttonSize?: 'sm' | 'default'
  buttonVariant?: 'outline' | 'default' | 'ghost'
  disabled?: boolean
}

const AddMoreFiles: React.FC<AddMoreFilesProps> = ({
  onUploadFromComputer,
  onUploadFromIntegration,
  side = 'bottom',
  align = 'end',
  buttonText = 'Add more files',
  leadingIcon,
  buttonSize = 'sm',
  buttonVariant = 'outline',
  disabled = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const authUser = useAuthUser()
  const integrationDefinitionsEnabled = Object.entries(
    IntegrationDefinitions
  ).filter(([, definition]) => definition.available(authUser))

  return integrationDefinitionsEnabled.length > 0 ? (
    <DropdownMenu onOpenChange={setIsDropdownOpen} open={isDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          className="pointer-events-auto whitespace-nowrap"
          size={buttonSize}
          variant={buttonVariant}
          disabled={disabled}
        >
          {leadingIcon && <Icon icon={leadingIcon} className="mr-1" />}
          {buttonText}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={align} side={side} className="w-52">
        <DropdownMenuItem onClick={onUploadFromComputer}>
          <Icon icon={LaptopIcon} className="mr-2" />
          From your computer
        </DropdownMenuItem>
        {integrationDefinitionsEnabled.map(([integrationType, definition]) => (
          <DropdownMenuItem
            key={integrationType}
            onClick={() =>
              onUploadFromIntegration(integrationType as IntegrationType)
            }
          >
            {definition.LogoComponent}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Button
      className="pointer-events-auto whitespace-nowrap"
      size={buttonSize}
      variant={buttonVariant}
      onClick={onUploadFromComputer}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  )
}

export default AddMoreFiles
