import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { PermissionLevel } from 'types/sharing'

import { doesUserHavePermission } from 'utils/sharing-helpers'
import { cn } from 'utils/utils'

import VaultFileExplorerToolbelt from './components/file-explorer/vault-file-explorer-toolbelt'
import VaultRecentQueries from './components/vault-recent-queries'
import VaultSectionBreadcrumb from './components/vault-section-breadcrumb'
import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { useVaultV2TourStore } from 'components/common/product-tours/vault-v2-tour'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import VaultFileExplorerActions from 'components/vault/components/file-explorer/vault-file-explorer-actions'
import VaultFileExplorerBreadcrumbs from 'components/vault/components/file-explorer/vault-file-explorer-breadcrumbs'
import VaultProgress from 'components/vault/components/vault-progress'

import useSharingPermissions from './hooks/use-sharing-permissions'
import { useVaultFileExplorerStore } from './utils/vault-file-explorer-store'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultWorkflowList from './workflows/vault-workflow-list'

const VaultProjectDetail = () => {
  const { projectId } = useParams()
  const userInfo = useAuthUser()

  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )
  const [
    exampleProjectIds,
    currentProjectMetadata,
    deleteVaultFolders,
    setError,
  ] = useVaultStore(
    useShallow((state) => [
      state.exampleProjectIds,
      state.currentProjectMetadata,
      state.deleteVaultFolders,
      state.setError,
    ])
  )

  const [currentUserPermissionByProjectId, permissionsByProjectId] =
    useVaultSharingStore(
      useShallow((s) => [
        s.currentUserPermissionByProjectId,
        s.permissionsByProjectId,
      ])
    )

  const currentUserPermissionForProject = projectId
    ? currentUserPermissionByProjectId[projectId]
    : undefined

  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )

  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )

  const isTourActive = useVaultV2TourStore((state) => state.isTourActive)

  useEffect(() => {
    const isCurrentUserNotProjectOwner =
      currentProjectMetadata.userId.trim() !== '' &&
      currentProjectMetadata.userId !== userInfo.dbId

    const isVaultSharingEnabled = userInfo.IsVaultViewSharesUser
    if (isVaultSharingEnabled) {
      // if sharing is enabled, only show error after fetching current user's project permission level
      if (
        isCurrentUserNotProjectOwner &&
        !isExampleProject &&
        projectId &&
        currentUserPermissionForProject &&
        !doesUserHavePermission({
          currentPermissionLevel: currentUserPermissionForProject,
          requiredPermissionLevel: PermissionLevel.VIEW,
          isOwner: currentProjectMetadata.userId === userInfo.dbId,
        })
      ) {
        deleteVaultFolders([projectId], projectId)
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    } else {
      if (isCurrentUserNotProjectOwner && !isExampleProject) {
        deleteVaultFolders([projectId!], projectId)
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    }
  }, [
    currentProjectMetadata,
    userInfo,
    deleteVaultFolders,
    setError,
    isExampleProject,
    permissionsByProjectId,
    currentUserPermissionForProject,
    projectId,
  ])

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId,
  })

  const shouldShowWorkflows =
    (doesCurrentUserHaveEditPermission && !isExampleProject) || isTourActive

  return (
    <AppMain hasContainer>
      <div className={cn('flex flex-col gap-8')}>
        {shouldShowWorkflows && <VaultWorkflowList />}
        <VaultRecentQueries />
        <div>
          <VaultSectionBreadcrumb
            title={<VaultFileExplorerBreadcrumbs />}
            trailingActions={[
              <VaultFileExplorerActions key="file-explorer-actions" />,
            ]}
          />
          <VaultProgress />
          <VaultFileExplorer
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            projectId={projectId}
          />
        </div>
        <VaultFileExplorerToolbelt
          className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
            'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
          })}
        />
      </div>
    </AppMain>
  )
}

export default VaultProjectDetail
