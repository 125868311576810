import React from 'react'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Checkbox } from 'components/ui/checkbox'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'
import useWorkflowTaxonomyGroupSelection, {
  WorkflowTaxonomyGroup,
} from 'components/workflows/workflow/utils/use-workflow-taxonomy-selector-group'

interface Props<T> {
  group: T
  onToggleTaxonomy: (value: string, isExpanded: boolean) => void
  selectedTaxonomies: T[]
  setSelectedTaxonomies: (taxonomies: T[]) => void
  checkboxDisabled: (title: string) => boolean
  disabledTooltipText: string
}

export const DiligenceTaxonomySelectorGroup = <
  T extends WorkflowTaxonomyGroup,
>({
  group,
  onToggleTaxonomy,
  selectedTaxonomies,
  setSelectedTaxonomies,
  checkboxDisabled,
  disabledTooltipText,
}: Props<T>) => {
  const groupValue = group.title
  const {
    areAllRowsSelected,
    onTaxonomyRowCheckedChange,
    toggleSelectAllTrigger,
    numberSelected,
  } = useWorkflowTaxonomyGroupSelection({
    group,
    selectedTaxonomies,
    setSelectedTaxonomies,
    onToggleTaxonomy,
  })

  const topLevelCheckbox = (
    <Checkbox
      data-testid="select-all-checkbox"
      className="self-center"
      onClick={toggleSelectAllTrigger}
      checked={numberSelected > 0}
      isIndeterminate={!areAllRowsSelected && numberSelected > 0}
      disabled={checkboxDisabled(group.title)}
    />
  )

  const rowComponents = group.rows.map((taxonomyRow) => {
    const checkbox = (
      <Checkbox
        key={taxonomyRow}
        className="space-x-3"
        label={taxonomyRow}
        value={taxonomyRow}
        disabled={checkboxDisabled(group.title)}
        checked={selectedTaxonomies.some(
          (groups) =>
            groups.title === group.title && groups.rows.includes(taxonomyRow)
        )}
        onClick={() => onTaxonomyRowCheckedChange(group.title, taxonomyRow)}
        id={taxonomyRow}
        data-testid="taxonomy-row-checkbox"
      />
    )

    if (checkboxDisabled(group.title)) {
      return (
        <TooltipProvider delayDuration={350} key={taxonomyRow}>
          <Tooltip>
            <TooltipTrigger>{checkbox}</TooltipTrigger>
            <TooltipContent>{disabledTooltipText}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    } else {
      return checkbox
    }
  })

  return (
    <AccordionItem
      value={groupValue}
      data-testid="accordion-item"
      className="last:border-b-0"
    >
      <AccordionTrigger
        data-testid="accordion-title"
        className="group h-auto flex-row items-start px-4 transition hover:no-underline"
      >
        <div className="flex flex-col space-y-3">
          <div className="flex items-center space-x-3">
            {checkboxDisabled(group.title) ? (
              <TooltipProvider delayDuration={350}>
                <Tooltip>
                  <TooltipTrigger>{topLevelCheckbox}</TooltipTrigger>
                  <TooltipContent>{disabledTooltipText}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              topLevelCheckbox
            )}
            <h2 className="text-left text-sm transition group-hover:underline">
              {group.title}
            </h2>
          </div>
          <div className="text-left text-xs text-muted">
            {numberSelected} of {group.rows.length} Selected
          </div>
          {group.description && (
            <p className="mt-1 text-left text-xs">{group.description}</p>
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent
        data-testid="accordion-content"
        className="my-2 grid gap-1 px-4"
      >
        {rowComponents}
      </AccordionContent>
    </AccordionItem>
  )
}
