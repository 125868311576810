import React, { useState, useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { RotateCw, Trash } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { VaultFile } from 'openapi/models/VaultFile'

import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  clearReviewErrors,
  getQuestionsLimit,
  isError,
  retryReviewErrors,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

const ErrorsPopover = () => {
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()

  const [
    isRunButtonLoading,
    historyItem,
    setQueryId,
    setIsRunButtonLoading,
    setHistoryItem,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.isRunButtonLoading,
      state.historyItem,
      state.setQueryId,
      state.setIsRunButtonLoading,
      state.setHistoryItem,
    ])
  )
  const [currentProjectMetadata] = useVaultStore(
    useShallow((state) => [state.currentProjectMetadata])
  )

  const [reviewQuestionsPerQueryLimit, reviewFilesPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.reviewQuestionsPerQueryLimit,
        s.reviewFilesPerQueryLimit,
      ])
    )

  const reviewEvent = historyItem as ReviewHistoryItem
  const currentProjectFilesById = useMemo(() => {
    return new Map(
      currentProjectMetadata.descendantFiles?.map((file) => [file.id, file]) ??
        []
    )
  }, [currentProjectMetadata])
  const projectFileIds = useMemo(() => {
    return new Set<string>(currentProjectFilesById.keys())
  }, [currentProjectFilesById])
  const processedFileIds = useMemo(() => {
    return reviewEvent?.processedFileIds ?? []
  }, [reviewEvent])
  const suppressedFileIds = useMemo(() => {
    return reviewEvent?.suppressedFileIds ?? []
  }, [reviewEvent])

  const filesWithErrors = useMemo(() => {
    if (!reviewEvent) return []
    return (
      Object.keys(reviewEvent.errors ?? {})
        .filter((id) =>
          isError({ projectFileIds, processedFileIds, suppressedFileIds, id })
        )
        .map((id) => currentProjectFilesById.get(id))
        .filter(Boolean) as VaultFile[]
    ).sort((a, b) => a.name.localeCompare(b.name))
  }, [
    reviewEvent,
    currentProjectFilesById,
    projectFileIds,
    processedFileIds,
    suppressedFileIds,
  ])

  const queryQuestionsLimit =
    reviewEvent?.questionsLimit ?? reviewQuestionsPerQueryLimit
  const questionsLimit = getQuestionsLimit(
    queryQuestionsLimit,
    reviewQuestionsPerQueryLimit
  )
  const filesLimit = reviewEvent?.filesLimit ?? reviewFilesPerQueryLimit

  const clearErrors = async (fileIds?: string[]) => {
    await clearReviewErrors({
      queryId: reviewEvent.id,
      fileIds:
        fileIds ??
        reviewEvent.fileIds.filter((id) =>
          filesWithErrors.some((f) => f.id === id)
        ),
      historyItem: reviewEvent,
      setIsRunButtonLoading,
      setHistoryItem,
      queryClient,
    })
  }

  const retryErrors = async (fileIds?: string[]) => {
    setIsOpen(false)
    const allFileIds = reviewEvent.fileIds
    const queryFileIds = fileIds ?? allFileIds
    await retryReviewErrors({
      queryFileIds,
      reviewEvent,
      currentProjectMetadata,
      questionsLimit,
      filesLimit,
      setIsRunButtonLoading,
      setQueryId,
      recordQuerySubmitted,
      queryClient,
    })
  }

  if (filesWithErrors.length === 0) {
    return null
  }

  const isDisabled = isRunButtonLoading

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm">
          <AlertIcon size="small" className="mr-1" />
          <p className="text-xs">
            {filesWithErrors.length} error
            {filesWithErrors.length > 1 ? 's' : ''}
          </p>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-96 p-0">
        <ScrollArea maxHeight="max-h-96">
          {filesWithErrors.map((file) => (
            <div
              key={file.id}
              className="flex items-center justify-between gap-2 border-b px-4 py-2 last:border-b-0"
            >
              <p key={file.id} className="truncate text-start text-xs">
                {file.name}
              </p>
              <div className="flex shrink-0 items-center gap-2">
                <Button
                  variant="ghost"
                  size="xsIcon"
                  disabled={isDisabled}
                  aria-label="clear errors"
                  tooltip={
                    isDisabled
                      ? 'You can clear the error after the query is finished processing'
                      : undefined
                  }
                  onClick={() => clearErrors([file.id])}
                >
                  <Icon icon={Trash} size="small" />
                </Button>
                <Button
                  variant="ghost"
                  size="xsIcon"
                  disabled={isDisabled}
                  aria-label="Retry"
                  tooltip={
                    isDisabled
                      ? 'You can retry after the query is finished processing'
                      : undefined
                  }
                  onClick={() => retryErrors([file.id])}
                >
                  <Icon icon={RotateCw} size="small" />
                </Button>
              </div>
            </div>
          ))}
        </ScrollArea>
        <div className="flex justify-end gap-2 border-t px-4 py-2">
          <Button
            variant="ghost"
            size="sm"
            className="text-xs"
            disabled={isDisabled}
            tooltip={
              isDisabled
                ? 'You can clear errors after the query is finished processing'
                : undefined
            }
            onClick={() => clearErrors()}
          >
            <Icon icon={Trash} size="small" className="mr-1" />
            Clear errors
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="text-xs"
            disabled={isDisabled}
            tooltip={
              isDisabled
                ? 'You can retry after the query is finished processing'
                : undefined
            }
            onClick={() => retryErrors()}
          >
            <Icon icon={RotateCw} size="small" className="mr-1" />
            Retry all
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default ErrorsPopover
