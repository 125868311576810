import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import { PlusIcon, Trash } from 'lucide-react'
import pluralize from 'pluralize'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import {
  createQueryCapRule,
  deleteQueryCapRule,
  levelToDisplayString,
  QueryCapRule,
  timeFrameToDisplayString,
  unitLevelToDisplayString,
  updateQueryCapRule,
} from 'models/query-cap-rule'
import { UserInfo } from 'models/user-info'
import { Workspace } from 'models/workspace'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'

import { getTableDateString } from 'utils/date-utils'
import { TaskType } from 'utils/task'
import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { displayWarningMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import Icon from 'components/ui/icon/icon'

import QueryCapDialog from './query-cap-dialog'

interface QueryCapRulesTableProps {
  queryCapRules: QueryCapRule[]
  workspace: Workspace
  userInfo?: UserInfo
}

const QueryCapRulesTable = ({
  queryCapRules,
  workspace,
  userInfo,
}: QueryCapRulesTableProps) => {
  const authUser = useAuthUser()
  const queryClient = useQueryClient()
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const canEdit = authUser.isWriteEngInternal

  const [filter, setFilter] = useState<string>('')
  const [isLoading] = useState<boolean>(false)
  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])
  const [queryCapModalOpen, setQueryCapModalOpen] = useState<boolean>(false)
  const [editQueryCapRule, setEditQueryCapRule] = useState<QueryCapRule | null>(
    null
  )

  const columns: ColumnDef<QueryCapRule>[] = [
    {
      accessorKey: 'taskType',
      id: 'taskType',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Task type" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">
          {taskLabelLookup[getValue() as TaskType]}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'level',
      id: 'level',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Level" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">
          {levelToDisplayString[getValue() as QueryCapRuleLevel]}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'timeFrame',
      id: 'timeFrame',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Time frame" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">
          {timeFrameToDisplayString[getValue() as QueryCapRuleTimeFrame]}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'unitLevel',
      id: 'unitLevel',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Unit level" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">
          {unitLevelToDisplayString[getValue() as QueryCapRuleUnitLevel]}
        </div>
      ),
    },
    {
      accessorKey: 'value',
      id: 'value',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Value" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      id: 'createdAt',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Date added"
            tooltipText="Date when this rule was added"
          />
        )
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'userId',
      id: 'userId',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Source" />
      },
      cell: ({ getValue }) => (
        <Badge variant="secondary" className="ml-3 text-sm">
          {getValue() ? 'User' : 'Workspace'}
        </Badge>
      ),
    },
    {
      accessorKey: 'actions',
      id: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        return (
          <Button
            variant="ghost"
            size="xsIcon"
            disabled={userInfo && row.original.userId !== userInfo.dbId}
            aria-label={`Remove ${row.original.taskType}`}
            onClick={async (event) => {
              event.stopPropagation()
              await onQueryCapRemove(row.original.id)
            }}
          >
            <Icon icon={Trash} className="stroke-destructive" />
          </Button>
        )
      },
    },
  ]

  const hideColumns = !canEdit ? ['actions'] : []
  const table = useReactTable({
    data: queryCapRules,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: _.mapValues(_.keyBy(hideColumns), () => false),
    },
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${
    table.getFilteredRowModel().rows.length
  } query cap ${pluralize('rule', table.getFilteredRowModel().rows.length)}`

  const queryKey = userInfo
    ? [HarvQueryKeyPrefix.QueryCapRulesQuery, userInfo.dbId, workspace.id]
    : [HarvQueryKeyPrefix.QueryCapRulesQuery, workspace.id]

  const onQueryCapAdd = async (queryCapRule: QueryCapRule) => {
    await createQueryCapRule(queryCapRule)
    await queryClient.invalidateQueries({ queryKey: queryKey })
  }

  const onQueryCapEdit = async (queryCapRule: QueryCapRule) => {
    await updateQueryCapRule(queryCapRule)
    await queryClient.invalidateQueries({ queryKey: queryKey })
  }

  const onQueryCapRemove = async (id: string) => {
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this query cap rule?'
    )
    if (!userConfirmed) {
      return
    }
    await deleteQueryCapRule(id)
    await queryClient.invalidateQueries({ queryKey: queryKey })
  }

  return (
    <>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
        className="mb-2"
      >
        <div className="mr-2 flex grow items-center justify-between gap-2">
          <p>
            {canEdit ? 'Manage' : 'View'} query caps for{' '}
            <b>{userInfo ? userInfo.id : workspace.clientName}</b>
            {canEdit && (
              <>
                , add a new one, or edit an existing one by clicking on the rule
                with source ‘{userInfo ? 'User' : 'Workspace'}’ below
              </>
            )}
          </p>
          {canEdit && (
            <Button
              variant="default"
              className="shrink-0"
              onClick={() => {
                setEditQueryCapRule(null)
                setQueryCapModalOpen(true)
              }}
            >
              <Icon icon={PlusIcon} className="mr-1" />
              Add
            </Button>
          )}
        </div>
      </DataTableHeader>
      <DataTable
        table={table}
        className={cn({ 'min-h-[420px]': !isLoading })}
        useVirtual={queryCapRules.length > 100}
        isLoading={isLoading}
        emptyStateText="No query cap rules found"
        onRowClick={
          canEdit
            ? (row) => {
                if (userInfo && row.original.userId !== userInfo.dbId) {
                  displayWarningMessage(
                    'You can only edit the rule with source ‘User’',
                    10
                  )
                  return
                }
                setEditQueryCapRule(row.original)
                setQueryCapModalOpen(true)
              }
            : undefined
        }
      />
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
      <QueryCapDialog
        workspace={workspace}
        queryCapModalOpen={queryCapModalOpen}
        setQueryCapModalOpen={setQueryCapModalOpen}
        editQueryCapRule={editQueryCapRule}
        onQueryCapAdd={onQueryCapAdd}
        onQueryCapEdit={onQueryCapEdit}
        userInfo={userInfo}
      />
    </>
  )
}

export default QueryCapRulesTable
