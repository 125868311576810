import React from 'react'

import _ from 'lodash'

import { Event } from 'models/event'
import Services from 'services'

import { SafeRecord } from 'utils/safe-types'
import { getDocumentCount } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import {
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import Combobox from 'components/ui/combobox/combobox'

interface DocumentCountOption {
  label: string
  value: string
  // lowerBound <= number < upperBound
  range: { lowerBound: number; upperBound: number }
}

const documentCountOptions = [
  { label: '0', value: '0', range: { lowerBound: 0, upperBound: 1 } },
  { label: '1', value: '1', range: { lowerBound: 1, upperBound: 2 } },
  { label: '2-5', value: '2-5', range: { lowerBound: 2, upperBound: 6 } },
  { label: '6+', value: '6+', range: { lowerBound: 6, upperBound: Infinity } },
]

const documentCountValueToOption = documentCountOptions.reduce(
  (acc, option) => ({ ...acc, [option.value]: option }),
  {} as SafeRecord<string, DocumentCountOption>
)

interface DocumentCountFilterProps extends AnalyticsContextType {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  label?: string
}

const DocumentCountFilter = (props: DocumentCountFilterProps) => {
  const { filterKey, value, setValue, trackEvent, label } = props
  const selectedValue = value || ''
  const selectedDocumentCountRange =
    documentCountValueToOption[selectedValue]?.range

  const render: React.FC = () => (
    <Combobox
      key={filterKey}
      value={selectedValue}
      options={documentCountOptions}
      setValue={(value) => {
        setValue(value)
        Services.HoneyComb.Record({
          metric: 'ui.history_document_count_filter',
          document_count_range: value,
        })
        trackEvent(FILTER_CHANGED_EVENT_NAME, {
          entity_name: 'history table',
          field_changed: 'document count',
          document_count_range: value,
        })
      }}
      inputPlaceholder="Search"
      defaultText="Select"
      className={cn('w-40', {
        'text-muted': _.isEmpty(selectedValue),
      })}
      align="end"
      ariaLabel={label}
    />
  )

  const filterLogic = (record: Event) => {
    if (_.isNil(selectedDocumentCountRange)) {
      return true
    }
    const documentCount = getDocumentCount(record)
    const { lowerBound, upperBound } = selectedDocumentCountRange
    return _.inRange(documentCount, lowerBound, upperBound)
  }

  return { filterKey, displayName: 'Document count', render, filterLogic }
}

export default DocumentCountFilter
