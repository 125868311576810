import * as Sentry from '@sentry/browser'
import { Query, QueryKey } from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import Services from 'services'
import { Maybe } from 'types'

import { parseIsoString } from 'utils/utils'

import { ReviewEvent } from 'components/vault/utils/vault'
import { mapReviewEventToEventV1Metadata } from 'components/vault/utils/vault-helpers'

const FetchVaultHistoryItemV2 = async ({
  id,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
}: {
  id: Maybe<string>
  throwOnError?: boolean
  maxRetryCount?: number
  skipSources?: boolean
}): Promise<ReviewEvent | null> => {
  if (!id) {
    return null
  }

  const searchParams = new URLSearchParams()
  if (skipSources) {
    searchParams.append('skip_sources', 'true')
  }

  const searchParamsString = searchParams.toString()

  return Services.Backend.Get<ReviewEvent>(
    `vault/v2/history/${id}${
      searchParamsString ? `?${searchParamsString}` : ''
    }`,
    {
      throwOnError: true,
      maxRetryCount: maxRetryCount,
    }
  )
    .then((reviewEvent) => {
      const metadata = mapReviewEventToEventV1Metadata(reviewEvent)
      return {
        ...reviewEvent,
        id: reviewEvent.eventId.toString(), // This is a number in the backend
        userId: reviewEvent.eventCreatorEmail, // userId of the event v1 is the creator's email
        status: reviewEvent.eventStatus,
        query:
          reviewEvent.runs.find((run) => run.runType === ReviewEventRunType.NEW)
            ?.query ?? '',
        response: '',
        kind: reviewEvent.eventKind,
        created: parseIsoString(reviewEvent.eventCreatedAt),
        updatedAt: parseIsoString(reviewEvent.eventUpdatedAt),
        metadata: metadata,
        ...metadata,
      } as ReviewEvent
    })
    .catch((e) => {
      if (throwOnError) {
        throw e
      } else {
        console.warn('Failed to fetch history item', { e, id })
        return null
      }
    })
}

const vaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
}: {
  id: Maybe<string>
  vaultFolderId: string
  throwOnError: boolean
  maxRetryCount?: number
  skipSources?: boolean
}) => ({
  queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, id, skipSources],
  queryFn: async () => {
    const historyItem = await FetchVaultHistoryItemV2({
      id,
      throwOnError,
      maxRetryCount,
      skipSources,
    })
    if (historyItem && historyItem.vaultFolderId !== vaultFolderId) {
      throw new Error(
        `History item ${historyItem.id} does not belong to the vault project ${vaultFolderId}`
      )
    }
    return historyItem
  },
  initialData: null,
})

export const useVaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  isEnabled = true,
  throwOnError = false,
  refetchInterval,
  skipSources = false,
}: {
  id: Maybe<string>
  vaultFolderId: string
  isEnabled?: boolean
  throwOnError?: boolean
  refetchInterval?: (
    query: Query<ReviewEvent | null, Error, ReviewEvent | null, QueryKey>
  ) => number | false | undefined
  skipSources?: boolean
}) => {
  const {
    isPending,
    error,
    data: historyItem,
    isLoading,
    isFetching,
    isFetched,
  } = useWrappedQuery({
    enabled: !!id && isEnabled,
    refetchInterval,
    refetchOnWindowFocus: false,
    // Set maxRetryCount to 0 because we will handle retries in the useQuery hook
    ...vaultHistoryItemQuery({
      id,
      vaultFolderId,
      throwOnError,
      maxRetryCount: 0,
      skipSources,
    }),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { historyItem, isPending, isLoading, isFetching, isFetched, error }
}
