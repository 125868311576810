import React, { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { Instance } from 'pspdfkit'

import { EventKind } from 'openapi/models/EventKind'
import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'
import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { feedbackClosedState } from 'utils/feedback'
import { createAcceptObject, mbToBytes, mbToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { HarveySocketSetter } from 'utils/use-harvey-socket'

import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { FeedbackResult } from 'components/common/feedback/feedback-with-comments'
import GoogleDriveButton from 'components/common/google-drive-button'
import PdfViewer from 'components/common/pdf-viewer/pdf-viewer'
import SharepointButton from 'components/common/sharepoint-button'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'

const MAX_FILES = 1
export const MAX_FILE_SIZE_MB = 20

interface WorkflowFileInputProps {
  store: () => {
    activeDocument: Maybe<UploadedFile | File>
    setActiveDocument: (document: null) => void
    setFeedback: (feedback: FeedbackResult | null) => void
    setTask: HarveySocketSetter
    isLoading: boolean
  }
  canMaximize?: boolean
  children: React.ReactNode
  eventKind: EventKind
  handleFile: (file: File, source: FileUploadSource) => void | Promise<void>
  pspdfInstanceRef: React.MutableRefObject<Maybe<Instance>>
  fileTypes?: FileType[]
  maxFileSizeMb?: number
  pdfViewerFooter?: React.ReactNode
  dropzoneDescription?: React.ReactNode
  onClear?: () => void
  previewComponent?: React.ReactNode
}

/* PDF Dropzone and PDF Viewer w/ varying input component */
const WorkflowFileInput: React.FC<WorkflowFileInputProps> = ({
  canMaximize = true,
  pspdfInstanceRef,
  store,
  children,
  eventKind,
  handleFile,
  fileTypes = [FileType.PDF, FileType.WORD],
  maxFileSizeMb = MAX_FILE_SIZE_MB,
  pdfViewerFooter,
  dropzoneDescription,
  onClear,
  previewComponent,
}) => {
  const [isMaximized, setIsMaximized] = React.useState(false)
  const { activeDocument, setActiveDocument, setFeedback, isLoading } = store()
  const [isFileLoading, setIsFileLoading] = React.useState(false)
  const authUser = useAuthUser()

  const onDocumentClear = () => {
    onClear?.()
    setActiveDocument(null)
    setFeedback(feedbackClosedState)
    setIsMaximized(false)
  }

  const recordFileDrop = useDropzoneTrack(eventKind)

  const onFileDrop = useCallback(
    async (acceptedFiles: File[], source: FileUploadSource) => {
      if (!acceptedFiles.length) return
      setIsFileLoading(true)
      recordFileDrop(acceptedFiles)
      try {
        const file = acceptedFiles[0]
        await handleFile(file, source)
      } catch (e) {
        displayErrorMessage(
          'An error occurred while uploading your file. Please try again.'
        )
      } finally {
        setIsFileLoading(false)
      }
    },
    [handleFile, recordFileDrop]
  )

  const onWorkflowFileDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      source: FileUploadSource
    ) => {
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: 0,
        maxFiles: MAX_FILES,
        acceptedFileTypes: fileTypes,
        maxFileSize: mbToBytes(maxFileSizeMb),
        maxZipFileSize: 0,
        handleAcceptedFiles: (files) => onFileDrop(files, source),
      })
    },
    [onFileDrop, fileTypes, maxFileSizeMb]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: createAcceptObject(fileTypes),
    onDrop: (acceptedFiles, fileRejections) =>
      onWorkflowFileDrop(
        acceptedFiles,
        fileRejections,
        FileUploadSource.COMPUTER
      ),
    maxSize: mbToBytes(maxFileSizeMb),
    maxFiles: MAX_FILES,
  })

  const onSharepointFileDrop = useCallback(
    (acceptedFiles: File[]) =>
      onWorkflowFileDrop(acceptedFiles, [], FileUploadSource.SHAREPOINT),
    [onWorkflowFileDrop]
  )

  const onGoogleDriveFileDrop = useCallback(
    (acceptedFiles: File[]) =>
      onWorkflowFileDrop(acceptedFiles, [], FileUploadSource.GOOGLE_DRIVE),
    [onWorkflowFileDrop]
  )

  const isFileSelected = !!activeDocument
  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(authUser)
  const googleDriveEnabled =
    IntegrationDefinitions[IntegrationType.GOOGLE_DRIVE].available(authUser)

  const integrations = []
  if (sharepointEnabled) {
    integrations.push(
      <SharepointButton
        onUploadFromSharepoint={onSharepointFileDrop}
        acceptedFileTypes={fileTypes}
      />
    )
  }
  if (googleDriveEnabled) {
    integrations.push(
      <GoogleDriveButton
        onUploadFromGoogleDrive={onGoogleDriveFileDrop}
        acceptedFileTypes={fileTypes}
      />
    )
  }

  return (
    <div className="flex h-full flex-col space-y-2">
      {isFileSelected ? (
        previewComponent ? (
          previewComponent
        ) : (
          <div className="flex h-full flex-col">
            <PdfViewer
              document={activeDocument ?? null}
              onClear={onDocumentClear}
              canClear={!isLoading}
              pspdfInstanceRef={pspdfInstanceRef}
              canMaximize={canMaximize}
              isMaximized={isMaximized}
              setIsMaximized={setIsMaximized}
              footerElement={pdfViewerFooter}
            />
          </div>
        )
      ) : (
        <div className="h-full">
          <Dropzone
            dropzone={{ getRootProps, getInputProps }}
            isLoading={isFileLoading}
            description={
              dropzoneDescription ?? (
                <DropzoneDescription
                  fileTypes={fileTypes}
                  maxSize={mbToReadable(maxFileSizeMb)}
                />
              )
            }
            integrations={integrations}
          />
        </div>
      )}
      {isMaximized ? null : <div>{children}</div>}
    </div>
  )
}

export default WorkflowFileInput
