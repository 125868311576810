import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useInterval, useMount } from 'react-use'
import { useUnmount } from 'react-use'

import {
  ColumnDef,
  PaginationState,
  Row,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { HTTPError } from 'ky'
import { isNil } from 'lodash'
import _ from 'lodash'
import { Search, Info } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { EventKind } from 'openapi/models/EventKind'
import { TagScope } from 'openapi/models/TagScope'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { compareFileName } from 'utils/file-utils'
import { displayErrorMessage, displayWarningMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter, {
  DataTablePageSizes,
} from 'components/ui/data-table/data-table-footer'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import Icon from 'components/ui/icon/icon'
import Skeleton from 'components/ui/skeleton'
import {
  VaultItemStatus,
  VaultNameCell,
  VaultTimeCell,
  VaultContentTypeCell,
  VaultSizeCell,
  VaultMenuCell,
  VaultLocationCell,
  VaultSelectCell,
  VaultClassificationCell,
  VaultSelectHeader,
} from 'components/vault/components/file-explorer/vault-cells'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import {
  VaultItem,
  VaultItemType,
  folderIdSearchParamKey,
  projectsPath,
  filesPath,
  REMOVE_PARAMS,
  queryIdSearchParamKey,
  VaultItemWithIndex,
  DOCUMENT_CLASSIFICATION_TOOLTIP_TEXT,
} from 'components/vault/utils/vault'
import { FetchVaultFolder } from 'components/vault/utils/vault-fetcher'
import { SemanticSearch } from 'components/vault/utils/vault-fetcher'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  fetchProjectMetadata,
  getSelectedFiles,
} from 'components/vault/utils/vault-helpers'
import {
  filterAndSortFolders,
  getQueryUpdatedAt,
  folderAsItem,
  fileAsItem,
  getContentTypeDisplayString,
} from 'components/vault/utils/vault-helpers'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import {
  VaultSocketTask,
  useVaultStore,
} from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

export const DEFAULT_FILES_WARNING_THRESHOLD = 1000
const MISMATCH_MESSAGE =
  'The documents that you’ve selected don’t match the recommended document type for this workflow.'

/*
 * UI component for warnings about too many files
 */
const WarningMessage = ({
  isDestructive,
  message,
}: {
  isDestructive?: boolean
  message: string
}) => {
  return (
    <div
      className={cn('flex items-center gap-2 px-6 py-3', {
        'bg-destructive/15': isDestructive,
        'bg-accent': !isDestructive,
      })}
    >
      <Icon
        icon={Info}
        size="small"
        className={cn({
          'text-destructive': isDestructive,
        })}
      />
      <p
        className={cn('text-xs', {
          'text-destructive': isDestructive,
        })}
      >
        {message}
      </p>
    </div>
  )
}

const VaultFileExplorer = ({
  selectedRows,
  setSelectedRows,
  className,
  isAddingFilesToQuery = false,
  existingSelectedFileIds,
  preselectFileIds,
  projectId,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold = DEFAULT_FILES_WARNING_THRESHOLD,
  showDocumentClassificationMismatchWarning = false,
}: {
  selectedRows: VaultItemWithIndex[]
  setSelectedRows: (rows: VaultItemWithIndex[]) => void
  className?: string
  isAddingFilesToQuery?: boolean
  existingSelectedFileIds?: Set<string>
  preselectFileIds?: Set<string>
  projectId: string | undefined
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
  showDocumentClassificationMismatchWarning?: boolean
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const folderId = searchParams.get(folderIdSearchParamKey)
  const userInfo = useAuthUser()

  const navigate = useNavigateWithQueryParams()

  const { trackEvent } = useAnalytics()

  const [
    currentFolderId,
    folderIdToVaultFolder,
    parentIdToVaultFolderIds,
    fileIdToVaultFile,
    folderIdToVaultFileIds,
    foldersMetadata,
    currentProjectMetadata,
    queryIdToState,
    requiresProjectDataRefetch,
    exampleProjectIds,
    sharedProjectIds,
    setCurrentFolderId,
    setAreUploadButtonsDisabled,
    setCurrentProject,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    setRequiresProjectDataRefetch,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentFolderId,
      state.folderIdToVaultFolder,
      state.parentIdToVaultFolderIds,
      state.fileIdToVaultFile,
      state.folderIdToVaultFileIds,
      state.foldersMetadata,
      state.currentProjectMetadata,
      state.queryIdToState,
      state.requiresProjectDataRefetch,
      state.exampleProjectIds,
      state.sharedProjectIds,
      state.setCurrentFolderId,
      state.setAreUploadButtonsDisabled,
      state.setCurrentProject,
      state.upsertVaultFolders,
      state.upsertVaultFiles,
      state.addToProjectsMetadata,
      state.setRequiresProjectDataRefetch,
      state.deleteVaultFiles,
      state.deleteVaultFolders,
      state.setError,
    ])
  )

  const [
    searchValue,
    isSearching,
    isRequestingSemanticSearch,
    isSemanticSearching,
    clearSearchHandler,
    setIsRequestingSemanticSearch,
    setIsSemanticSearching,
    setNumRowsFound,
  ] = useVaultFileExplorerStore(
    useShallow((state) => [
      state.searchValue,
      state.isSearching,
      state.isRequestingSemanticSearch,
      state.isSemanticSearching,
      state.clearSearchHandler,
      state.setIsRequestingSemanticSearch,
      state.setIsSemanticSearching,
      state.setNumRowsFound,
    ])
  )
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((state) => state.permissionsByProjectId)
  )

  const setOpenFileId = useDocumentClassificationStore(
    useShallow((s) => s.setOpenFileId)
  )

  const [lastSelectedRow, setLastSelectedRow] =
    useState<VaultItemWithIndex | null>(null)
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false)

  const isSharedProject = userInfo.IsVaultViewSharesUser
    ? isProjectShared(sharedProjectIds, permissionsByProjectId, projectId)
    : false

  const shouldShowLargeQueryWarning =
    !_.isNil(numberOfFilesWarningThreshold) &&
    selectedRows.length >= numberOfFilesWarningThreshold
  const exceedsFileLimit =
    !_.isNil(numberOfFilesLimit) && selectedRows.length > numberOfFilesLimit

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setIsShiftKeyPressed(true)
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setIsShiftKeyPressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const isFilesProcessing =
    // If there are incomplete files, we want to poll for the update
    currentProjectMetadata.completedFiles !== currentProjectMetadata.totalFiles

  const pollProjectData = useCallback(async () => {
    if (!projectId) return
    try {
      const project = await FetchVaultFolder(projectId, true)
      setCurrentProject(project)
      // fetch the metadata for the folder and its subfolders and files
      const projectMetadataResponse = await fetchProjectMetadata(project)
      const projectData = projectMetadataResponse[projectId]
      upsertVaultFolders(
        [project, ...(projectData.descendantFolders || [])],
        userInfo.dbId,
        !exampleProjectIds.has(projectId),
        projectId
      )
      upsertVaultFiles(projectData.descendantFiles || [], projectId)
      addToProjectsMetadata(projectMetadataResponse)
    } catch (e) {
      console.error(e)
      if (e instanceof HTTPError && e.response.status === 404) {
        // Show error message and delete project data if project no longer found
        setError({
          message: `The requested Vault project does not exist.\nContact support@harvey.ai if this issue persists.`,
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
        deleteVaultFiles(
          currentProjectMetadata?.descendantFiles?.map((file) => file.id) ?? [],
          projectId
        )
        deleteVaultFolders(
          currentProjectMetadata?.descendantFolders?.map(
            (folder) => folder.id
          ) ?? [],
          projectId
        )
      }
    }
  }, [
    projectId,
    setCurrentProject,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    userInfo.dbId,
    exampleProjectIds,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
    currentProjectMetadata,
  ])

  useInterval(pollProjectData, isFilesProcessing ? 10_000 : null)

  useEffect(() => {
    const refetchProjectData = async () => {
      await pollProjectData()
      setRequiresProjectDataRefetch(false)
    }
    if (requiresProjectDataRefetch) {
      void refetchProjectData()
    }
  }, [
    requiresProjectDataRefetch,
    pollProjectData,
    setRequiresProjectDataRefetch,
  ])

  // we want to use a useEffect here instead of useMount to allow the user to navigate with the browser forward & back buttons
  useEffect(() => {
    const currId = folderId ?? projectId
    setCurrentFolderId(currId ?? null)
  }, [setCurrentFolderId, folderId, projectId])

  useUnmount(() => {
    setSelectedRows([])
    setOpenFileId(null)
  })

  const {
    data: semanticSearchResults,
    isFetching,
    error,
  } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultFileSearchQuery, projectId, searchValue],
    queryFn: async ({ signal }) => {
      try {
        const response = await SemanticSearch(projectId!, searchValue, signal)
        return response.fileIds
      } catch (e) {
        return []
      }
    },
    enabled:
      isSearching &&
      isRequestingSemanticSearch &&
      searchValue.trim().length > 0,
  })
  useEffect(() => {
    if (error) {
      console.error('Failed to search for files', error)
      displayErrorMessage('Failed to search for files')
    }
  }, [error])
  useEffect(() => {
    setIsSemanticSearching(isFetching)
  }, [isFetching, setIsSemanticSearching])

  // TODO: Remove isRequestingSemanticSearch as it's always true
  // This is to hide "Search more" button temporarily before we
  // figure out the design of keyword search + semantic search.
  useEffect(() => {
    setIsRequestingSemanticSearch(true)
  }, [searchValue, setIsRequestingSemanticSearch])

  const documentTypeMouseEnter = () => {
    trackEvent('Document type header hovered')
  }

  const data: VaultItem[] = useMemo(() => {
    if (!currentFolderId) {
      return []
    }

    if (isSearching) {
      const projectFolderIds = new Set([
        projectId,
        ...(currentProjectMetadata.descendantFolders?.map(
          (folder) => folder.id
        ) || []),
      ])

      const localSearchedFiles = (
        Array.from(projectFolderIds)
          .flatMap((folderId) =>
            folderId ? folderIdToVaultFileIds[folderId] ?? [] : []
          )
          .map((fileId) => fileIdToVaultFile[fileId])
          .filter(Boolean) as VaultFile[]
      )
        .filter((file) =>
          file.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .sort(compareFileName)

      const localSearchedFolders = (
        currentProjectMetadata.descendantFolders ?? []
      )
        .filter((folder) =>
          folder.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .sort(compareFileName)

      const localSearchedRows = [
        ...localSearchedFolders.map((folder: VaultFolder) =>
          folderAsItem({ folder, foldersMetadata, shouldExpand: false })
        ),
        ...localSearchedFiles.map((file) => fileAsItem(file)),
      ]
      const semanticSearchedRows = (semanticSearchResults ?? [])
        .map((fileId) => fileIdToVaultFile[fileId])
        .filter((file) => file && projectFolderIds.has(file.vaultFolderId))
        .filter((file) => !localSearchedFiles.some((f) => f.id === file!.id))
        .map((file) => fileAsItem(file!))

      setNumRowsFound(localSearchedRows.length + semanticSearchedRows.length)
      return [...localSearchedRows, ...semanticSearchedRows]
    }

    const filteredAndSortedFolders = filterAndSortFolders({
      folderIdToVaultFolder,
      parentIdToVaultFolderIds,
      userId: userInfo.dbId,
      parentId: currentFolderId,
      projectId: projectId!,
      exampleProjectIds,
      isSharedProject,
    })

    const projectFiles = (
      (folderIdToVaultFileIds[currentFolderId] ?? [])
        .map((fileId) => fileIdToVaultFile[fileId])
        .filter(Boolean) as VaultFile[]
    ).sort(compareFileName)

    const allRows = [
      ...filteredAndSortedFolders.map((folder: VaultFolder) =>
        folderAsItem({
          folder,
          foldersMetadata,
          shouldExpand: true,
          folderIdToVaultFolder,
          parentIdToVaultFolderIds,
          fileIdToVaultFile,
          folderIdToVaultFileIds,
          existingSelectedFileIds,
          isAddingFilesToQuery,
        })
      ),
      ...projectFiles.map((file) =>
        fileAsItem(file, existingSelectedFileIds, isAddingFilesToQuery)
      ),
    ]
    return allRows
  }, [
    userInfo.dbId,
    projectId,
    currentProjectMetadata,
    currentFolderId,
    parentIdToVaultFolderIds,
    folderIdToVaultFolder,
    fileIdToVaultFile,
    folderIdToVaultFileIds,
    foldersMetadata,
    isSearching,
    searchValue,
    semanticSearchResults,
    setNumRowsFound,
    exampleProjectIds,
    existingSelectedFileIds,
    isAddingFilesToQuery,
    isSharedProject,
  ])

  const columns: Array<ColumnDef<VaultItem>> = [
    {
      id: 'select',
      header: ({ table }) => <VaultSelectHeader table={table} />,
      cell: ({ row }) => (
        <VaultSelectCell
          row={row}
          setLastSelectedRow={setLastSelectedRow}
          handleShiftSelection={handleShiftSelection}
        />
      ),
      enableSorting: false,
      size: 24,
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Name" />
      ),
      id: 'name',
      accessorKey: 'name',
      cell: ({ row }) => VaultNameCell({ row }),
      enableSorting: true,
      size: 280,
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader
          column={column}
          header="Document type"
          tooltipPopoverClassName="max-w-48"
          tooltipDelay={200}
          tooltipText={DOCUMENT_CLASSIFICATION_TOOLTIP_TEXT}
          onMouseEnter={documentTypeMouseEnter}
        />
      ),
      id: 'documentType',
      accessorKey: 'tags',
      size: 128,
      enableResizing: false,
      cell: ({ row }) => VaultClassificationCell({ row, isAddingFilesToQuery }),
      sortingFn: (a, b) => {
        const aDocClassificationTag = a.original.tags?.find(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        )
        const bDocClassificationTag = b.original.tags?.find(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        )
        return (
          aDocClassificationTag?.name.localeCompare(
            bDocClassificationTag?.name ?? ''
          ) || 0
        )
      },
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Updated" />
      ),
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      cell: ({ row }) => VaultTimeCell({ row, timeKey: 'updatedAt' }),
      size: 100,
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Kind" />
      ),
      id: 'contentType',
      accessorKey: 'contentType',
      cell: ({ row }) => VaultContentTypeCell({ row }),
      size: 64,
      sortingFn: (a, b) => {
        return getContentTypeDisplayString(a.original).localeCompare(
          getContentTypeDisplayString(b.original)
        )
      },
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Size" />
      ),
      id: 'size',
      accessorKey: 'size',
      cell: ({ row }) =>
        VaultSizeCell({
          row,
        }),
      size: 120,
    },
    {
      header: '',
      id: 'id',
      accessorKey: 'id',
      cell: ({ row }) => (
        <div className="flex w-full justify-center">
          <VaultMenuCell row={row} />
        </div>
      ),
      size: 40,
    },
    {
      header: 'Location',
      id: 'location',
      accessorKey: 'location',
      cell: ({ row }) => VaultLocationCell({ row }),
      size: 280,
    },
  ]

  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )
  const sharingPermissionsOptions = useMemo(
    () => ({
      projectId: projectId,
    }),
    [projectId]
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions(
    sharingPermissionsOptions
  )

  const hideColumns = useMemo(() => {
    const columnsToHide = isSearching
      ? ['updatedAt', 'size', 'id']
      : ['location']

    if (isExampleProject) {
      columnsToHide.push('id')
      columnsToHide.push('select')
    }
    if (!doesCurrentUserHaveEditPermission) {
      columnsToHide.push('id')
      columnsToHide.push('select')
    }
    if (isAddingFilesToQuery) {
      columnsToHide.push('updatedAt')
      columnsToHide.push('contentType')
      columnsToHide.push('id')
    }
    return columnsToHide
  }, [
    isSearching,
    isExampleProject,
    isAddingFilesToQuery,
    doesCurrentUserHaveEditPermission,
  ])

  const [sorting, setSorting] = useState<SortingState>([])
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 50,
    pageIndex: 0,
  })

  const getPathForItem = useCallback(
    (item: VaultItem) => {
      const vaultItemsOnPath: VaultItem[] = []
      let currentItem: VaultItem | null = item
      while (currentItem) {
        vaultItemsOnPath.push(currentItem)
        const parentFolderId =
          currentItem.type === VaultItemType.file
            ? currentItem.data.vaultFolderId
            : currentItem.data.parentId
        if (!parentFolderId || parentFolderId === currentFolderId) break
        const parentFolder = folderIdToVaultFolder[parentFolderId]
        if (parentFolder) {
          currentItem = folderAsItem({
            folder: parentFolder,
            foldersMetadata,
            shouldExpand: false,
          })
        } else {
          currentItem = null
        }
      }

      let currentItems: VaultItem[] = data
      return vaultItemsOnPath
        .reverse()
        .reduce((path, vaultItem) => {
          const index = currentItems.findIndex(
            (item) => item.id === vaultItem.id && item.type === vaultItem.type
          )
          if (index === -1) return path
          path.push(String(index))
          currentItem = currentItems[index]
          currentItems =
            currentItem.type !== VaultItemType.file
              ? currentItem.children ?? []
              : []
          return path
        }, [] as string[])
        .join('.')
    },
    [data, foldersMetadata, folderIdToVaultFolder, currentFolderId]
  )

  const rowSelection = useMemo(() => {
    return selectedRows.reduce(
      (acc, row) => {
        const path = getPathForItem(row)

        if (path && path.length > 0) {
          acc[path] = true
        }
        return acc
      },
      {} as Record<string, boolean>
    )
  }, [selectedRows, getPathForItem])
  const table = useReactTable<VaultItem>({
    columns,
    data,
    autoResetPageIndex: false,
    getSubRows: (row) =>
      row.type !== VaultItemType.file ? row.children : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: isAddingFilesToQuery
      ? undefined
      : getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: isAddingFilesToQuery ? undefined : setPagination,
    onRowSelectionChange: (
      updaterOrValue:
        | Record<string, boolean>
        | ((old: Record<string, boolean>) => Record<string, boolean>)
    ) => {
      let newRowSelection = updaterOrValue
      if (updaterOrValue instanceof Function) {
        newRowSelection = updaterOrValue(rowSelection)
      }
      for (const index in newRowSelection) {
        const row = table.getRow(index)
        if (
          isAddingFilesToQuery &&
          row.original.disabled &&
          (newRowSelection as Record<string, boolean>)[index]
        ) {
          ;(newRowSelection as Record<string, boolean>)[index] = false
        }
      }

      const getItemByIndex = (
        index: string,
        newRowSelection: Record<string, boolean>
      ): VaultItemWithIndex | null => {
        const row = table.getRow(index)
        const isAllDescendantsSelected = row
          .getLeafRows()
          .every((subRow) => newRowSelection[subRow.id])
        const item = { ...row.original, index, isAllDescendantsSelected }
        return item
      }

      const newSelectedRows = Object.entries(newRowSelection)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([index, isSelected]) => isSelected)
        .map(([index]) =>
          getItemByIndex(index, newRowSelection as Record<string, boolean>)
        )
        .filter(Boolean) as VaultItemWithIndex[]
      setSelectedRows(newSelectedRows)
    },
    state: {
      sorting,
      pagination: isAddingFilesToQuery ? undefined : pagination,
      rowSelection,
      columnVisibility: _.mapValues(_.keyBy(hideColumns), () => false),
    },
    enableSorting: true,
    enableSortingRemoval: true,
  })
  // TODO: We might have a better way to handle these changes?
  useEffect(() => {
    // De-select all rows if we're navigating to a new folder or searching
    // This is to prevent the user from getting unexpected results.
    setSelectedRows([])
  }, [currentFolderId, isSearching, searchValue, setSelectedRows])

  useMount(() => {
    if (preselectFileIds && preselectFileIds.size > 0) {
      const preselectedRows: VaultItemWithIndex[] = []

      const processRow = (row: Row<VaultItem>) => {
        if (
          row.original.type === VaultItemType.file &&
          preselectFileIds.has(row.original.id)
        ) {
          preselectedRows.push({
            ...row.original,
            index: getPathForItem(row.original),
          })
        }
      }
      table.getExpandedRowModel().flatRows.forEach((row) => processRow(row))

      setSelectedRows(preselectedRows)
    }
  })

  const handleShiftSelection = useCallback(
    (row: Row<VaultItem>) => {
      const dedupedFlatRows = table
        .getSortedRowModel()
        .flatRows.filter(
          (row, index, self) =>
            index === self.findIndex((r) => r.original.id === row.original.id)
        )
      const startIndex = dedupedFlatRows.findIndex(
        (r) => r.original.id === lastSelectedRow?.id
      )
      const endIndex = dedupedFlatRows.findIndex(
        (r) => r.original.id === row.original.id
      )
      const range = [startIndex, endIndex].sort((a, b) => a - b)

      const rowsInRange = dedupedFlatRows.slice(range[0], range[1] + 1)
      const vaultItemsInRange = rowsInRange
        .flatMap((row) => {
          const items = [
            {
              ...row.original,
              index: getPathForItem(row.original),
              isAllDescendantsSelected: row
                .getLeafRows()
                .every(
                  (subRow) =>
                    rowsInRange.includes(subRow) ||
                    selectedRows.find(
                      (selectedRow) => selectedRow.id === subRow.original.id
                    )
                ),
            },
          ]
          // if last row selected is a folder, make sure to select all descendants
          const rowIndex = dedupedFlatRows.indexOf(row)
          if (row.original.type === 'folder' && rowIndex === endIndex) {
            items.push(
              ...row.getLeafRows().map((leafRow) => ({
                ...leafRow.original,
                index: getPathForItem(leafRow.original),
                isAllDescendantsSelected: leafRow.original.type === 'folder',
              }))
            )
          }
          return items
        })
        .filter(
          (vaultItem) =>
            !selectedRows.some((selectedRow) => selectedRow.id === vaultItem.id)
        )
      setSelectedRows([...selectedRows, ...vaultItemsInRange])
    },
    [lastSelectedRow, selectedRows, table, getPathForItem, setSelectedRows]
  )

  const onRowClick = useCallback(
    async (row: Row<VaultItem>) => {
      if (
        row.original.status === VaultItemStatus.failedToUpload ||
        row.original.status === VaultItemStatus.unrecoverableFailure ||
        row.original.status === VaultItemStatus.recoverableFailure
      ) {
        return
      }
      if (
        isAddingFilesToQuery &&
        existingSelectedFileIds &&
        existingSelectedFileIds.has(row.original.id)
      ) {
        // if we're adding files to a query, and the current row is already selected, we don't select it again
        return
      }
      if (
        isAddingFilesToQuery &&
        row.original.type === VaultItemType.file &&
        row.original.status !== VaultItemStatus.readyToQuery
      ) {
        // if we're adding files to a query, and the current row is not ready to query, we don't select it
        return
      }
      if (
        row.original.type === VaultItemType.file &&
        (!isNil(row.original.url) || !isNil(row.original.docAsPdfUrl))
      ) {
        if (selectedRows.length > 0) {
          if (
            selectedRows.some(
              (selectedRow) =>
                selectedRow.type === row.original.type &&
                selectedRow.id === row.original.id
            )
          ) {
            // deselect if already selected
            setSelectedRows(
              selectedRows.filter(
                (selectedRow) =>
                  selectedRow.type !== row.original.type ||
                  selectedRow.id !== row.original.id
              )
            )
          } else {
            if (isShiftKeyPressed && lastSelectedRow !== null) {
              handleShiftSelection(row)
            } else {
              setSelectedRows([
                ...selectedRows,
                { ...row.original, index: getPathForItem(row.original) },
              ])
            }
          }
          // If some rows are already selected, we don't want to navigate to the file previewer.
          return
        }

        // if shift key is pressed and no rows are selected, we want to select the current row
        // or if we're adding files to a query, we want to select the current row
        if (isShiftKeyPressed || isAddingFilesToQuery) {
          setSelectedRows([
            ...selectedRows,
            { ...row.original, index: getPathForItem(row.original) },
          ])
          setLastSelectedRow({
            ...row.original,
            index: getPathForItem(row.original),
          })
          return
        }

        // navigate user to the file-previewer page
        const fileId = row.original.id
        trackEvent('Vault File Opened', {
          file_id: row.original.id,
        })
        const queryIds = Object.keys(queryIdToState)
        let latestQueryId: string | undefined = undefined
        queryIds.forEach((queryId) => {
          const query = queryIdToState[queryId] as VaultSocketTask
          if (query.isLoading) {
            // If the query is loading, we don't want to use it as latest query to open with
            return
          }

          const queryFileIds =
            query.taskType === EventKind.VAULT_REVIEW
              ? query.processedFileIds
              : query.sourcedFileIds
          if (_.isEmpty(queryFileIds) || !queryFileIds.includes(fileId)) {
            // If the file does not belong to the query, we don't want to use it as latest query to open with
            return
          }

          if (isNil(latestQueryId)) {
            // If we haven't found a query that contains the file, we use the current query as latest query to open with
            latestQueryId = queryId
            return
          }

          const latestQueryUpdatedAt = getQueryUpdatedAt(
            queryIdToState[latestQueryId]
          )!
          const queryUpdatedAt = getQueryUpdatedAt(query)!
          if (queryUpdatedAt > latestQueryUpdatedAt) {
            latestQueryId = queryId
          }
        })
        const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${filesPath}${fileId}`
        const canOpenFile = row.original.status !== VaultItemStatus.uploading
        if (!userInfo.IsVaultV2User && !isNil(latestQueryId) && canOpenFile) {
          const searchParams = new URLSearchParams()
          searchParams.set(queryIdSearchParamKey, latestQueryId)
          const searchParamString = `?${searchParams.toString()}`
          navigate(newPath + searchParamString, {}, REMOVE_PARAMS)
        } else if (canOpenFile) {
          navigate(newPath, {}, REMOVE_PARAMS)
        } else {
          displayWarningMessage(
            `This file is not ready for previewing. Please try again later.`
          )
        }
      } else if (row.original.type === VaultItemType.file) {
        displayWarningMessage(
          `This file is not ready for previewing. Please try again later.`
        )
      } else if (row.original.type === VaultItemType.folder) {
        if (isSearching) {
          clearSearchHandler()
          setAreUploadButtonsDisabled(false)
          setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev)
            newParams.set(folderIdSearchParamKey, row.original.id)
            return newParams
          })
        } else if (
          isShiftKeyPressed &&
          !selectedRows.some(
            (selectedRow) =>
              selectedRow.type === row.original.type &&
              selectedRow.id === row.original.id
          )
        ) {
          // add all descendants to selection
          const descendantRows = row.getLeafRows().map((subRow) => ({
            ...subRow.original,
            index: getPathForItem(subRow.original),
          }))
          setSelectedRows([
            ...selectedRows,
            {
              ...row.original,
              index: getPathForItem(row.original),
              isAllDescendantsSelected: true,
            },
            ...descendantRows,
          ])
          // if no rows are selected, set the last selected row to the current row
          if (selectedRows.length === 0) {
            setLastSelectedRow({
              ...row.original,
              index: getPathForItem(row.original),
            })
          } else if (lastSelectedRow) {
            handleShiftSelection(row)
          }
        } else {
          row.toggleExpanded(!row.getIsExpanded())
        }
      } else {
        console.error('Unhandled row type', row.original.type)
      }
    },
    [
      navigate,
      selectedRows,
      setSelectedRows,
      isSearching,
      setSearchParams,
      clearSearchHandler,
      setAreUploadButtonsDisabled,
      projectId,
      queryIdToState,
      userInfo.IsVaultV2User,
      trackEvent,
      getPathForItem,
      lastSelectedRow,
      isShiftKeyPressed,
      handleShiftSelection,
      isAddingFilesToQuery,
      existingSelectedFileIds,
    ]
  )

  const entity = projectId === currentFolderId ? 'project' : 'folder'
  const emptyStateText = isSearching
    ? `No results found`
    : isSharedProject
    ? `No files have been uploaded to this ${entity} yet`
    : `You haven’t uploaded any files to this ${entity} yet`

  const selectedFilesCountText = useMemo(() => {
    return pluralizeFiles(getSelectedFiles(selectedRows).length)
  }, [selectedRows])

  return (
    <div className={cn({ 'pb-8': !isAddingFilesToQuery }, className)}>
      <DataTable
        caption={`${entity} files`}
        isLoading={isSemanticSearching}
        table={table}
        onRowClick={onRowClick}
        className={cn('rounded-none', {
          'px-6': isAddingFilesToQuery,
        })}
        hideTableBorder
        tableCellClassName="bg-primary px-1 py-2.5 first:px-0 first:py-1 last:px-0 last:py-1 font-normal select-none"
        emptyStateText={emptyStateText}
        useVirtual={isAddingFilesToQuery}
        virtualEstimateSize={41}
        marginTop={isAddingFilesToQuery ? 256 : 0}
      />
      {(exceedsFileLimit || shouldShowLargeQueryWarning) && (
        <WarningMessage
          isDestructive={exceedsFileLimit}
          message={
            exceedsFileLimit
              ? `File limit exceeded. Please select no more than ${numberOfFilesLimit} files.`
              : `You are about to run a query on ${selectedFilesCountText}. This may take some time.`
          }
        />
      )}
      {!shouldShowLargeQueryWarning &&
        showDocumentClassificationMismatchWarning && (
          <WarningMessage isDestructive={false} message={MISMATCH_MESSAGE} />
        )}
      {isSearching &&
        table.getPageCount() <= 1 &&
        (isRequestingSemanticSearch ? (
          data.length > 0 && isSemanticSearching ? (
            <Skeleton rows={5} rowHeight="h-4" />
          ) : null
        ) : (
          <div className="flex w-full flex-col justify-center">
            <Button
              variant="text"
              className="font-semibold"
              onClick={() => {
                trackEvent('Vault File Semantic Search Requested', {
                  value: searchValue,
                })
                setIsRequestingSemanticSearch(true)
              }}
            >
              <Icon icon={Search} className="mr-2" />
              Search more
            </Button>
          </div>
        ))}
      {/* Only show the footer if the table has at least 10 rows (after expansion) */}
      {!isAddingFilesToQuery &&
        table.getExpandedRowModel().rows.length > DataTablePageSizes[0] && (
          <DataTableFooter table={table} />
        )}
    </div>
  )
}

export default VaultFileExplorer
