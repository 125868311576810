import * as React from 'react'

import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import HarveyLogo from 'components/common/harvey-logo'
import { useUserProfileStore } from 'components/common/user-profile-store'
import { Button } from 'components/ui/button'

import { NUM_TOTAL_STEPS, ProfileSetupFlowType } from './profile-setup'
import { EntryPointType } from './utils/entry-point-types'

export type WelcomeStepType = Step<
  { entryPointType: EntryPointType },
  {
    stepCounter: number
    totalSteps: number
  }
>

export const WELCOME_STEP_TEXTS = {
  [EntryPointType.NEW_USER]: {
    TITLE: 'Welcome to Harvey',
    TOP_SUBTITLE:
      'Professionals in legal, tax, and finance use Harvey every day to do complex work, faster.',
    BOTTOM_SUBTITLE:
      'Create your profile to personalize your experience and get content tailored to your profession and language.',
  },
  [EntryPointType.EXISTING_USER]: {
    TITLE: 'Create your profile',
    TOP_SUBTITLE:
      'Harvey now adapts to you. Create your profile to personalize your experience, and get content tailored to your profession and language.',
    BOTTOM_SUBTITLE: 'Completion time: Less than a minute',
  },
  [EntryPointType.SETTINGS_PAGE]: {
    TITLE: 'Create your profile',
    TOP_SUBTITLE:
      'Harvey now adapts to you. Create your profile to personalize your experience, and get content tailored to your profession and language.',
    BOTTOM_SUBTITLE: 'Completion time: Less than a minute',
  },
}

const WelcomeStep: React.FC = () => {
  const { navigateToStep, exit } = useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<WelcomeStepType>()
  const skipOnboarding = useUserProfileStore((s) => s.skipOnboarding)
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_welcome_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = () => {
    navigateToStep('profession-step', {
      stepCounter: 1,
      totalSteps: NUM_TOTAL_STEPS,
    })
  }

  const handleRemindMeLater = () => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_skipped', preparedEventProperties)
    skipOnboarding()
    exit()
  }

  const { TITLE, TOP_SUBTITLE, BOTTOM_SUBTITLE } =
    WELCOME_STEP_TEXTS[stepInput.entryPointType]

  return (
    <div
      className={cn(
        'mx-auto flex h-full max-w-96 flex-col items-center justify-between pt-[90px]',
        userInfo?.isCreateUserProfilesOptionalUser ? 'pb-[30px]' : 'pb-[60px]'
      )}
    >
      <div className="flex flex-col items-center">
        <div className="mb-4 flex size-16 items-center justify-center rounded-full border">
          <HarveyLogo className="h-7" />
        </div>
        <h1 className="mb-2 text-xl font-medium">{TITLE}</h1>
        <p className="w-[325px] text-center text-sm text-secondary">
          {TOP_SUBTITLE}
        </p>
      </div>
      <div className="flex flex-col items-center">
        <p className="mb-2 text-center text-sm text-secondary">
          {BOTTOM_SUBTITLE}
        </p>
        <p className="mb-4 text-center text-[10px] text-inactive">
          By clicking continue, you agree to Harvey&rsquo;s{' '}
          <a
            href="https://www.harvey.ai/legal/privacy-policy"
            className="underline outline-none transition hover:text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
        <Button
          className={cn({ 'mb-2': userInfo?.isCreateUserProfilesOptionalUser })}
          onClick={handleContinue}
        >
          Continue
        </Button>
        {userInfo?.isCreateUserProfilesOptionalUser ? (
          <Button onClick={handleRemindMeLater} variant="link">
            Remind me later
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default WelcomeStep
