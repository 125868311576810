import React from 'react'

import DataRow from './data-row'

const ExceptionComponent: React.FC<{
  exception: any
  idx: number
}> = ({ exception, idx }) => {
  return (
    <div className="space-y-2">
      <div className="mb-1 flex space-x-4">
        <p className="w-24 text-xs font-semibold">Exception {idx + 1}</p>
        <p className="text-xs font-semibold">
          {exception.exceptionConclusion.charAt(0).toUpperCase() +
            exception.exceptionConclusion.slice(1)}
        </p>
      </div>

      <DataRow label="Requirement" value={exception.exception} />
      <DataRow label="Analysis" value={exception.exceptionAnalysis} />
    </div>
  )
}

export default ExceptionComponent
