/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowFileUploadInputBlockBlockParams
 */
export interface WorkflowFileUploadInputBlockBlockParams {
    /**
     * 
     * @type {string}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    headerText: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    maxFileSizeBytes?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    maxTotalFileSizeBytes?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    minFileCount?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    maxFileCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowFileUploadInputBlockBlockParams
     */
    acceptedFileMimeTypes?: Array<string>;
}

/**
 * Check if a given object implements the WorkflowFileUploadInputBlockBlockParams interface.
 */
export function instanceOfWorkflowFileUploadInputBlockBlockParams(value: object): value is WorkflowFileUploadInputBlockBlockParams {
    if (!('headerText' in value) || value['headerText'] === undefined) return false;
    return true;
}

export function WorkflowFileUploadInputBlockBlockParamsFromJSON(json: any): WorkflowFileUploadInputBlockBlockParams {
    return WorkflowFileUploadInputBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowFileUploadInputBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowFileUploadInputBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'headerText': json['header_text'],
        'maxFileSizeBytes': json['max_file_size_bytes'] == null ? undefined : json['max_file_size_bytes'],
        'maxTotalFileSizeBytes': json['max_total_file_size_bytes'] == null ? undefined : json['max_total_file_size_bytes'],
        'minFileCount': json['min_file_count'] == null ? undefined : json['min_file_count'],
        'maxFileCount': json['max_file_count'] == null ? undefined : json['max_file_count'],
        'acceptedFileMimeTypes': json['accepted_file_mime_types'] == null ? undefined : json['accepted_file_mime_types'],
    };
}

export function WorkflowFileUploadInputBlockBlockParamsToJSON(json: any): WorkflowFileUploadInputBlockBlockParams {
    return WorkflowFileUploadInputBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowFileUploadInputBlockBlockParamsToJSONTyped(value?: WorkflowFileUploadInputBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'header_text': value['headerText'],
        'max_file_size_bytes': value['maxFileSizeBytes'],
        'max_total_file_size_bytes': value['maxTotalFileSizeBytes'],
        'min_file_count': value['minFileCount'],
        'max_file_count': value['maxFileCount'],
        'accepted_file_mime_types': value['acceptedFileMimeTypes'],
    };
}

