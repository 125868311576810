import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { User } from 'lucide-react'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import HarveyLogo from 'components/common/harvey-logo'
import Icon from 'components/ui/icon/icon'

export const AssistantWorkflowYouComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="mb-4 flex items-start space-x-3">
      <div className="flex size-6 shrink-0 items-center justify-center rounded-full border">
        <Icon icon={User} className="size-4 text-inactive" />
      </div>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm text-inactive">You</p>
        {children}
      </div>
    </div>
  )
}

export const AssistantWorkflowHarveyComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="mb-4 flex items-start space-x-3">
      <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-accent">
        <HarveyLogo className="size-3" />
      </div>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm text-inactive">Harvey</p>
        {children}
      </div>
    </div>
  )
}

export const AssistantWorkflowThreadText: React.FC<{ text: string }> = ({
  text,
}) => {
  return <p className="text-base text-primary">{text}</p>
}

export const AssistantWorkflowThreadBlock: React.FC<{
  children: React.ReactNode
  sidebar?: React.ReactNode
}> = ({ children, sidebar }) => {
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  // 324x is 288 (width of citations) + 36px (margin)
  const sideColumnWidth = fileId ? '16px' : 'minmax(324px, 1fr)'
  return (
    <div
      className="grid"
      style={{
        gridTemplateColumns: `${sideColumnWidth} minmax(640px, 1000px) minmax(324px, 1fr)`,
      }}
    >
      <div />
      <div className="mx-auto w-full">{children}</div>
      <div className="relative">{sidebar}</div>
    </div>
  )
}
