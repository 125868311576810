import { DateRange } from 'react-day-picker'

import { ClientMatterWithStats } from 'models/client-matters'
import { Event } from 'models/event'
import {
  FetchHistoryArgs,
  makeParamsShort,
} from 'models/helpers/history-helper'
import { EventKind } from 'openapi/models/EventKind'
import Services from 'services'
import { Maybe } from 'types'
import { HistoryItem, HistoryItemFolder, RawHistoryItem } from 'types/history'

import { getDateRange } from 'utils/date-utils'
import { TaskType } from 'utils/task'
import { parseIsoString } from 'utils/utils'

const FetchHistoryItem = async ({
  id,
  throwOnError = false,
  maxRetryCount = 3,
  useVaultEndpoint = false,
  skipSources = false,
}: {
  id: Maybe<string>
  throwOnError?: boolean
  maxRetryCount?: number
  useVaultEndpoint?: boolean
  skipSources?: boolean
}): Promise<HistoryItem | null> => {
  if (!id) {
    return null
  }

  const searchParams = new URLSearchParams()
  if (skipSources) {
    searchParams.append('skip_sources', 'true')
  }

  const searchParamsString = searchParams.toString()

  return Services.Backend.Get<RawHistoryItem>(
    `${useVaultEndpoint ? 'vault' : 'user'}/history/${id}${
      searchParamsString ? `?${searchParamsString}` : ''
    }`,
    {
      throwOnError: true,
      maxRetryCount: maxRetryCount,
    }
  )
    .then((historyItem) => {
      const dateRange = getDateRange(historyItem.dateRange)
      return {
        ...historyItem,
        id: historyItem.id.toString(), // This is a number in the backend
        created: new Date(historyItem.created),
        updatedAt: new Date(historyItem.updatedAt),
        dateRange,
        query: historyItem.query || '',
        startedAt: historyItem.startedAt
          ? parseIsoString(historyItem.startedAt)
          : undefined,
      }
    })
    .catch((e) => {
      if (throwOnError) {
        throw e
      } else {
        console.warn('Failed to fetch history item', { e, id })
        return null
      }
    })
}

const makeHistoryUrl = ({
  currentPage,
  pageSize,
  workspaceSlug,
  historyType,
}: FetchHistoryArgs) => {
  const params = makeParamsShort({
    currentPage,
    pageSize,
    workspaceSlug,
    historyType,
  })
  const requestPath = `${historyType}/history?${params.toString()}`
  return requestPath
}

const FetchHistoryMetadata = async () => {
  return Services.Backend.Get<{
    eventTaskTypes: TaskType[]
    eventFolders: HistoryItemFolder[]
    eventClientMatters: ClientMatterWithStats[]
  }>('user/history/metadata', {
    throwOnError: true,
  }).catch((e) => {
    console.warn('Failed to fetch history metadata', { e })
    return null
  })
}

const FetchWorkspaceHistoryMetadata = async (workspaceId: number) => {
  return Services.Backend.Get<{
    eventTaskTypes: TaskType[]
    eventClientMatters: ClientMatterWithStats[]
  }>(`workspace/history/metadata?workspace_id=${workspaceId}`, {
    throwOnError: true,
  }).catch((e) => {
    console.warn('Failed to fetch workspace history metadata', { e })
    return null
  })
}

export enum Export {
  Usage = 'usage', // only stats exports, so we can do it for 12 months. It should align with the graphs in the dashboard.
  Queries = 'queries', // full queries export, so we need to restrict this to a maximum of 30 days. This should align with the history view in the dashboard.
}

export interface HistoryExportDownloadPollResponse {
  downloadUrl?: string
}

export interface HistoryExportDownloadResponse {
  requestId: string
}

interface DownloadWorkspaceHistoryParams {
  exportType: Export
  workspaceSlug: string
  events: Event[]
  // be sure to use IANA timezone names so postgres can interpret
  // https://www.iana.org/time-zones
  userTz?: string
  dateRange?: DateRange
}

const DownloadWorkspaceHistory = async ({
  exportType,
  workspaceSlug,
  events,
  userTz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  dateRange,
}: DownloadWorkspaceHistoryParams): Promise<string> => {
  const exportTypeParam = exportType.toString().toLowerCase()
  const params = new URLSearchParams()
  params.append('workspace_slug', workspaceSlug)
  params.append('user_tz', userTz)
  if (events.length) {
    params.append('created_before_or_equal', events[0].created)
    params.append('created_after_or_equal', events[events.length - 1].created)
  } else if (dateRange) {
    if (dateRange.from) {
      params.append('created_after_or_equal', dateRange.from.toISOString())
    }
    if (dateRange.to) {
      params.append('created_before_or_equal', dateRange.to.toISOString())
    }
  }
  const response = await Services.Backend.Post<HistoryExportDownloadResponse>(
    `workspace/${exportTypeParam}/download?${params.toString()}`,
    {
      eventIds: events
        .filter((event) => event.kind !== EventKind.WORD_DOCUMENT)
        .map((event) => event.id),
      wordEventIds: events
        .filter((event) => event.kind === EventKind.WORD_DOCUMENT)
        .map((event) => event.id),
    },
    { throwOnError: true }
  )

  const url = await PollForDownload(response.requestId, exportTypeParam)
  return url
}

const PollForDownload = async (
  requestId: string,
  exportTypeParam: string
): Promise<string> => {
  const response =
    await Services.Backend.Get<HistoryExportDownloadPollResponse>(
      `workspace/${exportTypeParam}/download/${requestId}`,
      { throwOnError: true }
    )
  if (response.downloadUrl) {
    return response.downloadUrl
  } else {
    // If the job is still in progress, poll again in 1 second
    return new Promise((resolve, reject) =>
      setTimeout(
        () =>
          PollForDownload(requestId, exportTypeParam)
            .then(resolve)
            // Catch and forward any errors from the recursive call
            .catch(reject),
        1000
      )
    )
  }
}

const FetchAuth0LoginLogsExport = async (
  workspaceId: number
): Promise<string> => {
  // csv string
  const params = new URLSearchParams()
  params.append('workspace_id', workspaceId.toString())
  params.append('last_n_days', '7') // hard default to 7 days for now
  const response = await Services.Backend.Get<string>(
    `internal_admin/login_logs?${params.toString()}`,
    { throwOnError: true }
  )
  return response
}

export {
  FetchHistoryItem,
  makeHistoryUrl,
  FetchHistoryMetadata,
  FetchWorkspaceHistoryMetadata,
  DownloadWorkspaceHistory,
  FetchAuth0LoginLogsExport,
}
