import _ from 'lodash'

import { LanguageCode } from 'openapi/models/LanguageCode'
import { PracticeAreaTaxonomy } from 'openapi/models/PracticeAreaTaxonomy'
import { Profession } from 'openapi/models/Profession'
import { Title } from 'openapi/models/Title'
import { UserInfoUserProfile } from 'openapi/models/UserInfoUserProfile'
import { Maybe } from 'types'

import { ProfileData } from 'components/common/user-profile-store'

import { toTitleCase } from './string'

// TODO(@jhoong): Consider hardcoding this in OpenAPI
const LANGUAGE_MAP: Partial<Record<LanguageCode, string>> = {
  [LanguageCode.EN_US]: 'English (American)',
  [LanguageCode.EN_GB]: 'English (British)',
  [LanguageCode.ES_ES]: 'Spanish (European)',
  [LanguageCode.ES_419]: 'Spanish (Latin American)',
}

export const getLanguageText = (language: LanguageCode): string => {
  if (language in LANGUAGE_MAP) return LANGUAGE_MAP[language]!

  const languageDisplay = new Intl.DisplayNames(['en'], { type: 'language' })
  const regionDisplay = new Intl.DisplayNames(['en'], { type: 'region' })

  const [langCode, regionCode] = language.split('_')

  const languageName =
    languageDisplay.of(langCode.toLowerCase()) || toTitleCase(langCode)

  if (regionCode) {
    const regionName = regionDisplay.of(regionCode) || regionCode
    return `${languageName} (${regionName})`
  }

  return languageName
}

const TITLE_MAP: Partial<Record<Title, string>> = {
  [Title.ADMINISTRATIVE_INNOVATION_IT_OR_OTHER_BUSINESS_ROLE]:
    'Administrative, Innovation, IT or Other Business Role',
}

export const getTitleText = (title: Title) => {
  if (title in TITLE_MAP) return TITLE_MAP[title]
  return toTitleCase(title)
}

export const shouldShowYoe = (primaryProfession: Profession) => {
  return primaryProfession !== Profession.LEGAL
}

export const shouldShowTitle = (primaryProfession: Profession) => {
  return primaryProfession === Profession.LEGAL
}

export const shouldShowPracticeAreas = (
  primaryProfession: Profession,
  title: Maybe<Title>
) => {
  return (
    primaryProfession !== Profession.LEGAL ||
    (!!title &&
      title !== Title.ADMINISTRATIVE_INNOVATION_IT_OR_OTHER_BUSINESS_ROLE &&
      title !== Title.KNOWLEDGE_MANAGER_LIBRARIAN)
  )
}

export const validateUserProfileData = (data: ProfileData) => {
  if (shouldShowTitle(data.primaryProfession) && !data.title) {
    return false
  }

  if (
    shouldShowYoe(data.primaryProfession) &&
    (data.yoe === undefined || data.yoe === null)
  ) {
    return false
  }

  const numPracticeAreas = data.practiceAreas?.length
  if (
    shouldShowPracticeAreas(data.primaryProfession, data.title) &&
    (!numPracticeAreas || numPracticeAreas < 1 || numPracticeAreas > 3)
  ) {
    return false
  }

  return true
}

export const getUserInfoPracticeAreaTaxonomiesIfAvailable = ({
  userProfile,
  availablePracticeAreas,
}: {
  userProfile: Maybe<UserInfoUserProfile>
  availablePracticeAreas?: Set<string>
}): string[] => {
  const availableTaxonomies = Array.from(availablePracticeAreas ?? [])

  return _.uniq(
    userProfile?.practiceAreas
      ?.flatMap((pa) => pa.taxonomies)
      .map((taxonomy) => toTitleCase(taxonomy))
      .filter((taxonomy) => {
        if (!availablePracticeAreas) return true

        return availableTaxonomies
          .map((option) => option.toLowerCase())
          .includes(taxonomy.toLowerCase())
      })
  )
}

/**
 * This will return the available practice areas that the user has selected,
 * excluding the ones based on the user's practice area taxonomies
 *
 * If user has no practice areas, return empty array
 * If the user has both Litigation and Transactional, we exclude neither
 * If the user has only Litigation, we exclude Transactional
 * If the user has only Transactional, we exclude Litigation
 *
 * @param userProfile
 * @param availablePracticeAreas
 * @returns string[]
 */
export const getAvailablePracticeAreasWithExclusions = ({
  userProfile,
  availablePracticeAreas,
}: {
  userProfile: Maybe<UserInfoUserProfile>
  availablePracticeAreas: Set<string>
}): string[] => {
  const userProfileTaxonomies =
    userProfile?.practiceAreas?.flatMap((pa) => pa.taxonomies) ?? []

  if (!userProfileTaxonomies.length) return []

  const userHasLitigation = userProfileTaxonomies.includes(
    PracticeAreaTaxonomy.LITIGATION
  )
  const userHasTransactional = userProfileTaxonomies.includes(
    PracticeAreaTaxonomy.TRANSACTIONAL
  )

  return _.uniq(
    Array.from(availablePracticeAreas).filter((taxonomy) => {
      if (userHasLitigation && !userHasTransactional) {
        return (
          taxonomy.toLowerCase() !==
          PracticeAreaTaxonomy.TRANSACTIONAL.toLowerCase()
        )
      }
      if (userHasTransactional && !userHasLitigation) {
        return (
          taxonomy.toLowerCase() !==
          PracticeAreaTaxonomy.LITIGATION.toLowerCase()
        )
      }
      return true
    })
  )
}
