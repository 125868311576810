import React from 'react'

interface GoogleDriveIconProps {
  className?: string
}

const GoogleDriveIcon: React.FC<GoogleDriveIconProps> = ({
  className = '',
}) => {
  return (
    <img
      className={className}
      src="/img/logos/google-drive.svg"
      alt="Google Drive"
    />
  )
}

export default GoogleDriveIcon
