import React, { useState, useRef, useMemo } from 'react'

import { SortDirection, GridApi, ColDef } from 'ag-grid-community'
import { CustomHeaderProps } from 'ag-grid-react'
import { ArrowDownAZ, ArrowUpAZ, Check, GripVertical, Plus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { TaskStatus } from 'utils/task'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useVaultV2TourStore } from 'components/common/product-tours/vault-v2-tour'
import AttentionDot from 'components/ui/attention-dot/attention-dot'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Popover, PopoverAnchor, PopoverContent } from 'components/ui/popover'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  addNewColumn,
  QuestionColumnDef,
  ADD_COLUMN_WIDTH_WITH_ICON,
  getIconForDataType,
  handleMouseEnter,
  handleMouseLeave,
  handleLeftBorderReset,
  NO_EDIT_PERMISSIONS_TOOLTIP,
  HAS_REACHED_QUESTIONS_LIMIT_TOOLTIP,
} from 'components/vault/query-detail/data-grid-helpers'
import { EXCLUDED_COLIDS_FROM_COLUMN_CLICK } from 'components/vault/query-detail/vault-query-detail'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  QueryQuestion,
  RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE,
  ReviewColumn,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { getNumSelectedRows } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultColumnEditor from './vault-column-editor'

interface SortDropdownProps {
  isDropdownOpen: boolean
  setIsDropdownOpen: (isDropdownOpen: boolean) => void
  sortColumnId: string
  isSortIconHovered: boolean
  setIsSortIconHovered: (isSortIconHovered: boolean) => void
  setSort: (sort: SortDirection, multiSort?: boolean | undefined) => void
}
const SortDropdown = ({
  isDropdownOpen,
  setIsDropdownOpen,
  sortColumnId,
  isSortIconHovered,
  setIsSortIconHovered,
  setSort,
}: SortDropdownProps) => {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(
    'asc'
  )

  const currentSortColumnId = useVaultDataGridFilterStore(
    (state) => state.currentSortColumnId
  )
  const setCurrentSortColumnId = useVaultDataGridFilterStore(
    (state) => state.setCurrentSortColumnId
  )

  const isCurrentSortColumn = sortColumnId === currentSortColumnId
  const isCurrentSortAsc = isCurrentSortColumn && sortDirection === 'asc'
  const isCurrentSortDesc = isCurrentSortColumn && sortDirection === 'desc'
  const isSortIconHidden =
    !isCurrentSortColumn && !isSortIconHovered && !isDropdownOpen

  const sortHandler = (newSortDirection: 'asc' | 'desc') => {
    // using props.setSort instead of props.progressSort because progressSort was not working
    // it required the user to click on the icon twice to sort
    if (isCurrentSortColumn && newSortDirection === sortDirection) return
    setCurrentSortColumnId(sortColumnId)
    setSortDirection(newSortDirection)
    setSort(newSortDirection)
  }

  return (
    <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          size="xsIcon"
          variant="ghost"
          className={cn('rounded-md hover:bg-skeleton-dark', {
            hidden: isSortIconHidden,
            '!bg-skeleton-dark': isDropdownOpen,
          })}
        >
          {isCurrentSortDesc ? (
            <ArrowUpAZ className="h-3 w-3" />
          ) : (
            <ArrowDownAZ className="h-3 w-3" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="space-y-1">
        <DropdownMenuItem
          onClick={(e) => {
            sortHandler('asc')
            setIsSortIconHovered(false)
            e.stopPropagation()
          }}
          className={cn('flex w-full min-w-48 items-center justify-between', {
            'bg-button-secondary': isCurrentSortAsc,
          })}
        >
          <div className="flex items-center gap-1">
            <ArrowDownAZ className="h-3 w-3" />
            <p className="text-xs">Sort ascending</p>
          </div>
          {isCurrentSortAsc && <Check className="h-3 w-3" />}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={(e) => {
            sortHandler('desc')
            setIsSortIconHovered(false)
            e.stopPropagation()
          }}
          className={cn('flex w-full min-w-48 items-center justify-between', {
            'bg-button-secondary': isCurrentSortDesc,
          })}
        >
          <div className="flex items-center gap-1">
            <ArrowUpAZ className="h-3 w-3" />
            <p className="text-xs">Sort descending</p>
          </div>
          {isCurrentSortDesc && <Check className="h-3 w-3" />}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const RowNumberColumnHeader = ({ gridApi }: { gridApi: GridApi }) => {
  const selectedRows = useVaultDataGridFilterStore(
    useShallow((state) => state.selectedRows)
  )
  const bulkAddSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkAddSelectedRows
  )
  const bulkRemoveSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkRemoveSelectedRows
  )

  const { numRows } = getNumSelectedRows(gridApi)
  const isHeaderChecked = selectedRows.length > 0

  const onCheckedChange = () => {
    const nextChecked = !isHeaderChecked
    const rowsToUpdate: string[] = []
    gridApi.forEachNode((node) => {
      if (node.group) return
      // if the next state is checked and the node is not displayed, we don't want to select it
      // if the next state is unchecked we want to unselect it regardless of whether it is displayed
      if (nextChecked && !node.displayed) return
      node.setSelected(nextChecked)
      rowsToUpdate.push(node.data.id)
    })
    if (nextChecked) {
      bulkAddSelectedRows(rowsToUpdate)
    } else {
      bulkRemoveSelectedRows(rowsToUpdate)
    }
  }
  return (
    <div className="flex h-full w-full cursor-default items-center justify-center border-r border-primary px-4">
      <Checkbox
        checked={isHeaderChecked}
        onCheckedChange={onCheckedChange}
        isIndeterminate={selectedRows.length < numRows}
      />
    </div>
  )
}

const AnimateIcon = ({ isDisabled }: { isDisabled: boolean }) => {
  return (
    <AttentionDot isDisabled={isDisabled}>
      <Icon icon={Plus} size="small" />
    </AttentionDot>
  )
}

const AddColumnHeaderCell = ({
  gridApi,
  colDef,
}: {
  gridApi: GridApi
  colDef: ColDef
}) => {
  const { trackEvent } = useAnalytics()
  const colWidth = colDef.width
  const isIconOnly = colWidth === ADD_COLUMN_WIDTH_WITH_ICON

  const [
    historyItem,
    pendingQueryQuestions,
    pendingQueryFileIds,
    hasReachedQuestionsLimit,
    currentPendingColumnId,
    isQueryLoading,
    setIsHoveringAddColumn,
    addToPendingQueryQuestions,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.historyItem,
      state.pendingQueryQuestions,
      state.pendingQueryFileIds,
      state.hasReachedQuestionsLimit,
      state.currentPendingColumnId,
      state.isQueryLoading,
      state.setIsHoveringAddColumn,
      state.addToPendingQueryQuestions,
    ])
  )

  const [currentProject, exampleProjectIds] = useVaultStore(
    useShallow((state) => [state.currentProject, state.exampleProjectIds])
  )

  const isTourActive = useVaultV2TourStore((state) => state.isTourActive)

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const { hasEmptyCells } = useRunReview()
  const canResumeQuery = !isQueryLoading && hasEmptyCells

  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const reviewEvent = historyItem as ReviewHistoryItem

  const hasCurrentPendingColumnId = !!currentPendingColumnId
  const pendingFileIds = pendingQueryFileIds ?? []
  const pendingQuestions = pendingQueryQuestions ?? []
  const numQueryFiles = reviewEvent?.numFiles ?? 0
  const totalFileIds = numQueryFiles + pendingFileIds.length
  const allColumns: (QueryQuestion | ReviewColumn)[] = [
    ...pendingQuestions,
    ...(reviewEvent?.columns ?? []),
  ]
  const isAddColumnDisabled =
    (!gridApi ||
      !canCurrentUserEditProject ||
      hasReachedQuestionsLimit ||
      hasCurrentPendingColumnId ||
      canResumeQuery) &&
    !isTourActive

  const tooltip = useMemo(() => {
    if (hasReachedQuestionsLimit) {
      return HAS_REACHED_QUESTIONS_LIMIT_TOOLTIP
    }
    if (!canCurrentUserEditProject) {
      return NO_EDIT_PERMISSIONS_TOOLTIP
    }
    if (canResumeQuery) {
      return RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE
    }
    return ''
  }, [canCurrentUserEditProject, hasReachedQuestionsLimit, canResumeQuery])

  const onAddColumn = () => {
    trackEvent('Vault Review Query Add Column Button Clicked', {
      query_id: historyItem?.id || 'new',
    })
    handleLeftBorderReset()
    addNewColumn(gridApi, allColumns, addToPendingQueryQuestions)
  }

  const onMouseEnter = () => {
    if (isAddColumnDisabled) return
    handleMouseEnter(gridApi, isIconOnly)
    setIsHoveringAddColumn(true)
  }

  const onMouseLeave = (e: React.MouseEvent) => {
    if (isAddColumnDisabled) return
    const isPinned = colDef?.pinned === 'right'
    handleMouseLeave(e, gridApi, isPinned)
    setIsHoveringAddColumn(false)
  }

  // When there is a current pending column, we want to hide the CTA
  if (currentPendingColumnId || totalFileIds === 0) {
    return <div className="h-full w-full border-r bg-button-secondary" />
  }

  return (
    <div
      className="flex h-full w-full items-center justify-center border-r bg-button-secondary"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Button
        variant={isIconOnly ? 'ghost' : 'unstyled'}
        id="add-column-button"
        className="flex h-full w-full items-center justify-center rounded-none bg-button-secondary"
        onClick={onAddColumn}
        tooltip={tooltip}
        tooltipSide="left"
        tooltipPopoverClassName="max-w-64"
        disabled={isAddColumnDisabled}
      >
        {isIconOnly && <Icon icon={Plus} size="small" />}
        {!isIconOnly && (
          <>
            <AnimateIcon isDisabled={isAddColumnDisabled} />
            <p className="text-xs">Add column</p>
          </>
        )}
      </Button>
    </div>
  )
}

const VaultHeaderCell = (props: CustomHeaderProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState<boolean>(false)

  const [
    historyItem,
    isQueryLoading,
    pendingQueryQuestions,
    currentPendingColumnId,
    setCurrentPendingColumnId,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.historyItem,
      state.isQueryLoading,
      state.pendingQueryQuestions,
      state.currentPendingColumnId,
      state.setCurrentPendingColumnId,
    ])
  )

  const popoverRef = useRef<HTMLDivElement>(null)

  const gridApi = props.api
  const colDef = props.column.getColDef() as QuestionColumnDef
  const colId = props.column.getColId()
  const isSortable = props.column.isSortable()

  const columnType = colDef.type
  const isResizable = props.column.isResizable()
  const isRowNumberColumn = colId === 'row'
  const isAddColumn = colId === 'addColumn'
  const isPendingColumn = pendingQueryQuestions
    ? pendingQueryQuestions.some((question) => question.id === colId)
    : false
  const isUpdatableColumn = !EXCLUDED_COLIDS_FROM_COLUMN_CLICK.includes(colId)
  const columnHeaderName = colDef.headerName
  const columnQuestion = colDef.originalQuestion
  const iconComponent = getIconForDataType(colDef.columnDataType)
  const isExampleProject = colDef.headerComponentParams.isExampleProject
  const doesCurrentUserHaveEditPermission =
    colDef.headerComponentParams.doesCurrentUserHaveEditPermission
  const showGripIcon =
    isHovered &&
    columnType !== 'document' &&
    !isQueryLoading &&
    !isExampleProject &&
    doesCurrentUserHaveEditPermission
  const hasBorderRight = !isResizable
  const onColumnHeaderClicked = () => {
    if (
      EXCLUDED_COLIDS_FROM_COLUMN_CLICK.includes(colId) ||
      !doesCurrentUserHaveEditPermission ||
      isExampleProject
    )
      return
    setCurrentPendingColumnId(colId)
  }

  const shouldShowPopover =
    currentPendingColumnId === colId && isUpdatableColumn

  const isSortableColumn =
    isSortable &&
    !isQueryLoading &&
    (!!colDef.backingReviewColumn || colDef.field === 'name') &&
    historyItem?.status !== TaskStatus.IN_PROGRESS

  if (isRowNumberColumn) {
    return <RowNumberColumnHeader gridApi={gridApi} />
  }

  if (isAddColumn) {
    return <AddColumnHeaderCell gridApi={gridApi} colDef={colDef} />
  }

  return (
    <Popover open={shouldShowPopover}>
      <PopoverAnchor asChild>
        <Button
          variant="unstyled"
          className={cn(
            'flex h-full w-full items-center justify-between rounded-none px-4 hover:bg-button-secondary',
            {
              'border-r': hasBorderRight,
            }
          )}
          tooltip={columnQuestion}
          tooltipDelay={200}
          tooltipPopoverClassName="text-xs max-w-72 whitespace-normal"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onColumnHeaderClicked}
          onContextMenu={(e) => {
            e.preventDefault()
            onColumnHeaderClicked()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
              onColumnHeaderClicked()
            }
          }}
        >
          <div className="flex min-w-0 flex-1 items-center">
            {showGripIcon ? (
              <Icon icon={GripVertical} size="small" className="mr-1" />
            ) : (
              <Icon icon={iconComponent} size="small" className="mr-1" />
            )}
            <p className="truncate text-xs">{columnHeaderName}</p>
          </div>
          {isSortableColumn && (
            <SortDropdown
              isDropdownOpen={isSortDropdownOpen}
              setIsDropdownOpen={setIsSortDropdownOpen}
              sortColumnId={colDef.colId ?? ''}
              isSortIconHovered={isHovered}
              setIsSortIconHovered={setIsHovered}
              setSort={props.setSort}
            />
          )}
        </Button>
      </PopoverAnchor>
      <PopoverContent
        align="start"
        sideOffset={-16}
        className="mt-4 min-w-[320px] "
        ref={popoverRef}
        id="column-editor"
        onOpenAutoFocus={(event: any) => {
          event.preventDefault()
          event.target?.focus()
        }}
      >
        <VaultColumnEditor
          gridApi={gridApi}
          colDef={colDef}
          colId={colId}
          isPendingColumn={isPendingColumn}
          popoverRef={popoverRef}
        />
      </PopoverContent>
    </Popover>
  )
}

export default VaultHeaderCell
