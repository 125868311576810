import React from 'react'

import { cn } from 'utils/utils'

interface PdfIconProps {
  className?: string
}

const PdfIcon: React.FC<PdfIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M17.375 3H7.25C6.65326 3 6.08097 3.23178 5.65901 3.64437C5.23705 4.05694 5 4.61652 5 5.2V22.8C5 23.3835 5.23705 23.9431 5.65901 24.3556C6.08097 24.7682 6.65326 25 7.25 25H20.75C21.3467 25 21.919 24.7682 22.341 24.3556C22.7629 23.9431 23 23.3835 23 22.8V8.5L17.375 3Z"
        fill="#ffffff"
        stroke="#CDCAC6"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77703 19C11.5676 19 14.4054 11.9054 14.4054 9.81081C14.4054 9.37162 14.0338 9 13.6284 9C13.223 9 12.9527 9.50676 12.9527 10.0811C12.9527 13.6622 16.7365 16.5 18.1892 16.5C18.5781 16.5 19 16.3649 19 15.8243C19 15.2838 18.4595 14.9797 17.8176 14.9797C14.4054 14.9797 9 17.1081 9 18.3243C9 18.7635 9.30405 19 9.77703 19Z"
        stroke="#FA0F00"
        strokeWidth="1.25"
      />
    </svg>
  )
}

export default PdfIcon
