import React, { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AssistantWorkflowFilePopover } from './components/workflow-file-popover'
import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import AOLogo from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/ao-logo'
import { AssistantWorkflowThreadBlock } from 'components/assistant/workflows/blocks/assistant-workflow-block-layout'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { ScrollArea } from 'components/ui/scroll-area'

type Params = {
  title: string
  input: React.ReactNode
  thread: React.ReactNode[]
}

export const AssistantWorkflowLayout: React.FC<Params> = ({
  title,
  input,
  thread,
}) => {
  const showAOLogo = title === 'Antitrust Filings'
  const logo = showAOLogo ? <AOLogo className="mb-2 h-2" /> : null
  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const defaultResizablePanelSizes = [50, 50]
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  return (
    <AppMain>
      <AppHeader title="Assistant Workflows" subtitle={title} actions={logo} />
      <ResizablePanelGroup direction="horizontal" ref={resizablePanelGroupRef}>
        <ResizablePanel
          id="thread"
          defaultSize={fileId ? defaultResizablePanelSizes[0] : 100}
          minSize={20}
          order={1}
        >
          <div className="flex h-full flex-col">
            <ScrollArea className="grow space-y-4">{thread}</ScrollArea>
            <AssistantWorkflowThreadBlock>{input}</AssistantWorkflowThreadBlock>
          </div>
        </ResizablePanel>
        {!!fileId && (
          <>
            <ResizableHandle withHandle onDoubleClick={resetLayout} />
            <ResizablePanel
              id="viewer"
              className="z-0"
              defaultSize={defaultResizablePanelSizes[1]}
              minSize={20}
              order={2}
            >
              <AssistantWorkflowFilePopover />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </AppMain>
  )
}
