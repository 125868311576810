import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUnmount } from 'react-use'

import _ from 'lodash'
import { ListFilter, XCircle } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { Event } from 'models/event'
import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { Workspace } from 'models/workspace'
import Services from 'services'

import { SafeRecord } from 'utils/safe-types'
import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { FilterKey, useEventFilterStore } from 'components/filter/filter-store'
import ClientMatterFilter from 'components/filter/instances/client-matter-filter'
import DateRangeFilter from 'components/filter/instances/date-range-filter'
import DocumentCountFilter from 'components/filter/instances/document-count-filter'
import FavoriteFilter from 'components/filter/instances/favorite-filter'
import SearchFilter from 'components/filter/instances/search-filter'
import SharedFilter from 'components/filter/instances/shared-filter'
import SourceFilter from 'components/filter/instances/source-filter'
import TaskTypeFilter from 'components/filter/instances/task-type-filter'
import { ClientMatterRecord } from 'components/filter/types/client-matter-record'
import { RecordBase } from 'components/filter/types/record-base'
import { SharedRecord } from 'components/filter/types/shared-record'
import { useGroupingStore } from 'components/grouping/grouping-store'
import {
  HistoryGrouping,
  HistoryGroupingSearchParamKey,
} from 'components/grouping/history-grouping-def'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'

import HistoryGroupingSelect from './history-grouping-select'
import { useHistoryMetadataStore } from './history-metadata-store'
import { useHistoryStore } from './history-store'
import { useWorkspaceHistoryMetadataStore } from './workspace-history-metadata-store'

interface FilterComponentProps extends AnalyticsContextType {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  entityName?: string
}

interface FilterComponent<T extends RecordBase> {
  filterKey: string
  displayName: string
  render: React.FC
  filterLogic: (record: T) => boolean
}

interface HistoryFilterComponent<T extends RecordBase> {
  Component: (props: FilterComponentProps) => FilterComponent<T>
  filterKey: string
  leading?: boolean // RTL leading -> right
  trailing?: boolean // RTL trailing -> left
  label?: string
}

interface HistoryFilterRowInternalProps<T extends RecordBase> {
  filterComponents: Array<HistoryFilterComponent<T>>
  type: HistoryTypeEnum
  workspace: Workspace
}

const HistoryFilterRowInternal: React.FC<
  HistoryFilterRowInternalProps<Event>
> = ({ filterComponents, type, workspace }) => {
  const [registerFilter, filterValues, setFilterValue, setFilterValues] =
    useEventFilterStore(
      useShallow((s) => [
        s.registerFilter,
        s.filterValues,
        s.setFilterValue,
        s.setFilterValues,
      ])
    )
  const [grouping, setGrouping] = useGroupingStore(
    useShallow((s) => [s.grouping, s.setGrouping])
  )
  const { trackEvent } = useAnalytics()

  const builtComponents = React.useMemo(
    () =>
      filterComponents.map(({ Component, filterKey, ...props }) =>
        Component({
          filterKey: filterKey,
          value: filterValues[filterKey],
          setValue: (value: string) => setFilterValue(filterKey, value),
          trackEvent,
          ...props,
        })
      ),
    [filterComponents, filterValues, setFilterValue, trackEvent]
  )
  const allFilterKeys = React.useMemo(
    () => filterComponents.map(({ filterKey }) => filterKey),
    [filterComponents]
  )
  const filterKeysLeading = React.useMemo(
    () =>
      new Set(
        filterComponents
          .filter(({ leading }) => leading)
          .map(({ filterKey }) => filterKey)
      ),
    [filterComponents]
  )
  const filterKeysTrailing = React.useMemo(
    () =>
      new Set(
        filterComponents
          .filter(({ trailing }) => trailing)
          .map(({ filterKey }) => filterKey)
      ),
    [filterComponents]
  )
  const filterKeysInDropdown = React.useMemo(
    () =>
      new Set(
        filterComponents
          .filter(({ leading, trailing }) => !leading && !trailing)
          .map(({ filterKey }) => filterKey)
      ),
    [filterComponents]
  )
  const hasFilterValues = React.useMemo(
    () => Object.keys(filterValues).length > 0,
    [filterValues]
  )
  const filterCountInDropdown = React.useMemo(() => {
    return Object.keys(filterValues).filter((key) =>
      filterKeysInDropdown.has(key)
    ).length
  }, [filterValues, filterKeysInDropdown])

  React.useEffect(() => {
    builtComponents.forEach(({ filterKey, filterLogic }) => {
      registerFilter(filterKey, filterLogic)
    })

    return () => {
      allFilterKeys.forEach((filterKey) => {
        registerFilter(filterKey, null)
      })
    }
  }, [builtComponents, registerFilter, allFilterKeys])

  const [
    filterValuesAndGroupingLoadedFromUrl,
    setFilterValuesAndGroupingLoadedFromUrl,
  ] = React.useState(false)
  React.useEffect(() => {
    if (filterValuesAndGroupingLoadedFromUrl) {
      return
    }
    setFilterValuesAndGroupingLoadedFromUrl(true)

    const searchParams = new URLSearchParams(location.search)

    const newFilterValues = { ...filterValues }
    allFilterKeys.forEach((filterKey) => {
      const paramValue = searchParams.get(filterKey)
      if (paramValue !== null) {
        newFilterValues[filterKey] = paramValue
      } else {
        delete newFilterValues[filterKey]
      }
    })
    setFilterValues(newFilterValues)

    const grouping = searchParams.get(HistoryGroupingSearchParamKey)
    setGrouping(grouping as HistoryGrouping)
  }, [
    filterValuesAndGroupingLoadedFromUrl,
    filterValues,
    allFilterKeys,
    setFilterValues,
    setGrouping,
  ])

  const navigate = useNavigate()
  const navigateWithFilterValuesAndGrouping = React.useCallback(
    (
      filterValues: SafeRecord<string, string>,
      grouping: HistoryGrouping | undefined
    ) => {
      const searchParams = new URLSearchParams(location.search)
      allFilterKeys.forEach((filterKey) => {
        const value = filterValues[filterKey]
        if (!value || value === '') {
          searchParams.delete(filterKey)
        } else {
          searchParams.set(filterKey, value)
        }
      })
      if (_.isNil(grouping)) {
        searchParams.delete(HistoryGroupingSearchParamKey)
      } else {
        searchParams.set(HistoryGroupingSearchParamKey, grouping)
      }
      if (
        new URLSearchParams(location.search).toString() !==
        searchParams.toString()
      ) {
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        })
      }
    },
    [allFilterKeys, navigate]
  )

  const prevFilterValuesRef = React.useRef<SafeRecord<string, string>>({})
  const prevGroupingRef = React.useRef<HistoryGrouping | undefined>(undefined)
  React.useEffect(() => {
    if (filterValuesAndGroupingLoadedFromUrl) {
      // Only navigate if the current filterValues or grouping are different from the previous ones
      if (
        !_.isEqual(prevFilterValuesRef.current, filterValues) ||
        !_.isEqual(prevGroupingRef.current, grouping)
      ) {
        navigateWithFilterValuesAndGrouping(filterValues, grouping)
        // Update the ref with the current filterValues for the next comparison
        prevFilterValuesRef.current = filterValues
        // Update the ref with the current grouping for the next comparison
        prevGroupingRef.current = grouping
      }
    }
  }, [
    filterValuesAndGroupingLoadedFromUrl,
    filterValues,
    prevFilterValuesRef,
    grouping,
    prevGroupingRef,
    navigateWithFilterValuesAndGrouping,
  ])

  useUnmount(() => {
    if (filterValuesAndGroupingLoadedFromUrl) {
      navigateWithFilterValuesAndGrouping({}, undefined)
    }
  })

  return (
    <div className="flex flex-wrap items-center gap-2">
      {builtComponents
        .filter(({ filterKey }) => filterKeysTrailing.has(filterKey))
        .map((component) => component.render({}))}
      {(hasFilterValues || !_.isNil(grouping)) && (
        <Button
          variant="dashed"
          onClick={() => {
            Services.HoneyComb.Record({
              metric: 'ui.history_reset_filters',
              type: type,
              workspace_slug: workspace.slug,
              filter_values: filterValues,
              grouping: grouping,
            })
            trackEvent(FILTER_CHANGED_EVENT_NAME, {
              entity_name: 'history table',
              field_changed: 'all reset',
            })
            if (hasFilterValues) {
              setFilterValues({})
            }
            if (!_.isNil(grouping)) {
              setGrouping(undefined)
            }
          }}
          className="text-muted"
        >
          <XCircle className="mr-2 size-4" />
          Reset
        </Button>
      )}
      <HistoryGroupingSelect />
      {filterKeysInDropdown.size > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="data-[state=open]:bg">
            <Button
              variant="outline"
              className={cn('justify-between', {
                'text-muted': filterCountInDropdown === 0,
              })}
            >
              <ListFilter className="mr-2 size-4 opacity-50" />
              Filters
              {filterCountInDropdown > 0 && (
                <Badge
                  variant="secondary"
                  className="ml-2 min-w-6 justify-center px-1"
                >
                  {filterCountInDropdown}
                </Badge>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex flex-col gap-4 p-4" align="end">
            <h2 className="my-0.5 text-sm font-semibold leading-none">
              Additional filters
            </h2>
            <div className="flex flex-col gap-2">
              {builtComponents
                .filter(({ filterKey }) => filterKeysInDropdown.has(filterKey))
                .map((component) => (
                  <div
                    key={component.filterKey}
                    className="flex h-8 items-center justify-between"
                  >
                    <p className="mr-4 text-muted">{component.displayName}</p>
                    {component.render({})}
                  </div>
                ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {builtComponents
        .filter(({ filterKey }) => filterKeysLeading.has(filterKey))
        .map((component) => component.render({}))}
    </div>
  )
}

interface HistoryFilterRowProps {
  type: HistoryTypeEnum
  workspace: Workspace
}

const HistoryFilterRow: React.FC<HistoryFilterRowProps> = (props) => {
  const { type, workspace } = props
  const userInfo = useAuthUser()
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const [
    workspaceHistoryTaskTypes,
    workspaceHistoryClientMatters,
    getWorkspaceClientMatterName,
  ] = useWorkspaceHistoryMetadataStore(
    useShallow((s) => [
      s.historyTaskTypes,
      s.historyClientMatters,
      s.getClientMatterName,
    ])
  )

  const [
    userHistoryTaskTypes,
    userHistoryClientMatters,
    getUserClientMatterName,
    getFavoriteStatus,
  ] = useHistoryMetadataStore(
    useShallow((s) => [
      s.historyTaskTypes,
      s.historyClientMatters,
      s.getClientMatterName,
      s.getFavoriteStatus,
    ])
  )
  const getClientMatterName = React.useCallback(
    (event: ClientMatterRecord) => {
      if (type === HistoryTypeEnum.WORKSPACE) {
        return getWorkspaceClientMatterName(workspace.slug, event)
      } else {
        return getUserClientMatterName(event)
      }
    },
    [getWorkspaceClientMatterName, getUserClientMatterName, workspace, type]
  )
  const historyDataSource = useHistoryStore(useShallow((s) => s.dataSource))

  const historyTaskTypes = React.useMemo(
    () =>
      type === HistoryTypeEnum.WORKSPACE
        ? workspaceHistoryTaskTypes[workspace.slug] ?? []
        : userHistoryTaskTypes,
    [type, workspace, workspaceHistoryTaskTypes, userHistoryTaskTypes]
  )
  const historyClientMatters = React.useMemo(
    () =>
      type === HistoryTypeEnum.WORKSPACE
        ? workspaceHistoryClientMatters[workspace.slug] ?? []
        : userHistoryClientMatters,
    [
      type,
      workspaceHistoryClientMatters,
      workspace?.slug,
      userHistoryClientMatters,
    ]
  )
  const allTaskTypeStrings = React.useMemo(
    () => new Set(historyTaskTypes.map((taskType) => taskType.toString())),
    [historyTaskTypes]
  )
  const sortedTaskTypes = React.useMemo(
    () =>
      _.sortBy(
        Object.keys(taskLabelLookup)
          .filter((type) => allTaskTypeStrings.has(type))
          .map((type) => {
            const text = taskLabelLookup[type]
            return { text, value: type }
          }),
        'text'
      ),
    [taskLabelLookup, allTaskTypeStrings]
  )
  const allHistorySourceTypes = React.useMemo(() => {
    return Array.from(
      new Set(historyDataSource.map((record) => record.source))
    ).filter(Boolean)
  }, [historyDataSource])

  const sortedSourceTypes = React.useMemo(() => {
    return _.sortBy(
      allHistorySourceTypes.map((sourceType) => ({
        text: sourceType,
        value: sourceType,
      })),
      'text'
    )
  }, [allHistorySourceTypes])

  const setWorkspaceRetentionPolicyInSeconds = useEventFilterStore(
    (s) => s.setWorkspaceRetentionPolicyInSeconds
  )
  React.useEffect(() => {
    setWorkspaceRetentionPolicyInSeconds(workspace.retentionPeriod ?? 0)
  }, [workspace.retentionPeriod, setWorkspaceRetentionPolicyInSeconds])

  const filterComponents = React.useMemo(() => {
    const filterComponents: Array<HistoryFilterComponent<Event>> = [
      {
        Component: SearchFilter,
        filterKey: FilterKey.SEARCH,
        leading: true,
        placeholderText: 'Search history',
      } as HistoryFilterComponent<Event>,
    ]
    filterComponents.push({
      Component: DateRangeFilter,
      filterKey: FilterKey.DATE_RANGE,
      trailing: true,
      workspaceRetentionPolicyInSeconds: workspace.retentionPeriod,
    } as HistoryFilterComponent<Event>)

    if (type !== HistoryTypeEnum.WORKSPACE) {
      filterComponents.push({
        Component: FavoriteFilter,
        filterKey: FilterKey.FAVORITE,
        isFavorite: getFavoriteStatus,
      } as HistoryFilterComponent<Event>)
    }

    if (userInfo.workspace.sharingSettings.assistant.enabled) {
      filterComponents.push({
        Component: SharedFilter,
        filterKey: FilterKey.SHARED,
        isShared: (record: SharedRecord) => !!record.isShared,
      } as HistoryFilterComponent<Event>)
    }

    if (userInfo.isClientMattersReadUser) {
      filterComponents.push({
        Component: ClientMatterFilter,
        filterKey: FilterKey.CLIENT_MATTER,
        allEventClientMatters: historyClientMatters,
        getClientMatterName,
        entityName: 'client matter selector',
        label: 'Filter by client matter',
      } as HistoryFilterComponent<Event>)
    }

    filterComponents.push({
      Component: TaskTypeFilter,
      filterKey: FilterKey.TASK_TYPE,
      userInfo,
      taskLabelLookup,
      sortedTaskTypes,
      entityName: 'history table',
      label: 'Filter by task type',
    } as HistoryFilterComponent<Event>)

    filterComponents.push({
      Component: DocumentCountFilter,
      filterKey: FilterKey.DOCUMENT_COUNT,
      label: 'Filter by document count',
    })

    filterComponents.push({
      Component: SourceFilter,
      filterKey: FilterKey.SOURCE,
      sortedSourceTypes,
      label: 'Filter by source',
    } as unknown as HistoryFilterComponent<Event>)

    return filterComponents
  }, [
    type,
    userInfo,
    taskLabelLookup,
    sortedTaskTypes,
    workspace.retentionPeriod,
    getFavoriteStatus,
    historyClientMatters,
    getClientMatterName,
    sortedSourceTypes,
  ])

  return (
    <HistoryFilterRowInternal filterComponents={filterComponents} {...props} />
  )
}

export default HistoryFilterRow
