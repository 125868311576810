import React from 'react'
import { useEffect } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import pluralize from 'pluralize'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { WorkflowFileUploadInputBlockBlockParams } from 'openapi/models/WorkflowFileUploadInputBlockBlockParams'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { useFileCache } from 'stores/file-cache'
import { FileType, FileTypeReadableName } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { bytesToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'

import { FileItem } from 'components/assistant/components/assistant-files'
import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import DocumentCard from 'components/assistant/workflows/components/document-card/document-card'
import MultipleDocumentsCard from 'components/assistant/workflows/components/multiple-documents-card/multiple-documents-card'
import { useSetViewingFile } from 'components/assistant/workflows/components/workflow-file-popover'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { Button } from 'components/ui/button'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

const DEFAULT_BLOCK_PARAMS: WorkflowFileUploadInputBlockBlockParams = {
  headerText: 'Upload a file',
  maxFileSizeBytes: 1024 * 1024 * 10, // 10MB
  maxTotalFileSizeBytes: 1024 * 1024 * 10, // 10MB
  minFileCount: 1,
  maxFileCount: 1,
  acceptedFileMimeTypes: ['application/pdf'],
}

interface BlockStoreState {
  uploadedFiles: UploadedFile[] | null
  isLoading: boolean
}

interface BlockStoreActions {
  setUploadedFiles: (file: UploadedFile[] | null) => void
  setIsLoading: (loading: boolean) => void
}

const initialState: BlockStoreState = {
  uploadedFiles: null,
  isLoading: false,
}

// TODO: Need to somehow reset the store at certain points
const createBlockStore = () =>
  create(
    devtools(
      immer<BlockStoreState & BlockStoreActions>((set) => ({
        ...initialState,

        reset: () => set(() => initialState),
        setUploadedFiles: (files) =>
          set((state) => {
            state.uploadedFiles = files
          }),
        setIsLoading: (loading) =>
          set((state) => {
            state.isLoading = loading
          }),
      }))
    )
  )

// TODO: Can we make this zudstand stuff more generic for other blocks?
export const useBlockStore = (
  stepIdx: number
): ReturnType<typeof createBlockStore> => {
  const [blockStores, addBlockStore] = useAssistantWorkflowStore(
    useShallow((state) => [state.blockStores, state.addBlockStore])
  )

  if (!blockStores[stepIdx]) {
    const blockStore = createBlockStore()
    addBlockStore(stepIdx, blockStore)
    return blockStore
  }

  return blockStores[stepIdx]
}

type FileToShow = UploadedFile & { fileType?: FileType; description: string }

const renderFiles = (
  files: FileToShow[] | null,
  onFileClick: (file: FileToShow) => void
) => {
  if (!files || !files.length) return null
  if (files.length === 1) {
    return (
      <AssistantWorkflowYouComponent>
        <div className="mt-1 flex flex-col gap-2">
          <DocumentCard
            key={files[0].id}
            onClick={() => onFileClick(files[0])}
            fileType={files[0].fileType}
            title={files[0].name}
            description={files[0].description}
          />
        </div>
      </AssistantWorkflowYouComponent>
    )
  } else {
    return (
      <AssistantWorkflowYouComponent>
        <MultipleDocumentsCard files={files} />
      </AssistantWorkflowYouComponent>
    )
  }
}

export const AssistantWorkflowFileUploadThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ blockParams: _blockParams, outputData, stepIdx }) => {
  const blockParams = { ...DEFAULT_BLOCK_PARAMS, ..._blockParams }
  const { headerText } = blockParams
  const [uploadedFiles, setUploadedFiles] = useBlockStore(stepIdx)(
    useShallow((state) => [state.uploadedFiles, state.setUploadedFiles])
  )
  const getFile = useFileCache((state) => state.getFile)
  const setViewingFile = useSetViewingFile()

  useEffect(() => {
    if (uploadedFiles === null && outputData !== null) {
      // TODO: Error handling
      Promise.all((outputData.fileIds || []).map(getFile))
        .then((files) =>
          setUploadedFiles(
            files.filter((f): f is UploadedFile => f !== undefined)
          )
        )
        .catch((e) => {
          console.error(e)
        })
    }
  }, [uploadedFiles, outputData, setUploadedFiles, getFile])

  const onFileClick = (file: UploadedFile) => {
    setViewingFile(file)
  }

  const filesToShow =
    uploadedFiles?.reduce((acc: FileToShow[], file) => {
      if (outputData?.fileIds.includes(file.id)) {
        const fileType = file.contentType as FileType | null | undefined
        let description = ''
        if (fileType) {
          description = `${FileTypeReadableName[fileType]} document • ${
            file.size ? bytesToReadable(file.size) : ''
          }`
        } else {
          description = `Document • ${
            file.size ? bytesToReadable(file.size) : ''
          }`
        }
        acc.push({ ...file, fileType: fileType || undefined, description })
      }
      return acc
    }, []) || null

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
      </AssistantWorkflowHarveyComponent>

      {renderFiles(filesToShow, onFileClick)}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFileUploadInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ blockParams: _blockParams, onCompleted, stepIdx, outputData }) => {
  const blockParams = { ...DEFAULT_BLOCK_PARAMS, ..._blockParams }
  const [isLoading, uploadedFiles, setUploadedFiles, setIsLoading] =
    useBlockStore(stepIdx)(
      useShallow((state) => [
        state.isLoading,
        state.uploadedFiles,
        state.setUploadedFiles,
        state.setIsLoading,
      ])
    )

  const addFile = useFileCache((state) => state.addFile)

  const onSubmit = () => {
    onCompleted({ fileIds: uploadedFiles?.map((f) => f.id) ?? [] })
  }

  const handleRemoveFile = (file: UploadedFile | any) => {
    setUploadedFiles(
      uploadedFiles?.filter((f) => f.id !== file.id) ?? uploadedFiles
    )
  }

  const handleRemoveAll = () => {
    setUploadedFiles(null)
  }

  const handleAcceptedFiles = async (files: File[]) => {
    if (!files.length) {
      displayErrorMessage('No files were accepted')
      return
    }
    // TODO: Do we have a batch upload API?
    // TODO: Error handling
    Promise.all(
      files.map((file) =>
        uploadFile(file).then((uploaded) => {
          addFile(uploaded) // Add to file cache for quick retrieval
          return uploaded
        })
      )
    )
      .then((newUploadedFiles) => {
        setUploadedFiles([...newUploadedFiles, ...(uploadedFiles ?? [])])
      })
      .catch((e) => {
        displayErrorMessage('Failed to upload file')
        console.error(e)
      })
      // TODO: It's weird to set isLoading to false here and true in onFileDrop
      .finally(() => setIsLoading(false))
  }

  const onFileDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    // TODO: Handle file rejections
    setIsLoading(true)
    return onDrop({
      acceptedFiles: acceptedFiles,
      fileRejections,
      currentFileCount: uploadedFiles?.length ?? 0,
      maxFiles: blockParams.maxFileCount!,
      acceptedFileTypes: blockParams.acceptedFileMimeTypes! as FileType[],
      maxFileSize: blockParams.maxFileSizeBytes!,
      maxTotalFileSizeProps: {
        maxTotalFileSize: blockParams.maxTotalFileSizeBytes!,
        currentTotalFileSize:
          uploadedFiles?.reduce((total, file) => total + (file.size ?? 0), 0) ??
          0,
      },
      handleAcceptedFiles,
    })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    maxFiles: blockParams.maxFileCount,
    maxSize: blockParams.maxTotalFileSizeBytes,
  })

  const footer = uploadedFiles?.length
    ? `${uploadedFiles.length} ${pluralize(
        'file',
        uploadedFiles.length
      )} attached`
    : 'Upload a file'

  const isSkippable = blockParams.minFileCount === 0
  const componentDisabled = !!isLoading || !!outputData
  const requiresFile = !uploadedFiles?.length && !isSkippable
  // TODO: Construct actual input component
  return (
    <WorkflowInput
      submit={{
        onClick: onSubmit,
        disabled: requiresFile || componentDisabled,
      }}
      footer={footer}
    >
      {uploadedFiles?.length ? (
        <div className="space-y-2 rounded-md bg-primary p-4">
          <div className="flex justify-between">
            <h4>Files</h4>
            <div className="space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={open}
                disabled={
                  componentDisabled ||
                  uploadedFiles.length >= (blockParams.maxFileCount ?? 1)
                }
              >
                Attach more
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={handleRemoveAll}
                disabled={componentDisabled}
              >
                Remove all
              </Button>
            </div>
          </div>

          <ul className="h-full divide-y py-2">
            {uploadedFiles.map((file, i) => (
              <FileItem
                key={i}
                file={file}
                handleRemoveFile={handleRemoveFile}
                isRemoveDisabled={componentDisabled}
              />
            ))}
          </ul>
        </div>
      ) : (
        <Dropzone
          className="h-60 rounded-md bg-primary"
          isLoading={isLoading}
          description={
            <DropzoneDescription
              fileTypes={blockParams.acceptedFileMimeTypes as FileType[]}
              maxSize={bytesToReadable(blockParams.maxFileSizeBytes!)}
            >
              <Button size="sm" variant="outline" className="mt-2">
                Browse files
              </Button>
            </DropzoneDescription>
          }
          dropzone={{ getRootProps, getInputProps }}
        />
      )}
    </WorkflowInput>
  )
}
