import React from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useIntegrationsStore } from 'stores/integrations-store'
import { FileType, FileTypeToExtension } from 'types/file'

import { Button } from 'components/ui/button'

import GoogleDriveLogo from './google-drive-logo'
import { IntegrationType } from 'openapi/models/IntegrationType'

interface Props {
  onUploadFromGoogleDrive: (files: File[]) => Promise<void>
  acceptedFileTypes: FileType[]
  size?: 'sm' | 'lg'
}

const GoogleDriveButton: React.FC<Props> = ({
  onUploadFromGoogleDrive,
  acceptedFileTypes,
  size = 'sm',
}) => {
  const [setIntegrationFilePickerOpenState] = useIntegrationsStore(
    useShallow((state) => [state.setIntegrationFilePickerOpenState])
  )

  const handleGoogleDriveClick = () => {
    setIntegrationFilePickerOpenState({
      acceptedFileTypes: _.flatMap(
        acceptedFileTypes,
        (type) => FileTypeToExtension[type] ?? []
      ),
      onUploadFromIntegration: onUploadFromGoogleDrive,
      integrationType: IntegrationType.GOOGLE_DRIVE,
    })
  }

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        handleGoogleDriveClick()
      }}
      onKeyDown={(e) => {
        e.stopPropagation()
        handleGoogleDriveClick()
      }}
      size={size}
      variant="outline"
      className="pointer-events-auto w-full px-3"
    >
      <GoogleDriveLogo />
    </Button>
  )
}

export default GoogleDriveButton
