export const SEC_HELP_SHORT = `<h4>About</h4>
<p class="ql-align-justify">Harvey will answer questions or generate new content based on annual reports (Form 10-K), quarterly reports (Form 10-Q), annual proxy statements (Form DEF 14A), and current reports (Form 8-K) by searching EDGAR. The output includes linked references to highlighted sections of source documents so that you can check Harvey’s work.</p>`

export const SEC_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey will answer questions or generate new content based on annual reports (Form 10-K), quarterly reports (Form 10-Q), annual proxy statements (Form DEF 14A), and current reports (Form 8-K) by searching EDGAR. The output includes linked references to highlighted sections of source documents so that you can check Harvey’s work.</p>
<h4>Querying tips</h4>
<ul>
 <li>Select the filing type(s) you would like Harvey to search (10-K and/or 10-Q and/or DEF 14A and/or 8-K).</li>
 <li>Specify the filing year(s) you would like Harvey to search. Otherwise, Harvey will infer which filing year(s) to search based on your query. Harvey can review multiple years of filings at once.</li>
 <li>Identify a company or companies by name, stock ticker, or industry.</li>
 <li>For companies that have undergone a change that resulted in a new stock ticker more recently than 2021, include the exact ticker symbol in your query to help Harvey find the company in EDGAR.</li>
 <li>Consider asking for a table, email, memo, outline, bullet points or other format.</li>
 <li>Consider asking for an informal, professional, firm, or other tone.</li>
 <li>Consider specifying the intended audience (e.g., “a client with little acquisition experience” or “a senior in-house attorney”).</li>
 <li>Queries should be explicit, descriptive, and specific. Providing some extra direction in a query can go a long way (e.g., saying “holders of Company Common Stock” rather than “common holders”, or saying “written legal opinion” rather than “legal opinion”).</li>
 <li>Break up complex queries into simpler components, especially when there is an analytical or subjective task contained in your query. We recommend one task per sentence in your query.</li>
</ul>
<h4>Important reminders</h4>
<ul>
 <li>Limited to annual reports, quarterly reports, annual proxy statements, and current reports filed on EDGAR in HTML format. More recent filings are more likely to be in this format.</li>
 <li>Better at understanding language than performing numerical operations or recognizing pictures or data tables.</li>
 <li>Currently less reliable results for questions that require model to wholly understand one filing while reviewing another (e.g., comparing one entire filing to another).</li>
 <li>Harvey’s answers may vary based on subtle changes to query language and even across multiple submissions of the same query. Consider refining your query to explore different answers.</li>
 <li>You do not need to ask Harvey for references or citations — they will be generated automatically.</li>
</ul>
<h4>Example queries</h4>
<ul>
 <li>Summarize the material agreements disclosed by Netflix during 2022 and 2023.</li>
 <li>Find examples of technology companies that have filed 8-Ks regarding changes to management or the board of directors.</li>
 <li>Provide examples of insurance companies that recently disclosed amendments to governing documents.</li>
 <li>How are real estate companies disclosing risks relating to remote work?</li>
 <li>Compare the streaming business of Disney and Comcast over the past five years.</li>
 <li>Analyze the skills of Ford’s board of directors and identify any potential gaps based on its business.</li>
 <li>Describe how Procter &amp; Gamble’s ESG disclosure approach has changed over the past five years.</li>
 <li>Draft an email to a client in the agriculture company identifying the peer companies that have included a risk factor in their most recent 10-K regarding the war in Ukraine, and suggesting they consider doing the same.</li>
 <li>What did Johnson &amp; Johnson’s peer companies include as industry-specific risk factors in their most recent 10-Ks?</li>
 <li>What are the common climate related disclosures among tourism companies?</li>
 <li>What are credit card companies saying about product innovation in recent 10-Ks?</li>
</ul>`

export const MEMOS_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey will answer questions or generate new content based on memos from law firms.</p>`

export const EURLEX_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey will answer questions or generate new content based on court cases from EUR-Lex.</p>`

export const USA_CASELAW_HELP_SHORT = `<h4>About</h4>
<p class="ql-align-justify">Harvey’s US Case Law tool can be used to answer questions and conduct research about case law in the United States.</p>
<p class="ql-align-justify">To begin, select the jurisdiction you’d like to research. Only one jurisdiction can be selected at a time. You can also optionally select a date range. These filters will determine the cases Harvey researches to help generate an answer.</p>`

export const FRANCE_CASE_LAW_HELP_SHORT = `<h4>About</h4>
<p class="ql-align-justify">Harvey’s French Case Law tool can be used to answer questions and conduct research about case law in France.</p>
<p class="ql-align-justify">To begin, select the jurisdiction you’d like to research. Only one jurisdiction can be selected at a time. You can also optionally select a date range. These filters will determine the cases Harvey researches to help generate an answer.</p>`

export const USA_CASELAW_HELP = `<h4>Tips for using Case Law</h4>
<ul>
  <li>Queries should be explicit, descriptive, and specific.</li>
  <li>If you have a complex research problem or scenario, try breaking it up into simpler questions for Harvey to get more precise and detailed answers to individual components of your problem. This is especially helpful if there are multiple levels of analysis contained within your query.</li>
  <li>Where applicable, provide context about the area of law relevant to your query. For example, if asking Harvey about motions to dismiss, you may want to specify whether your query relates to civil or criminal cases (or both).</li>
  <li>Harvey will typically answer in an outline format using bullet points or numbered lists. If you prefer a different format for your response, specify the desired format (e.g., essay) in your prompt. </li>
</ul>
<h4>Important reminders</h4>
<ul>
  <li>You do not need to ask Harvey for references or citations — they will be generated automatically.</li>
  <li>Harvey’s answer may discuss or reference cases that fall outside of your filters, because those cases are referenced or cited by the cases that do fall within the scope of your filters.</li>
  <li>Always double-check outputs for accuracy and completeness. Hallucinations are rare but may occur. Pay particular attention to cases identified by a database identifier and electronic report number (e.g., “2005 U.S. Dist. LEXIS 1234” or “2005 WL 12345”), as the electronic report number may be inaccurate. Note that cases and snippets provided in footnotes will always be accurate, primary source material.</li>
  <li>Responses may vary based on subtle changes to query language and even across multiple submissions of the same query. Consider refining or retrying your query to explore different answers.</li>
</ul>
`

export const UK_TAX_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey’s Tax AI Assistant is a system built to answer questions related to key databases of United Kingdoms tax law.  The system currently answers questions based on documents from: <b>HMRC</b>, <b>Legislation</b>, <b>Tax Case Law</b>, and <b>OECD</b>: </p>

<b>1. HMRC</b>
<ul></ul>
<b>2. Legislation</b>
<ul>
  <li> Selected UK Finance Acts 1895 - 2023</li>
  <li> Capital Allowances Act 2001 </li>
  <li> Corporation Tax Act 2009 &amp; 2010 </li>
  <li> Customs and Excise Management Act 1979 </li>
  <li> The Customs (Export) (EU Exit) Regulations 2019 </li>
  <li> Customs (Import Duty) (EU Exit) Regulations 2018 </li>
  <li> Financial Services and Markets Act 2000 Parts XVII &amp; XVIII </li>
  <li> Government of Wales Act 2006 Part 4A </li>
  <li> Income Tax (Earnings and Pensions) Act 2003 </li>
  <li> Income Tax (Trading and Other Income) Act 2005 </li>
  <li> Income Tax Act 2007 </li>
  <li> The Income Tax (Construction Industry Scheme) Regulations 2005 </li>
  <li> The Income Tax (Pay As You Earn) Regulations 2003 </li>
  <li> Inheritance Tax Act 1984 </li>
  <li> The Inheritance Tax (Delivery of Accounts) (Excepted Estates) Regulations 2004 </li>
  <li> Land Transaction Tax and Anti-Avoidance of Devolved Taxes (Wales) Act 2017 </li>
  <li> Land and Buildings Transaction Tax (Scotland) Act 2013 </li>
  <li> Revenue Scotland and Tax Powers Act 2014 </li>
  <li> SI 2006/206 regulation 2 (original as made) </li>
  <li> Social Security Contributions and Benefits Act 1992 (awaiting further updates) </li>
  <li> The Social Security (Contributions) Regulations 2001 </li>
  <li> Stamp Act &amp; Stamp Duties Management Acts </li>
  <li> The Stamp Duty Land Tax (Administration) Regulations 2003 </li>
  <li> Tax Collection and Management (Wales) Act 2016 </li>
  <li> Taxation (International and Other Provisions) Act 2010 </li>
  <li> Taxation of Chargeable Gains Act 1992 </li>
  <li> Taxes Management Act 1970 </li>
  <li> Territorial Sea Act 1987 </li>
  <li> The Offshore Funds (Tax) Regulations 2009 reg 75 </li>
  <li> Value Added Tax Act 1994 </li>
  <li> Value Added Tax Regulations 1995 </li>
  <li> Value Added Tax (Place of Supply of Goods) Order 2004, (Special Provisions) Order 1995, (Input Tax) Order 1992, (Cars) Order 1992</li>
  <li> The Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 </li>
  <li> National Minimum Wage Regulations 2015 </li>
</ul>
<b>3. Tax Case Law</b>
<ul>
  <li> First tier Tax Tribunal Decisions </li>
  <li> Upper Tribunal Decisions (Tax Chamber) </li>
  <li> Special Commissioners' Decisions </li>
  <li> Court of Appeal Decisions </li>
  <li> Supreme Court Decisions </li>
  <li> House of Lords Decisions </li>
  <li> European Court of Justice </li>
</ul>

<b>4. OECD
</ul>`

export const UK_TAX_HELP_PWC = `<h4>About</h4>
<p class="ql-align-justify">Harvey’s Tax AI Assistant is a system built to answer questions related to key databases of United Kingdoms tax law.  The system currently answers questions based on documents from: <b>HMRC</b>, <b>Legislation</b>, <b>Tax Case Law</b>, <b>PwC Internal Papers</b>, and <b>OECD</b>: </p>

<b>1. HMRC</b>
<ul></ul>
<b>2. Legislation</b>
<ul>
  <li> Selected UK Finance Acts 1895 - 2023</li>
  <li> Capital Allowances Act 2001 </li>
  <li> Corporation Tax Act 2009 &amp; 2010 </li>
  <li> Customs and Excise Management Act 1979 </li>
  <li> The Customs (Export) (EU Exit) Regulations 2019 </li>
  <li> Customs (Import Duty) (EU Exit) Regulations 2018 </li>
  <li> Financial Services and Markets Act 2000 Parts XVII &amp; XVIII </li>
  <li> Government of Wales Act 2006 Part 4A </li>
  <li> Income Tax (Earnings and Pensions) Act 2003 </li>
  <li> Income Tax (Trading and Other Income) Act 2005 </li>
  <li> Income Tax Act 2007 </li>
  <li> The Income Tax (Construction Industry Scheme) Regulations 2005 </li>
  <li> The Income Tax (Pay As You Earn) Regulations 2003 </li>
  <li> Inheritance Tax Act 1984 </li>
  <li> The Inheritance Tax (Delivery of Accounts) (Excepted Estates) Regulations 2004 </li>
  <li> Land Transaction Tax and Anti-Avoidance of Devolved Taxes (Wales) Act 2017 </li>
  <li> Land and Buildings Transaction Tax (Scotland) Act 2013 </li>
  <li> Revenue Scotland and Tax Powers Act 2014 </li>
  <li> SI 2006/206 regulation 2 (original as made) </li>
  <li> Social Security Contributions and Benefits Act 1992 (awaiting further updates) </li>
  <li> The Social Security (Contributions) Regulations 2001 </li>
  <li> Stamp Act &amp; Stamp Duties Management Acts </li>
  <li> The Stamp Duty Land Tax (Administration) Regulations 2003 </li>
  <li> Tax Collection and Management (Wales) Act 2016 </li>
  <li> Taxation (International and Other Provisions) Act 2010 </li>
  <li> Taxation of Chargeable Gains Act 1992 </li>
  <li> Taxes Management Act 1970 </li>
  <li> Territorial Sea Act 1987 </li>
  <li> The Offshore Funds (Tax) Regulations 2009 reg 75 </li>
  <li> Value Added Tax Act 1994 </li>
  <li> Value Added Tax Regulations 1995 </li>
  <li> Value Added Tax (Place of Supply of Goods) Order 2004, (Special Provisions) Order 1995, (Input Tax) Order 1992, (Cars) Order 1992</li>
  <li> The Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 </li>
  <li> National Minimum Wage Regulations 2015 </li>
</ul>
<b>3. Tax Case Law</b>
<ul>
  <li> First tier Tax Tribunal Decisions </li>
  <li> Upper Tribunal Decisions (Tax Chamber) </li>
  <li> Special Commissioners' Decisions </li>
  <li> Court of Appeal Decisions </li>
  <li> Supreme Court Decisions </li>
  <li> House of Lords Decisions </li>
  <li> European Court of Justice </li>
</ul>

<b>4. (~150) PwC Internal Papers</b>

<b>5. OECD
</ul>`

export const JAPAN_TAX_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey’s Tax AI Assistant is a system built to answer questions related to key databases of Japanese tax law.</p>
<p>The system currently responds to queries based on the following sources:</p>
<ul>
  <li>NTA Notices of Law Interpretation</li>
  <li>NTA Frequently Asked Tax Questions</li>
  <li>NTA Q&A Examples</li>
  <li>NTA Transfer Pricing Administrative Guidance</li>
  <li>法人税法 (Corporate Tax Law)</li>
  <li>法人税法施行令 (Corporate Tax Law Enforcement Order)</li>
  <li>法人税法施行規則 (Corporate Tax Law Enforcement Regulations)</li>
  <li>減価償却資産の耐用年数等に関する省令 (Ministerial Ordinance on the Useful Life of Depreciable Assets, etc.)</li>
  <li>地方法人税法 (Local Corporation Tax Law)</li>
  <li>地方法人税法施行令 (Local Corporation Tax Law Enforcement Order)</li>
  <li>地方法人税法施行規則 (Local Corporation Tax Law Enforcement Regulations)</li>
  <li>消費税法 (Consumption Tax Law)</li>
  <li>消費税法施行令 (Consumption Tax Law Enforcement Order)</li>
  <li>消費税法施行規則 (Consumption Tax Law Enforcement Regulations)</li>
  <li>相続税法 (Inheritance Tax Law)</li>
  <li>相続税法施行令 (Inheritance Tax Law Enforcement Order)</li>
  <li>相続税法施行規則 (Inheritance Tax Law Enforcement Regulations)</li>
  <li>所得税法 (Income Tax Law)</li>
  <li>所得税法施行令 (Income Tax Law Enforcement Order)</li>
  <li>所得税法施行規則 (Income Tax Law Enforcement Regulations)</li>
  <li>東日本大震災からの復興のための施策を実施するために必要な財源の確保に関する特別措置法 (Act on Special Measures Concerning Securing the Financial Resources Necessary to Implement Measures for Recovery from the Great East Japan Earthquake)</li>
  <li>復興特別所得税に関する政令 (Cabinet Order Regarding Special Income Tax for Reconstruction)</li>
  <li>地方税法 (Local Tax Law)</li>
  <li>地方税法施行令 (Local Tax Law Enforcement Order)</li>
  <li>地方税法施行規則 (Local Tax Law Enforcement Regulations)</li>
  <li>国税通則法 (National Tax General Rules Act)</li>
  <li>国税通則法施行令 (National Tax General Rules Law Enforcement Order)</li>
  <li>国税通則法施行規則 (Enforcement Regulations of the National Tax General Rules Act)</li>
  <li>印紙税法 (Stamp Duty Law)</li>
  <li>印紙税法施行令 (Stamp Tax Act Enforcement Order)</li>
  <li>印紙税法施行規則 (Stamp Duty Act Enforcement Regulations)</li>
  <li>租税特別措置法 (Special Tax Measures Act)</li>
  <li>租税特別措置法施行令 (Enforcement Order of the Special Taxation Measures Act)</li>
  <li>租税特別措置法施行規則 (Enforcement Regulations of the Act on Special Measures Concerning Taxation)</li>
  <li>租税条約等の実施に伴う所得税法、法人税法及び地方税法の特例等に関する法律 (Act on Special Provisions of the Income Tax Act, Corporate Tax Act, and Local Tax Act in Accordance with the Implementation of Tax Treaties, etc.)</li>
  <li>租税条約等の実施に伴う所得税法、法人税法及び地方税法の特例等に関する法律施行令 (Enforcement Order for the Act on Special Provisions, etc. of the Income Tax Act, Corporate Tax Act, and Local Tax Act in Accordance with the Implementation of Tax Treaties, etc.)</li>
  <li>租税条約等の実施に伴う所得税法、法人税法及び地方税法の特例等に関する法律の施行に関する省令 (Ministerial Ordinance on the Enforcement of the Act on Special Provisions, etc. of the Income Tax Act, Corporate Tax Act, and Local Tax Act in Conjunction with the Implementation of Tax Treaties, etc.)</li>
  <li>会社法 (Company Law)</li>
  <li>会社法施行令 (Companies Act Enforcement Order)</li>
  <li>会社法施行規則 (Companies Act Enforcement Regulations)</li>
  <li>会社計算規則 (Company calculation rules)</li>
</ul>`

export const PWC_DISCLAIMER_TEXT = `Harvey outputs are intended to provide guidance only, do not constitute legal, tax or other professional advice and should not be relied on unless reviewed by a suitably qualified professional.  PwC has supplemented the functionality of Harvey to enable it to provide extended guidance in relation to tax queries.  Neither Counsel AI Corporation nor PwC guarantee the accuracy or suitability of any outputs from Harvey (whether from Harvey or Harvey plus PwC's Tax AI Assistant) for any specific purpose.  Furthermore, Counsel AI Corporation and PwC do not accept liability for any errors, omissions losses or damage arising from the use of or reliance on such outputs.  You and any of your personnel are solely responsible for any decisions or actions taken based on outputs from Harvey and for ensuring your own compliance with applicable laws, regulations and standards.`

export const TAX_HELP_TEXT = `
<p>Tax AI Assistant provides comprehensive generative AI outputs that are grounded by verified sources on tax laws and guidance.</p>
`
export const TAX_AI_HELP_TEXT = `
<h4>What data is the model based on and how is it kept up to date?</h4>
<p>The underlying data set includes current UK tax legislation, UK and EU case law, HMRC manuals & guidance, together with some international tax aspects derived from Worldwide tax summaries and OECD tax sources. The tool is further supplemented by proprietary PwC internal tax knowledge consisting of tax technical papers, standard appendices, tax insights etc.</p>
<p>Our Knowledge team refreshes the data periodically depending on the source and we have the ability to push through updates outside of this cycle (e.g following a budget). Care should be taken in using the tool for historical issues where legislation / guidance may have been superseded, or prospective rules which are not yet enacted. Although the data is regularly refreshed, you should not assume that very recent or prospective tax developments will be integrated into the model.</p>
<p>The level of information in the model will grow over time and you can find the most up to date list of tax sources and update schedule <a href="https://drive.google.com/file/d/1E9w6oC5lMePaWns5_rXSryGYjJvzYuLi/view">here</a>. If you would like to suggest new tax sources, please use the feedback option.</p>
`

export const AUS_BREACH_REPORTING_HELP = `<h4>About</h4>
<p class="ql-align-justify">Harvey will answer questions or generate new content based on Australia Breach Reporting.</p>`

export const RESEARCH_SAVE_PROMPT_TOOLTIP_HELP = `Save this query to your library for future reference`

export const CUATRECASAS_HELP = `
<p class="ql-align-justify"> <b>Cuatrecasas Knowledge Base Tool.</b> [Antes de utilizar esta funcionalidad, por favor, lee atentamente las siguientes advertencias]: </p>
<ul>
  <li><b>Configuración y Propósito</b>: Cuatrecasas Research Tool es una herramienta diseñada por Harvey para la resolución de consultas jurídicas sobre la base de los Modelos y Criterios Técnicos ACI disponibles en Knowledge.</li>
  <li><b>Punto de Partida:</b> Cuatrecasas Research Tool puede ser utilizada como un punto de partida para identificar los recursos internos del ACI aplicables y para obtener un primer borrador de respuesta en la investigación jurídica.</li>
  <li><b>Precisión y Limitaciones:</b> La herramienta se basa sobre los Modelos y Criterios Técnicos ACI para que la respuesta sea más precisa. Sin embargo, es importante tener en cuenta que puede presentar alucinaciones y ofrecer información incompleta o no directamente relacionada con la pregunta formulada. Los Modelos y Criterios Técnicos ACI disponibles se actualizan mensualmente.</li>
  <li><b>Verificación Necesaria:</b> Por ello, es imprescindible que un experto en la materia revise la respuesta proporcionada. En ningún caso, esta herramienta sustituye la revisión y el juicio profesional del abogado para asegurar la precisión y relevancia de la información proporcionada.</li>
</ul>
<p class="ql-align-justify"> <b>Cuatrecasas Knowledge Base Tool</b> [Before using this functionality, please read the following warnings carefully]: </p>
<ul>
  <li><b>Configuration and Purpose:</b> Cuatrecasas Research Tool is a tool designed by Harvey for solving legal queries based on the ACI Technical Models and Criteria available in Knowledge.</li>
  <li><b>Starting Point:</b> Cuatrecasas Research Tool can be used as a starting point to identify applicable internal ACI resources and to obtain a first draft response in legal research.</li>
  <li><b>Accuracy and Limitations:</b> The tool builds upon the ACI Technical Models and Criteria to provide more accurate responses. However, it is important to note that it may present hallucinations and offer incomplete or not directly related information to the question asked. The available ACI Models and Technical Criteria are updated monthly.</li>
  <li><b>Necessary Verification:</b> Therefore, it is essential that a legal expert in the field reviews the provided answer. Under no circumstances does this tool replace the review and professional judgment of the lawyer to ensure the accuracy and relevance of the provided information.</li>
</ul>
`
