import React from 'react'

import Services from 'services'

import {
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import { FavoriteRecord } from 'components/filter/types/favorite-record'
import { Switch } from 'components/ui/switch'

interface FavoriteFilterProps extends AnalyticsContextType {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  isFavorite?: (record: FavoriteRecord) => boolean
}

const FavoriteFilter = (props: FavoriteFilterProps) => {
  const { filterKey, value, setValue, isFavorite, trackEvent } = props

  const render: React.FC = () => {
    return (
      <Switch
        aria-label="Filter by starred"
        defaultChecked={false}
        key={filterKey}
        checked={value === 'true'}
        onCheckedChange={(checked) => {
          setValue(checked ? 'true' : '')
          Services.HoneyComb.Record({
            metric: 'ui.history_favorite_filter',
            favorite: checked,
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'history table',
            field_changed: 'starred',
            starred: checked,
          })
        }}
      />
    )
  }

  const filterLogic = (record: FavoriteRecord) =>
    value !== 'true' || !!isFavorite?.(record)

  return { filterKey, displayName: 'Starred', render, filterLogic }
}

export default FavoriteFilter
