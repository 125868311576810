import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { useFileCache } from 'stores/file-cache'

import { Source } from 'utils/task'

import {
  FILE_ID_PARAM,
  RESET_PARAM,
  SOURCE_ID_PARAM,
  incrementReset,
} from 'components/assistant/utils/assistant-helpers'
import PdfPushSheet from 'components/common/pdf-viewer/pdf-push-sheet'

interface WorkflowFileViewerStoreState {
  sources: Source[]
}

interface WorkflowFileViewerStoreActions {
  setSources: (sources: Source[]) => void
}

const initialState: WorkflowFileViewerStoreState = {
  sources: [],
}

export const useWorkflowFileViewer = create(
  devtools(
    immer<WorkflowFileViewerStoreState & WorkflowFileViewerStoreActions>(
      (set) => ({
        ...initialState,
        setSources: (sources) => set(() => ({ sources })),
      })
    )
  )
)

export const useSetViewingFile = () => {
  const setSources = useWorkflowFileViewer((state) => state.setSources)
  const [searchParams, setSearchParams] = useSearchParams()
  const resetSource = searchParams.get(RESET_PARAM)

  const setViewingFile = (
    file: UploadedFile | null,
    sourceId?: string,
    sources?: Source[]
  ) => {
    const setUrlParams = (fileId: string | null, sourceId?: string) => {
      setSearchParams((prevParams) => {
        // TODO: We should also set BLOCK_ID so that we can restore sources
        // when opening the url
        const newParams = new URLSearchParams(prevParams)
        if (fileId) {
          newParams.set(FILE_ID_PARAM, fileId)
          if (sourceId) newParams.set(SOURCE_ID_PARAM, sourceId)
        } else {
          newParams.delete(FILE_ID_PARAM)
          newParams.delete(SOURCE_ID_PARAM)
        }
        if (sourceId === newParams.get(SOURCE_ID_PARAM)) {
          newParams.set(RESET_PARAM, incrementReset(resetSource))
        } else {
          newParams.delete(RESET_PARAM)
        }
        return newParams
      })
    }

    setUrlParams(file?.id || null, sourceId)
    setSources(sources || [])
  }

  return setViewingFile
}

export const AssistantWorkflowFilePopover: React.FC = () => {
  const [sources] = useWorkflowFileViewer((state) => [state.sources])
  const [searchParams] = useSearchParams()
  const [getFile] = useFileCache((state) => [state.getFile])
  const fileId = searchParams.get(FILE_ID_PARAM)
  const [viewingFile, setViewingFile] = useState<UploadedFile | null>(null)

  const getFileInternal = useCallback(
    async (_eventId: string | null, fileId: string) => getFile(fileId),
    [getFile]
  )

  useEffect(() => {
    const fetchFile = async () => {
      if (fileId) {
        const file = await getFile(fileId)
        setViewingFile(file || null)
      } else {
        setViewingFile(null)
      }
    }

    fetchFile()
  }, [fileId, getFile])

  const viewingFiles = useMemo(
    () => (viewingFile ? [viewingFile] : []),
    [viewingFile]
  )

  if (!fileId) return null

  return (
    <PdfPushSheet
      documents={viewingFiles}
      fileId={fileId}
      getDocument={getFileInternal}
      sources={sources || []}
    />
  )
}
