import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Search } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'
import { TagScope } from 'openapi/models/TagScope'
import { VaultFile } from 'openapi/models/VaultFile'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { DebouncedInput } from 'components/ui/debounced-input'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuLabel,
} from 'components/ui/dropdown-menu'
import { Pill, Dot, PillColor } from 'components/ui/pill/pill'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipPortal,
} from 'components/ui/tooltip'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING,
  DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING,
  DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING,
  useDocumentClassificationStore,
} from 'components/vault/utils/use-document-classification-store'
import {
  DOT_SEPARATOR,
  SUB_MENU_HEIGHT,
  VaultItemStatus,
} from 'components/vault/utils/vault'
import { UpdateVaultFileTags } from 'components/vault/utils/vault-fetcher'
import {
  determineFileStatus,
  getRandomSkeletonSize,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

export const SimpleDocumentClassificationPill = ({
  tagName,
  tagId,
  isPill = false,
}: {
  tagName: string
  tagId: string
  isPill?: boolean
}) => {
  const tagNameAsKey =
    tagName as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
  const tagParent = DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[tagNameAsKey]
  const tagColor = DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING[tagParent]
  
  if (isPill) {
    return (
      <Pill
        key={tagId}
        labelDot={tagColor}
        className="cursor-default text-muted"
      >
        {tagName}
      </Pill>
    )
  }

  return (
    <div key={tagId} className="flex items-center gap-1">
      <Dot labelDot={tagColor} />
      <p className="text-xs">{tagName}</p>
    </div>
  )
}

const VaultDocumentClassificationPill = ({
  tag,
  fileId,
  isFilesProcessing,
  className,
  shouldDisable,
}: {
  tag: DocumentClassificationTag
  fileId: string
  isFilesProcessing: boolean
  className?: string
  shouldDisable?: boolean
}) => {
  const { projectId } = useParams()
  const { trackEvent } = useAnalytics()

  const parentTags = Object.keys(DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING)

  const tagName =
    tag.name as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
  const parentTag = DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[
    tagName
  ] as keyof typeof DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING
  const tagColor = DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING[
    parentTag
  ] as PillColor

  const [searchValue, setSearchValue] = useState('')
  const [displayTags, setDisplayTags] = useState<string[]>()
  const parentTagsIncludeSearchValue = parentTags.some((tag) =>
    tag.toLowerCase().includes(searchValue.toLowerCase())
  )

  const openFileId = useDocumentClassificationStore(
    useShallow((s) => s.openFileId)
  )
  const documentClassificationTags = useDocumentClassificationStore(
    useShallow((s) => s.documentClassificationTags)
  )
  const setOpenFileId = useDocumentClassificationStore(
    useShallow((s) => s.setOpenFileId)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const updateVaultFile = useVaultStore(useShallow((s) => s.updateVaultFile))

  const isExampleProject = projectId && exampleProjectIds.has(projectId)

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId,
  })

  const onOpenChangeHandler = (isOpen: boolean) => {
    setOpenFileId(isOpen ? fileId : null)
  }

  const inputKeyDownHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const isEnterKey = e.key === 'Enter'
    const onlyOneTagFound = displayTags?.length === 1
    if (isEnterKey && onlyOneTagFound) {
      await onTagChangeHandler(displayTags[0])
    }
  }

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value
    if (!newSearch) {
      setSearchValue('')
      return
    }
    setSearchValue(newSearch)
    setDisplayTags(
      Object.keys(DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING).filter((tag) =>
        tag.toLowerCase().includes(newSearch.toLowerCase())
      )
    )
  }

  const onTagChangeHandler = useCallback(
    async (newTagName: string) => {
      if (isExampleProject) {
        displayErrorMessage('Cannot update classification for example project')
        return
      }

      if (!doesCurrentUserHaveEditPermission) {
        displayErrorMessage(
          'You do not have permission to update classifications'
        )
        return
      }

      const newDocumentClassificationTag = documentClassificationTags.find(
        (tag) => tag.name === newTagName
      )
      if (!newDocumentClassificationTag) {
        displayErrorMessage('Failed to update classification')
        return
      }

      // TODO: when we support multiple tags, we need to update the tags array
      updateVaultFile(
        fileId,
        {
          tags: [newDocumentClassificationTag],
        },
        projectId
      )
      setOpenFileId(null)

      try {
        await UpdateVaultFileTags(
          fileId,
          [tag.id],
          [newDocumentClassificationTag.id]
        )
        trackEvent('Vault Document Classification Updated', {
          file_id: fileId,
          grouping_document_classification: parentTag,
          old_document_classification: tag.name,
          new_document_classification: newDocumentClassificationTag.name,
        })
      } catch (e) {
        console.error(e)
        displayErrorMessage('Failed to update classification')
        updateVaultFile(
          fileId,
          {
            tags: [tag],
          },
          projectId
        )
      }
    },
    [
      tag,
      fileId,
      parentTag,
      isExampleProject,
      doesCurrentUserHaveEditPermission,
      documentClassificationTags,
      updateVaultFile,
      setOpenFileId,
      trackEvent,
      projectId,
    ]
  )

  const isDisabled =
    !!isExampleProject ||
    isFilesProcessing ||
    !doesCurrentUserHaveEditPermission ||
    shouldDisable

  if (isDisabled) {
    return (
      <Tooltip>
        <TooltipTrigger>
          <Pill labelDot={tagColor} className="cursor-default text-muted">
            {tagName}
          </Pill>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent className="w-44">
            {isFilesProcessing
              ? 'Please wait for files to finish processing before updating document type'
              : ''}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    )
  }

  return (
    <DropdownMenu
      key={tag.id}
      open={openFileId === fileId}
      onOpenChange={onOpenChangeHandler}
    >
      <DropdownMenuTrigger
        className="data-[state=open]:bg-transparent"
        asChild
        disabled={isDisabled}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Button
          variant="unstyled"
          className={cn('flex w-full justify-start p-0', className)}
          size="sm"
        >
          <Pill
            labelDot={tagColor}
            className="text-muted transition hover:bg-accent"
          >
            {tagName}
          </Pill>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="h-[298px] w-64"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          role="none"
          className="flex h-8 items-center px-1 py-0"
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DebouncedInput
            // eslint-disable-next-line
            autoFocus
            debounceDelay={100}
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSearchInputChange(e)
            }
            onKeyDown={inputKeyDownHandler}
            icon={Search}
            placeholder="Search classification"
            className="h-8 rounded-none border-none pl-1 pr-3 transition hover:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
          />
        </div>
        <DropdownMenuSeparator />
        <div className="flex h-[248px] flex-col gap-1">
          {!!searchValue && (
            <ScrollArea>
              {parentTags
                .filter((parentTag) => {
                  const parentTagKey =
                    parentTag as keyof typeof DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING
                  const parentTagChildren =
                    DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING[parentTagKey]
                  const parentTagDisplayTags = parentTagChildren.filter(
                    (childTag) => {
                      return childTag
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    }
                  )
                  const isSearchValueInParentTag = parentTag
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                  return (
                    parentTagDisplayTags.length > 0 ||
                    (isSearchValueInParentTag &&
                      parentTagDisplayTags.length === 0)
                  )
                })
                .map((parentTag, idx) => {
                  const isFirstParentTag = idx === 0
                  const labelClassName = !isFirstParentTag ? 'mt-4' : ''

                  const parentTagKey =
                    parentTag as keyof typeof DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING
                  const parentTagColor =
                    DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING[parentTagKey]
                  const parentTagChildren =
                    DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING[parentTagKey]
                  let parentTagDisplayTags = parentTagChildren.filter(
                    (childTag) =>
                      childTag.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  if (
                    parentTagKey
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) &&
                    parentTagDisplayTags.length === 0
                  ) {
                    parentTagDisplayTags = parentTagChildren
                  }

                  return (
                    <div key={parentTag}>
                      <DropdownMenuLabel className={labelClassName}>
                        {parentTag}
                      </DropdownMenuLabel>
                      {parentTagDisplayTags.map((displayTag) => {
                        return (
                          <DropdownMenuItem
                            key={displayTag}
                            className="mb-1 p-0.5 focus:bg-transparent"
                            onClick={() => onTagChangeHandler(displayTag)}
                          >
                            <Pill
                              labelDot={parentTagColor}
                              className="transition hover:bg-accent"
                            >
                              <p className="text-xs">{displayTag}</p>
                            </Pill>
                          </DropdownMenuItem>
                        )
                      })}
                    </div>
                  )
                })}
              {displayTags?.length === 0 && !parentTagsIncludeSearchValue && (
                <div className="mt-2 flex w-full flex-col items-center gap-1">
                  <p className="text-xs text-muted">No tags found</p>
                </div>
              )}
            </ScrollArea>
          )}
          {!searchValue && (
            <>
              {parentTags.map((parentTag) => {
                const parentKey =
                  parentTag as keyof typeof DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING
                const parentTagColor =
                  DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING[parentKey]
                const childTags =
                  DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING[parentKey]
                // The submenu height needs to be a dynamically calculated height based on the number of child tags in the parent tag
                // The height of each child tag is 26px and 12x is to account for padding
                // This way if a submenu has 1 tag the height is not 256 (h-64)
                const subMenuHeight = 8 + childTags.length * 26
                const childTagToRender = childTags.map((childTag) => {
                  return (
                    <DropdownMenuItem
                      key={childTag}
                      className="p-0.5 focus:bg-transparent"
                      onClick={() => onTagChangeHandler(childTag)}
                    >
                      <Pill
                        labelDot={parentTagColor}
                        className="transition hover:bg-accent"
                      >
                        <p className="text-xs">{childTag}</p>
                      </Pill>
                    </DropdownMenuItem>
                  )
                })
                return (
                  <DropdownMenuSub key={parentTag}>
                    <DropdownMenuSubTrigger className="flex items-center gap-1">
                      <Dot labelDot={parentTagColor} />
                      {parentTag}
                    </DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent
                        className="max-h-64 w-64"
                        // eslint-disable-next-line
                        style={{ height: subMenuHeight }}
                      >
                        {subMenuHeight > SUB_MENU_HEIGHT && (
                          <ScrollArea isFullHeight className="h-full">
                            {childTagToRender}
                          </ScrollArea>
                        )}
                        {subMenuHeight <= SUB_MENU_HEIGHT && childTagToRender}
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                )
              })}
            </>
          )}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

interface VaultDocumentClassificationProps {
  fileId: string
  isFilesProcessing?: boolean
  className?: string
  shouldShowPrefixDot?: boolean
  shouldDisable?: boolean
}

const VaultDocumentClassification = ({
  fileId,
  className,
  isFilesProcessing = false,
  shouldShowPrefixDot = false,
  shouldDisable = false,
}: VaultDocumentClassificationProps) => {
  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )

  if (!fileIdToVaultFile[fileId]) {
    return null
  }

  const vaultFile: VaultFile = fileIdToVaultFile[fileId] as VaultFile
  const fileName = vaultFile.name
  const fileTags = vaultFile.tags
  const fileDocumentClassificationTags = fileTags.filter(
    (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
  )
  const hasNoDocumentClassificationTags =
    fileDocumentClassificationTags.length === 0

  const fileStatus = determineFileStatus(vaultFile)
  const isFileLoading =
    fileStatus === VaultItemStatus.processing ||
    fileStatus === VaultItemStatus.uploading

  if (isFileLoading) {
    return (
      <div
        className="h-4 animate-pulse rounded bg-skeleton"
        style={{ width: getRandomSkeletonSize(fileId, fileName) }}
      />
    )
  }

  if (hasNoDocumentClassificationTags) {
    return null
  }

  return (
    <>
      {fileDocumentClassificationTags.map((tag) => {
        return (
          <div key={tag.id} className="flex items-center gap-1">
            {shouldShowPrefixDot && (
              <p className="text-xs text-muted">{DOT_SEPARATOR}</p>
            )}
            <VaultDocumentClassificationPill
              tag={tag}
              fileId={fileId}
              isFilesProcessing={isFilesProcessing}
              className={className}
              shouldDisable={shouldDisable}
            />
          </div>
        )
      })}
    </>
  )
}

export default VaultDocumentClassification
