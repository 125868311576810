import React from 'react'

import _ from 'lodash'

import { AnalyticsProperties } from 'components/common/analytics/analytics-context'

import { AnnotationById, Source } from './task'

// Get content within <mark></mark>
const MARK_INNER_REGEX = /<mark.*>(.*)<\/mark>/

export const getSourceClicked = (
  identifier: string,
  annotations: AnnotationById
) => {
  if (_.isNil(annotations)) {
    return null
  }
  // if it's a numeric then it's a footnote (old format to Deprecate)
  return annotations[identifier] as Source
}

export const getSourceClickedFromSource = (
  sourceId: string,
  sources: Source[]
) => {
  return sources.find((source) => source.id === sourceId)
}

export type HrvyInfoElement =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLSpanElement
  | null

export type getHrvyInfoMetadata = (
  id: string,
  position?: { line: number; column: number; offset: number }
) =>
  | {
      url?: string
      onClick?: (e: React.MouseEvent) => void
      hoverContent?: React.ReactNode
      isSelected?: boolean
      ref?: (el: HrvyInfoElement) => void
      eventData?: AnalyticsProperties
    }
  | undefined

export const extractMarkedTextSimple = (s: string | undefined): string => {
  if (!s) return ''
  const match = s.match(MARK_INNER_REGEX)
  return match ? match[1].trim() : s
}
