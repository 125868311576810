/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TableSelect } from './TableSelect';
import {
    TableSelectFromJSON,
    TableSelectFromJSONTyped,
    TableSelectToJSON,
    TableSelectToJSONTyped,
} from './TableSelect';
import type { WorkflowTableSelectInputBlockBlockParamsSourcesInner } from './WorkflowTableSelectInputBlockBlockParamsSourcesInner';
import {
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerFromJSON,
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerFromJSONTyped,
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerToJSON,
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerToJSONTyped,
} from './WorkflowTableSelectInputBlockBlockParamsSourcesInner';

/**
 * 
 * @export
 * @interface WorkflowTableSelectInputBlockBlockParams
 */
export interface WorkflowTableSelectInputBlockBlockParams {
    /**
     * 
     * @type {string}
     * @memberof WorkflowTableSelectInputBlockBlockParams
     */
    headerText: string;
    /**
     * 
     * @type {TableSelect}
     * @memberof WorkflowTableSelectInputBlockBlockParams
     */
    table: TableSelect;
    /**
     * 
     * @type {Array<WorkflowTableSelectInputBlockBlockParamsSourcesInner>}
     * @memberof WorkflowTableSelectInputBlockBlockParams
     */
    sources?: Array<WorkflowTableSelectInputBlockBlockParamsSourcesInner>;
}

/**
 * Check if a given object implements the WorkflowTableSelectInputBlockBlockParams interface.
 */
export function instanceOfWorkflowTableSelectInputBlockBlockParams(value: object): value is WorkflowTableSelectInputBlockBlockParams {
    if (!('headerText' in value) || value['headerText'] === undefined) return false;
    if (!('table' in value) || value['table'] === undefined) return false;
    return true;
}

export function WorkflowTableSelectInputBlockBlockParamsFromJSON(json: any): WorkflowTableSelectInputBlockBlockParams {
    return WorkflowTableSelectInputBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowTableSelectInputBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTableSelectInputBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'headerText': json['header_text'],
        'table': TableSelectFromJSON(json['table']),
        'sources': json['sources'] == null ? undefined : ((json['sources'] as Array<any>).map(WorkflowTableSelectInputBlockBlockParamsSourcesInnerFromJSON)),
    };
}

export function WorkflowTableSelectInputBlockBlockParamsToJSON(json: any): WorkflowTableSelectInputBlockBlockParams {
    return WorkflowTableSelectInputBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowTableSelectInputBlockBlockParamsToJSONTyped(value?: WorkflowTableSelectInputBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'header_text': value['headerText'],
        'table': TableSelectToJSON(value['table']),
        'sources': value['sources'] == null ? undefined : ((value['sources'] as Array<any>).map(WorkflowTableSelectInputBlockBlockParamsSourcesInnerToJSON)),
    };
}

