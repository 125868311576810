import _ from 'lodash'

import { DefaultVisibility } from 'openapi/models/DefaultVisibility'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'
import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'

import { RolePerm } from 'components/settings/roles/role-perms-table'
import { RoleRawUser } from 'components/settings/roles/roles-users-card'

import { Perm, PermBundle } from './perms'

export interface WorkspaceRole {
  rolePk: string
  roleId: string
  name: string
  desc: string
  workspaceId: number
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export interface RoleConfig {
  roleId: string
  permBundleId: PermissionBundleId
  permissions: Perm[]
  missingPerms: string[]
}
export interface WorkspaceRoleConfig {
  permBundle: PermBundle
  roleConfigs: RoleConfig[]
  visibility?: DefaultVisibility
}

export const getWorkspaceRoles = async (
  workspaceId: number
): Promise<WorkspaceRole[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('workspace_id', workspaceId.toString())
  return Services.Backend.Get<WorkspaceRole[]>(
    `internal_admin/workspace_roles?${searchParams.toString()}`
  )
}

export const getWorkspaceRoleConfigs = async (workspaceId: number) => {
  return Services.Backend.Get<WorkspaceRoleConfig[]>(
    `client_admin/workspace/${workspaceId}/role_configs`
  )
}

export const createWorkspaceRole = async (
  workspaceId: number,
  role: Pick<WorkspaceRole, 'name' | 'desc'>
): Promise<WorkspaceRole> => {
  return Services.Backend.Post<WorkspaceRole>(
    `internal_admin/workspace_roles`,
    {
      workspaceId,
      ...role,
    }
  )
}

export const updateWorkspaceRole = async (
  rolePk: string,
  role: Pick<WorkspaceRole, 'name' | 'desc'>
): Promise<WorkspaceRole> => {
  const response = await Services.Backend.Patch<WorkspaceRole | RequestError>(
    `internal_admin/workspace_roles/${rolePk}`,
    role,
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export const deleteWorkspaceRole = async (
  rolePk: string,
  reassignToRolePk?: string
): Promise<void> => {
  const response = await Services.Backend.Delete<RequestError>(
    `internal_admin/workspace_roles/${rolePk}`,
    {
      reassignToRolePk,
    },
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export const getRoleUsers = async (rolePk: string): Promise<RoleRawUser[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('role_pk', rolePk)
  return Services.Backend.Get<RoleRawUser[]>(
    `internal_admin/workspace_role/users?${searchParams.toString()}`
  )
}

export const getRolePerms = async (rolePk: string): Promise<RolePerm[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('role_pk', rolePk)
  return Services.Backend.Get<RolePerm[]>(
    `internal_admin/workspace_role/perms?${searchParams.toString()}`
  )
}

export const setUserRole = async (
  userId: string,
  rolePk: string
): Promise<void> => {
  const response = await Services.Backend.Put(
    `internal_admin/users/${userId}/role`,
    {
      rolePk,
    },
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return
}

export const addUsersToRole = async (
  rolePk: string,
  userEmails: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(`internal_admin/workspace_role/users`, {
    rolePk,
    userEmails,
  })
}

export const removeUsersFromRole = async (
  rolePk: string,
  userEmails: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(
    `internal_admin/workspace_role/users/delete`,
    {
      rolePk,
      userEmails,
    }
  )
}

export const addPermsToRole = async (
  rolePk: string,
  permIds: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(`internal_admin/workspace_role/perms`, {
    rolePk,
    permIds,
  })
}

export const removePermsFromRole = async (
  rolePk: string,
  permIds: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(
    `internal_admin/workspace_role/perms/delete`,
    {
      rolePk,
      permIds,
    }
  )
}

export const fetchRoleByIdOrPk = async (
  roleId: string
): Promise<Maybe<WorkspaceRole>> => {
  const searchParams = new URLSearchParams()
  searchParams.append('role_id', roleId)
  return Services.Backend.Get<Maybe<WorkspaceRole>>(
    `internal_admin/workspace_role?${searchParams.toString()}`
  )
}

export interface EditDiffs {
  diffsByRolePk: {
    [key: string]:
      | {
          enabledPermBundleIds: Set<PermissionBundleId> // These are redundant for convenience
          disabledPermBundleIds: Set<PermissionBundleId> // These are redundant for convenience
          enabledPermIds: Set<string>
          disabledPermIds: Set<string>
        }
      | undefined
  }
  enabledPermIds: Set<string>
  disabledPermIds: Set<string>
}

const serializeEditDiffs = (diffs: EditDiffs) => {
  return {
    diffsByRolePk: _.mapValues(
      diffs.diffsByRolePk,
      (diff) => diff && _.mapValues(diff, (set) => Array.from(set))
    ),
    enabledPermIds: Array.from(diffs.enabledPermIds),
    disabledPermIds: Array.from(diffs.disabledPermIds),
  }
}

export const bulkUpdateRolePerms = async (
  workspaceId: number,
  diffs: EditDiffs
) => {
  const response = await Services.Backend.Patch<void>(
    `internal_admin/workspace_roles/config`,
    {
      workspaceId,
      diffs: serializeEditDiffs(diffs),
    },
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}
