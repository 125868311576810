/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocketMessageResponseAntitrustFilings } from './SocketMessageResponseAntitrustFilings';
import {
    SocketMessageResponseAntitrustFilingsFromJSON,
    SocketMessageResponseAntitrustFilingsFromJSONTyped,
    SocketMessageResponseAntitrustFilingsToJSON,
    SocketMessageResponseAntitrustFilingsToJSONTyped,
} from './SocketMessageResponseAntitrustFilings';

/**
 * 
 * @export
 * @interface WorkflowAntitrustFilingsRendererBlockParams
 */
export interface WorkflowAntitrustFilingsRendererBlockParams {
    /**
     * 
     * @type {SocketMessageResponseAntitrustFilings}
     * @memberof WorkflowAntitrustFilingsRendererBlockParams
     */
    answer: SocketMessageResponseAntitrustFilings;
}

/**
 * Check if a given object implements the WorkflowAntitrustFilingsRendererBlockParams interface.
 */
export function instanceOfWorkflowAntitrustFilingsRendererBlockParams(value: object): value is WorkflowAntitrustFilingsRendererBlockParams {
    if (!('answer' in value) || value['answer'] === undefined) return false;
    return true;
}

export function WorkflowAntitrustFilingsRendererBlockParamsFromJSON(json: any): WorkflowAntitrustFilingsRendererBlockParams {
    return WorkflowAntitrustFilingsRendererBlockParamsFromJSONTyped(json, false);
}

export function WorkflowAntitrustFilingsRendererBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowAntitrustFilingsRendererBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'answer': SocketMessageResponseAntitrustFilingsFromJSON(json['answer']),
    };
}

export function WorkflowAntitrustFilingsRendererBlockParamsToJSON(json: any): WorkflowAntitrustFilingsRendererBlockParams {
    return WorkflowAntitrustFilingsRendererBlockParamsToJSONTyped(json, false);
}

export function WorkflowAntitrustFilingsRendererBlockParamsToJSONTyped(value?: WorkflowAntitrustFilingsRendererBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'answer': SocketMessageResponseAntitrustFilingsToJSON(value['answer']),
    };
}

