/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkflowRedlinesE2EBackendFunctionOutput } from './WorkflowRedlinesE2EBackendFunctionOutput';
import {
    WorkflowRedlinesE2EBackendFunctionOutputFromJSON,
    WorkflowRedlinesE2EBackendFunctionOutputFromJSONTyped,
    WorkflowRedlinesE2EBackendFunctionOutputToJSON,
    WorkflowRedlinesE2EBackendFunctionOutputToJSONTyped,
} from './WorkflowRedlinesE2EBackendFunctionOutput';

/**
 * 
 * @export
 * @interface WorkflowAnswerRenderBlockBlockParams
 */
export interface WorkflowAnswerRenderBlockBlockParams {
    /**
     * 
     * @type {WorkflowRedlinesE2EBackendFunctionOutput}
     * @memberof WorkflowAnswerRenderBlockBlockParams
     */
    answer: WorkflowRedlinesE2EBackendFunctionOutput;
}

/**
 * Check if a given object implements the WorkflowAnswerRenderBlockBlockParams interface.
 */
export function instanceOfWorkflowAnswerRenderBlockBlockParams(value: object): value is WorkflowAnswerRenderBlockBlockParams {
    if (!('answer' in value) || value['answer'] === undefined) return false;
    return true;
}

export function WorkflowAnswerRenderBlockBlockParamsFromJSON(json: any): WorkflowAnswerRenderBlockBlockParams {
    return WorkflowAnswerRenderBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowAnswerRenderBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowAnswerRenderBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'answer': WorkflowRedlinesE2EBackendFunctionOutputFromJSON(json['answer']),
    };
}

export function WorkflowAnswerRenderBlockBlockParamsToJSON(json: any): WorkflowAnswerRenderBlockBlockParams {
    return WorkflowAnswerRenderBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowAnswerRenderBlockBlockParamsToJSONTyped(value?: WorkflowAnswerRenderBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'answer': WorkflowRedlinesE2EBackendFunctionOutputToJSON(value['answer']),
    };
}

