import React from 'react'

import { UserInfo } from 'models/user-info'
import { EventKind } from 'openapi/models/EventKind'

import {
  DATABASE_SOURCE_TO_RESEARCH_AREA,
  isDatabaseSource,
  KnowledgeSource,
  KnowledgeSourceConfig,
  useKnowledgeSources,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { ASSISTANT_KNOWLEDGE_SOURCES_HEADER } from 'components/assistant/utils/strings'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import { getEventForResearchArea } from 'components/research/research-helpers'

const AssistantCustomTips = () => {
  const userInfo = useAuthUser()
  const knowledgeSources = useKnowledgeSources(userInfo)

  let tipsText = userInfo.GetHelpPanel(EventKind.ASSISTANT)?.content ?? ''

  const getKnowledgeSourceTipText = (source: KnowledgeSource) => {
    if (isDatabaseSource(source)) {
      const researchArea = DATABASE_SOURCE_TO_RESEARCH_AREA[source]
      const eventKind = getEventForResearchArea(researchArea)
      return userInfo.GetHelpPanel(eventKind)?.content ?? ''
    }
    return ''
  }

  const knowledgeSourcesWithTips = Array.from(knowledgeSources).filter(
    (source) => {
      return !!getKnowledgeSourceTipText(source)
    }
  )
  if (knowledgeSourcesWithTips.length > 0) {
    tipsText += ASSISTANT_KNOWLEDGE_SOURCES_HEADER
    knowledgeSourcesWithTips.forEach((source) => {
      const config = KnowledgeSourceConfig[source]
      const tipText = getKnowledgeSourceTipText(source)
      tipsText += `<h4>${config.name}</h4>
        <p>${tipText}</p>`
    })
  }

  return <Markdown className="max-w-4xl pt-2" content={tipsText} />
}

export default AssistantCustomTips

export const hasCustomTips = (userInfo: UserInfo) => {
  const eventKinds = [
    EventKind.ASSISTANT,
    ...Object.values(DATABASE_SOURCE_TO_RESEARCH_AREA).map(
      getEventForResearchArea
    ),
  ]
  return eventKinds.some(
    (eventKind) => !!userInfo.GetHelpPanel(eventKind)?.content
  )
}
