import React from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { Source } from 'utils/task'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

type RowData = TableSelect['rows'][number]

export const AssistantWorkflowTableRendererThread: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams }) => {
  const { headerText, table } = blockParams
  const { getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })

  const columns: ColumnDef<RowData>[] = generateColumns(
    table,
    getHrvyInfoMetadata
  )

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText text={headerText} />
        <div className="mt-5">
          <TableSelectTable data={table.rows} columns={columns} />
        </div>
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableRendererInput: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams }) => {
  const { headerText } = blockParams
  return <WorkflowInput footer={headerText} />
}
