import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import { Trash } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import {
  deleteStatusMessageByName,
  deleteStatusMessageByWorkspaceId,
} from 'models/workspace'

import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import {
  STATUS_VARIANT_TO_BG_COLOR,
  STATUS_VARIANT_TO_TEXT_COLOR,
  StatusVariant,
} from 'components/common/status-bar'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/ui/sheet'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import {
  ExistingStatusMessagesByName,
  useIncidentManagementStore,
} from './incident-management-store'

const ExistingIncidentsCard = () => {
  const userInfo = useAuthUser()
  const {
    existingStatusMessagesByName,
    workspacesById,
    fetchAllStatusMessages,
  } = useIncidentManagementStore(
    useShallow((state) => ({
      existingStatusMessagesByName: state.existingStatusMessagesByName,
      workspacesById: state.workspacesById,
      fetchAllStatusMessages: state.fetchAllStatusMessages,
    }))
  )

  const onDeleteStatusMessageByName = async (name: string) => {
    try {
      await deleteStatusMessageByName(name)
      await fetchAllStatusMessages()
      displaySuccessMessage(`Incident ${name} deleted`)
    } catch (error) {
      displayErrorMessage(`Error deleting status message ${name}`)
      console.error('Error deleting status message:', error)
    }
  }

  const onDeleteStatusMessageByWorkspaceId = async (workspaceId: number) => {
    try {
      await deleteStatusMessageByWorkspaceId(workspaceId)
      await fetchAllStatusMessages()
      displaySuccessMessage(
        `Status message deleted from workspace ${workspaceId}`
      )
    } catch (error) {
      displayErrorMessage(`Error deleting status message ${name}`)
      console.error('Error deleting status message:', error)
    }
  }

  const columns: ColumnDef<ExistingStatusMessagesByName>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Name" />
      },
      cell: ({ getValue, row }) => (
        <div
          className="ml-3 text-sm"
          onKeyDown={() => row.getValue('userEmail')}
          tabIndex={0}
          role="button"
        >
          {String(getValue())}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'workspaces',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Workspaces" />
      },
      cell: ({ row }) => {
        const workspaces = row.original.workspaces as number[] | undefined
        return (
          <div className="ml-3 text-sm">
            {workspaces ? workspaces.length : 0}
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="secondary" size="sm" className="ml-2">
                  View
                </Button>
              </SheetTrigger>
              <SheetContent>
                <SheetHeader>
                  <SheetTitle>Workspaces for {row.getValue('name')}</SheetTitle>
                </SheetHeader>
                <div className="p-6">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Workspace Name</TableCell>
                      </TableRow>
                      <TableBody className="w-[300px]">
                        {workspaces?.map((workspaceId) => {
                          const workspaceById = workspacesById[workspaceId]
                          if (!workspaceById) return null
                          return (
                            <TableRow key={workspaceId} className="w-full">
                              <TableCell>
                                {!_.isEmpty(workspaceById.clientName)
                                  ? workspaceById.clientName
                                  : workspaceById.friendlyName ??
                                    workspaceById.slug}
                              </TableCell>
                              <TableCell>
                                <div className="flex w-full justify-end">
                                  <Button
                                    className="flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
                                    variant="ghost"
                                    size="xsIcon"
                                    tooltip={`Remove ${row.getValue('name')}`}
                                    tooltipSide="left"
                                    aria-label={`Remove ${row.getValue(
                                      'name'
                                    )}`}
                                    onClick={() =>
                                      onDeleteStatusMessageByWorkspaceId(
                                        workspaceId
                                      )
                                    }
                                  >
                                    <Trash className="h-4 w-4 stroke-destructive" />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </TableHeader>
                  </Table>
                </div>
              </SheetContent>
            </Sheet>
          </div>
        )
      },
    },
    {
      accessorKey: 'messageType',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Message Type" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'header',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Header" />
      },
      cell: ({ getValue }) => {
        return <div className="ml-3 text-sm">{String(getValue())}</div>
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'ctaText',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="CTA Text" />
      },
      cell: ({ getValue }) => {
        return <div className="ml-3 text-sm">{String(getValue())}</div>
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'ctaLink',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="CTA Link" />
      },
      cell: ({ getValue }) => {
        return <div className="ml-3 text-sm">{String(getValue())}</div>
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'variant',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Variant" />
      },
      cell: ({ getValue }) => {
        const value = String(getValue())

        return (
          <div className="ml-3 text-sm">
            <Badge
              className={cn(
                STATUS_VARIANT_TO_BG_COLOR[value as StatusVariant],
                STATUS_VARIANT_TO_TEXT_COLOR[value as StatusVariant]
              )}
            >
              {_.startCase(value)}
            </Badge>
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Created" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: true,
    },

    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        if (!userInfo?.IsInternalAdminWriter) return null
        return (
          <div className="float-right flex justify-end">
            <Button
              className="flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('name')}`}
              tooltipSide="left"
              onClick={() => onDeleteStatusMessageByName(row.getValue('name'))}
            >
              <Trash className="h-4 w-4 stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: existingStatusMessagesByName,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        id: false,
      },
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'key',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <Card>
      <CardHeader className="text-lg font-semibold">
        Existing Incidents
      </CardHeader>
      <CardContent>
        <DataTableHeader
          searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
        />
        <div className="mt-2">
          <DataTable
            table={table}
            className="min-h-[420px]"
            emptyStateText="No existing incident messages found"
          />
        </div>
        <DataTableFooter table={table}>
          <p>{rowCountCopy}</p>
        </DataTableFooter>
      </CardContent>
    </Card>
  )
}

export default ExistingIncidentsCard
