import React from 'react'

import Services from 'services'

import {
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import { SharedRecord } from 'components/filter/types/shared-record'
import { Switch } from 'components/ui/switch'

interface SharedFilterProps extends AnalyticsContextType {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  isShared?: (record: SharedRecord) => boolean
}

const SharedFilter = (props: SharedFilterProps) => {
  const { filterKey, value, setValue, isShared, trackEvent } = props

  const render: React.FC = () => {
    return (
      <Switch
        aria-label="Filter by shared"
        defaultChecked={false}
        key={filterKey}
        checked={value === 'true'}
        onCheckedChange={(checked) => {
          setValue(checked ? 'true' : '')
          Services.HoneyComb.Record({
            metric: 'ui.history_shared_filter',
            shared: checked,
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'history table',
            field_changed: 'shared',
            shared: checked,
          })
        }}
      />
    )
  }

  const filterLogic = (record: SharedRecord) =>
    value !== 'true' || !!isShared?.(record)

  return { filterKey, displayName: 'Shared', render, filterLogic }
}

export default SharedFilter
