import React from 'react'

import { isPast } from 'date-fns'
import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import { IntegrationType } from 'openapi/models/IntegrationType'

import { parseIsoString } from 'utils/utils'

import IntegrationAdminCard from './integration-admin-card'
import { IntegrationDefinitions } from './integration-definitions'
import { ConnectedIntegration } from './utils'

interface IntegrationAdminProps {
  userInfo: UserInfo
  connections: ConnectedIntegration[]
}

const IntegrationAdmin: React.FC<IntegrationAdminProps> = ({
  userInfo,
  connections,
}) => {
  const connectionsObj = React.useMemo(
    () => _.keyBy(connections, 'integrationType'),
    [connections]
  )

  return (
    <div>
      <h1 className="font-semibold">Integrations</h1>
      <p className="mt-1 text-xs text-muted">
        Allow anyone in your workspace to authenticate into enabled
        integrations.
      </p>
      {Object.entries(IntegrationDefinitions).map(([key, definition]) => {
        if (!userInfo.permissions.includes(definition.permission)) {
          return null
        }
        const conn = connectionsObj[key]
        return (
          <IntegrationAdminCard
            key={key}
            integrationType={key as IntegrationType}
            integrationDefinition={definition}
            enabled={userInfo.workspace.integrations.includes(
              key as IntegrationType
            )}
            connected={
              !_.isNil(conn) && !isPast(parseIsoString(conn.expiresAt))
            }
            workspaceId={userInfo.workspace.id}
          />
        )
      })}
    </div>
  )
}

export default IntegrationAdmin
