import React from 'react'

import {
  ColumnDef,
  Row,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Trash } from 'lucide-react'

import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceDocumentLabel } from 'openapi/models/DiligenceDocumentLabel'

import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'

type Props = {
  documents: DiligenceDocument[]
  onMultiSelectChange: (
    row: Row<DiligenceDocument>,
    selectedValues: DiligenceDocumentLabel[]
  ) => void
  onDeleteClick: (row: Row<DiligenceDocument>) => void
}

export const DiligenceDocumentsTable: React.FC<Props> = ({
  documents,
  onDeleteClick,
}) => {
  const columns: ColumnDef<DiligenceDocument>[] = [
    {
      header: 'File name',
      accessorKey: 'file.name',
      size: 480,
    },
    {
      id: 'delete',
      cell: ({ row }) => {
        const isLoading = row.original.isLoading
        return (
          <Button
            variant="ghost"
            onClick={() => onDeleteClick(row)}
            disabled={isLoading}
            size="smIcon"
            aria-label="Delete"
          >
            <Trash size={16} />
          </Button>
        )
      },
      size: 64,
      maxSize: 64,
    },
  ]

  const table = useReactTable({
    data: documents,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: false,
  })

  return <DataTable className="h-full w-full" table={table} />
}
