import React, { useState, useCallback } from 'react'
import { useMount } from 'react-use'

import { IRowNode } from 'ag-grid-community'
import { Info } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { OrderedSet } from 'utils/ordered-set'
import { requestIdleCallback } from 'utils/request-idle-callback'
import { TaskType } from 'utils/task'
import { displayErrorMessage, displayInfoMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Switch } from 'components/ui/switch'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  exportExcelWithReviewState,
  exportWordWithReviewState,
  downloadFiles,
} from 'components/vault/utils/vault-exporter'
import { useVaultStore } from 'components/vault/utils/vault-store'

enum ExportExtension {
  WORD = 'word',
  EXCEL = 'excel',
  CSV = 'csv',
}

enum RowType {
  ALL = 'all',
  FILTERED = 'filtered',
  SELECTED = 'selected',
}

const title = 'Export'

const RowTypeRadioGroupForm = ({
  rowType,
  setRowType,
  setFileIdsToDownload,
}: {
  rowType: RowType
  setRowType: (value: RowType) => void
  setFileIdsToDownload: (value: string[]) => void
}) => {
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const gridApi = useVaultQueryDetailStore(useShallow((s) => s.gridApi))
  const [selectedRows, currentAppliedFilters] = useVaultDataGridFilterStore(
    useShallow((s) => [s.selectedRows, s.currentAppliedFilters])
  )

  const options = [
    {
      id: RowType.ALL,
      label: 'Full table',
      value: RowType.ALL,
      isHidden: false,
    },
    {
      id: RowType.FILTERED,
      label: 'Filtered rows',
      value: RowType.FILTERED,
      isHidden: currentAppliedFilters.length === 0,
    },
    {
      id: RowType.SELECTED,
      label: `Selected ${pluralize('row', selectedRows.length)} (${
        selectedRows.length
      } ${pluralize('file', selectedRows.length)})`,
      value: RowType.SELECTED,
      isHidden: selectedRows.length === 0,
    },
  ]

  const changeHandler = (value: RowType) => {
    setRowType(value)
    if (value === RowType.SELECTED) {
      setFileIdsToDownload(selectedRows)
      return
    }

    if (value === RowType.ALL) {
      const fileIds =
        currentProjectMetadata.descendantFiles?.map((file) => file.id) || []
      setFileIdsToDownload(fileIds)
      return
    }

    const fileIdsToDownload: string[] = []
    gridApi?.forEachNode((node) => {
      if (node.group || !node.displayed) return
      if (node.id) {
        fileIdsToDownload.push(node.id)
      }
    })
    setFileIdsToDownload(fileIdsToDownload)
  }

  useMount(() => {
    setFileIdsToDownload(
      currentProjectMetadata.descendantFiles?.map((file) => file.id) || []
    )
  })

  if (selectedRows.length === 0 && currentAppliedFilters.length === 0) {
    return null
  }

  return (
    <div>
      <div className="mb-2 text-xs font-medium text-muted">Rows</div>
      <RadioGroup
        orientation="horizontal"
        className="flex gap-3"
        value={rowType}
        onValueChange={changeHandler}
      >
        {options
          .filter((option) => !option.isHidden)
          .map((option) => {
            return (
              <RadioGroupItem
                key={option.id}
                className="py-0.5"
                checked={rowType === option.value}
                {...option}
              />
            )
          })}
      </RadioGroup>
    </div>
  )
}

const ExtensionTypeRadioGroup = ({
  extensionType,
  setExtensionType,
}: {
  extensionType: ExportExtension
  setExtensionType: (value: ExportExtension) => void
}) => {
  const options = [
    {
      label: 'Excel',
      id: ExportExtension.EXCEL,
      value: ExportExtension.EXCEL,
    },
    {
      label: 'Word',
      id: ExportExtension.WORD,
      value: ExportExtension.WORD,
    },
    {
      label: 'CSV',
      id: ExportExtension.CSV,
      value: ExportExtension.CSV,
    },
  ]

  return (
    <div>
      <div className="mb-2 text-xs font-medium text-muted">Extension</div>
      <RadioGroup
        orientation="horizontal"
        className="flex gap-3"
        value={extensionType}
        onValueChange={setExtensionType}
      >
        {options.map((option) => {
          return (
            <RadioGroupItem
              key={option.id}
              className="py-0.5"
              checked={extensionType === option.value}
              {...option}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

const ExportOptionsForm = ({
  shouldIncludeQuestion,
  setShouldIncludeQuestion,
  shouldExportAdditionalContext,
  setShouldExportAdditionalContext,
}: {
  shouldIncludeQuestion: boolean
  setShouldIncludeQuestion: (value: boolean) => void
  shouldExportAdditionalContext: boolean
  setShouldExportAdditionalContext: (value: boolean) => void
}) => {
  const includeQuestionLabel = 'Include question for each column'
  const includeQuestionTooltip =
    'The exported file will always include the column label as the title of each column. If enabled, the column title will also state the question text.'
  const additionalContextLabel = 'Include Additional Context for each column'
  const additionalContextString = `“Additional Context”`
  const additionalContextTooltip = `The exported file will always include the “Summary” for each column. If enabled, “Additional Context” will also be included.`

  return (
    <div className="w-full">
      <div className="mb-2 text-xs font-medium text-muted">Export Options</div>
      <div className="space-y-2">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <p className="text-sm">{includeQuestionLabel}</p>
            <Tooltip>
              <TooltipTrigger>
                <Icon icon={Info} size="small" />
              </TooltipTrigger>
              <TooltipContent className="max-w-64">
                <p className="text-xs">{includeQuestionTooltip}</p>
              </TooltipContent>
            </Tooltip>
          </div>
          <Switch
            id={includeQuestionLabel}
            checked={shouldIncludeQuestion}
            onCheckedChange={setShouldIncludeQuestion}
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <p className="text-sm">
              Include <strong>{additionalContextString}</strong> for each column
            </p>
            <Tooltip>
              <TooltipTrigger>
                <Icon icon={Info} size="small" />
              </TooltipTrigger>
              <TooltipContent className="max-w-64">
                <p className="text-xs">{additionalContextTooltip}</p>
              </TooltipContent>
            </Tooltip>
          </div>
          <Switch
            id={additionalContextLabel}
            checked={shouldExportAdditionalContext}
            onCheckedChange={setShouldExportAdditionalContext}
          />
        </div>
      </div>
    </div>
  )
}

const AdditionalOptionsForm = ({
  shouldDownloadFiles,
  setShouldDownloadFiles,
}: {
  shouldDownloadFiles: boolean
  setShouldDownloadFiles: (value: boolean) => void
}) => {
  const downloadFilesLabel = 'Download files from the table as a zip'
  return (
    <div className="w-full">
      <div className="mb-2 text-xs font-medium text-muted">
        Additional Options
      </div>
      <div className="flex w-full items-center justify-between">
        <p className="text-sm">{downloadFilesLabel}</p>
        <Switch
          id={downloadFilesLabel}
          checked={shouldDownloadFiles}
          onCheckedChange={setShouldDownloadFiles}
        />
      </div>
    </div>
  )
}

const ExportReviewForm = ({
  shouldDownloadFiles,
  setShouldDownloadFiles,
  rowType,
  setRowType,
  setFileIdsToDownload,
  shouldExportAdditionalContext,
  setShouldExportAdditionalContext,
  exportExtension,
  setExportExtension,
  shouldIncludeQuestion,
  setShouldIncludeQuestion,
}: {
  shouldDownloadFiles: boolean
  setShouldDownloadFiles: (value: boolean) => void
  rowType: RowType
  setRowType: (value: RowType) => void
  setFileIdsToDownload: (value: string[]) => void
  shouldExportAdditionalContext: boolean
  setShouldExportAdditionalContext: (value: boolean) => void
  exportExtension: ExportExtension
  setExportExtension: (value: ExportExtension) => void
  shouldIncludeQuestion: boolean
  setShouldIncludeQuestion: (value: boolean) => void
}) => {
  return (
    <div className="space-y-4">
      <ExtensionTypeRadioGroup
        extensionType={exportExtension}
        setExtensionType={setExportExtension}
      />
      <ExportOptionsForm
        shouldIncludeQuestion={shouldIncludeQuestion}
        setShouldIncludeQuestion={setShouldIncludeQuestion}
        shouldExportAdditionalContext={shouldExportAdditionalContext}
        setShouldExportAdditionalContext={setShouldExportAdditionalContext}
      />
      <RowTypeRadioGroupForm
        rowType={rowType}
        setRowType={setRowType}
        setFileIdsToDownload={setFileIdsToDownload}
      />
      <AdditionalOptionsForm
        shouldDownloadFiles={shouldDownloadFiles}
        setShouldDownloadFiles={setShouldDownloadFiles}
      />
    </div>
  )
}

const VaultExportDialog = () => {
  const { trackEvent } = useAnalytics()

  const [
    currentProject,
    fileIdToVaultFile,
    isExportDialogOpen,
    upsertVaultFiles,
    setIsExportDialogOpen,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.fileIdToVaultFile,
      s.isExportDialogOpen,
      s.upsertVaultFiles,
      s.setIsExportDialogOpen,
    ])
  )
  const [
    gridApi,
    isQueryLoading,
    historyItem,
    pendingQueryFileIds,
    pendingQueryQuestions,
    currentPendingColumnId,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.gridApi,
      s.isQueryLoading,
      s.historyItem,
      s.pendingQueryFileIds,
      s.pendingQueryQuestions,
      s.currentPendingColumnId,
    ])
  )
  const [selectedRows] = useVaultDataGridFilterStore(
    useShallow((s) => [s.selectedRows])
  )

  // Review Query States
  const [shouldDownloadFiles, setShouldDownloadFiles] = useState(false)
  const [rowType, setRowType] = useState<RowType>(RowType.ALL)
  const [shouldIncludeQuestion, setShouldIncludeQuestion] =
    useState<boolean>(true)
  const [shouldExportAdditionalContext, setShouldExportAdditionalContext] =
    useState<boolean>(true)
  const [exportExtension, setExportExtension] = useState<ExportExtension>(
    ExportExtension.EXCEL
  )

  const [fileIdsToDownload, setFileIdsToDownload] = useState<string[]>([])
  const [isExporting, setIsExporting] = useState(false)

  const projectName = currentProject?.name || 'Untitled'
  const taskType = TaskType.VAULT_REVIEW
  const reviewState = historyItem as ReviewHistoryItem

  const hasToProcessFiles =
    pendingQueryFileIds && pendingQueryFileIds.length > 0
  const hasToProcessQuestions =
    pendingQueryQuestions && pendingQueryQuestions.length > 0

  const isExportDisabled =
    isQueryLoading ||
    !!hasToProcessFiles ||
    !!hasToProcessQuestions ||
    !!currentPendingColumnId

  const disabledTooltip =
    hasToProcessFiles || hasToProcessQuestions
      ? 'Run the query first before exporting'
      : isQueryLoading
      ? 'Query is still running. Please wait for it to finish before exporting.'
      : undefined

  const closeHandler = () => {
    setIsExporting(false)
    setIsExportDialogOpen(false)
  }

  const handleExportInner = useCallback(
    async ({
      exportAll,
      onlySelected,
      extensionType,
      shouldExportAdditionalContext,
      shouldIncludeQuestion,
    }: {
      exportAll: boolean
      onlySelected: boolean
      extensionType: ExportExtension
      shouldExportAdditionalContext: boolean
      shouldIncludeQuestion: boolean
    }) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!reviewState || !gridApi) {
        displayErrorMessage(
          'Sorry we could not export the query at this time. Please try again later.'
        )
        return
      }
      trackEvent('Vault Query Exported', {
        export_type: 'download',
        export_all: exportAll,
        export_only_selected: onlySelected,
        export_question: shouldIncludeQuestion,
      })
      let visibleRows: IRowNode<any>[] = []
      if (onlySelected) {
        const selectedNodes = gridApi.getSelectedNodes()
        visibleRows = selectedNodes
      } else if (exportAll) {
        gridApi.forEachNode((node) => {
          if (node.group) return
          visibleRows.push(node)
        })
        visibleRows.sort((a, b) => {
          const aIndex = a.rowIndex ?? 0
          const bIndex = b.rowIndex ?? 0
          return aIndex - bIndex
        })
      } else {
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.group) return
          visibleRows.push(node)
        })
      }
      if (extensionType === ExportExtension.WORD) {
        await exportWordWithReviewState({
          visibleRows,
          taskType,
          reviewState,
          shouldExportAdditionalContext,
          shouldIncludeQuestion,
          fileIdToVaultFile,
        })
        return
      }

      await exportExcelWithReviewState({
        gridApi,
        visibleRowIds: new OrderedSet(visibleRows.map((node) => node.data.id)),
        taskType: TaskType.VAULT_REVIEW,
        sheetName: projectName,
        reviewState,
        fileIdToVaultFile,
        shouldExportAdditionalContext,
        shouldIncludeQuestion,
        isCSV: extensionType === ExportExtension.CSV,
      })
    },
    [taskType, projectName, gridApi, reviewState, fileIdToVaultFile, trackEvent]
  )

  const onSubmit = async () => {
    setIsExporting(true)

    closeHandler()
    displayInfoMessage(
      'Your export is being prepared. This may take a while. It will automatically download when it is ready.'
    )

    if (shouldDownloadFiles) {
      void downloadFiles({
        fileIdsToDownload,
        fileIdToVaultFile,
        downloadFileName: projectName,
        projectId: currentProject?.id,
        upsertVaultFiles,
      })
    }

    // Use setTimeout to ensure state updates before calling the function
    // TODO: put this into a web-worker to avoid blocking the main thread
    requestIdleCallback(async () => {
      await handleExportInner({
        exportAll: rowType === RowType.ALL,
        onlySelected: rowType === RowType.SELECTED,
        extensionType: exportExtension,
        shouldExportAdditionalContext,
        shouldIncludeQuestion,
      })
    })
  }

  return (
    <Dialog open={isExportDialogOpen} onOpenChange={setIsExportDialogOpen}>
      <DialogTrigger asChild>
        <Button
          disabled={isExportDisabled}
          tooltip={disabledTooltip}
          variant="outline"
        >
          Export{' '}
          {selectedRows.length > 0 && (
            <Badge
              variant="secondary"
              className="ml-1.5 flex h-4 min-w-4 items-center justify-center p-0"
            >
              {selectedRows.length}
            </Badge>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent
        id="vault-export-dialog"
        className="max-w-[620px] "
        innerClassName="p-0 py-5 space-y-0"
        hasContainer={false}
      >
        <DialogHeader className="h-9 border-b border-b-primary px-6">
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col px-6">
          <div className="h-5" />
          <ExportReviewForm
            shouldDownloadFiles={shouldDownloadFiles}
            setShouldDownloadFiles={setShouldDownloadFiles}
            rowType={rowType}
            setRowType={setRowType}
            setFileIdsToDownload={setFileIdsToDownload}
            shouldExportAdditionalContext={shouldExportAdditionalContext}
            setShouldExportAdditionalContext={setShouldExportAdditionalContext}
            exportExtension={exportExtension}
            setExportExtension={setExportExtension}
            shouldIncludeQuestion={shouldIncludeQuestion}
            setShouldIncludeQuestion={setShouldIncludeQuestion}
          />
          <div className="h-5" />
        </div>
        <DialogFooter className="h-12 border-t border-t-primary px-6">
          <div className="flex h-full w-full items-end justify-end gap-2 pt-0.5">
            <DialogClose asChild>
              <Button id="vault-export-dialog-cancel-button" variant="outline">
                Cancel
              </Button>
            </DialogClose>
            <Button
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              id="vault-export-dialog-export-button"
              variant="default"
              isLoading={isExporting}
              onClick={onSubmit}
            >
              Export
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultExportDialog
